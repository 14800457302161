import React from "react";
import ReactDOM from "react-dom";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import "./styles.less";
import "./tailwind.less";
import App from "./App";

const hasNativeElementsFromPoint = document && document.elementsFromPoint;

ReactDOM.render(
  <React.StrictMode>
    <DndProvider
      backend={TouchBackend}
      options={{
        getDropTargetElementsAtPoint:
          !hasNativeElementsFromPoint && document.msElementsFromPoint,
        enableMouseEvents: true,
      }}
    >
      <App />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import { observer } from "mobx-react";
import store from "../store";
import { PromoIcon } from "./Icons";

const CampaignBar = observer(() => {
  return (
    <div className="flex justify-center items-center bg-green py-2">
      <PromoIcon className="mr-1.5" />
      <p className="text-black font-light whitespace-nowrap tracking-tighter text-sm">
        {store.campaign.description}
      </p>
      {store.currentPrice && store.currentPrice.total_amount > 0 && (
        <span className="bg-dark text-green text-xs font-bold whitespace-nowrap leading-none rounded-lg px-1.5 py-1 ml-1.5">
          Spara{" "}
          {new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK",
          }).format(
            (store.currentPrice.original_amount -
              store.currentPrice.total_amount) /
              100
          )}
        </span>
      )}
    </div>
  );
});

export default CampaignBar;

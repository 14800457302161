import React, { useEffect } from "react";
import { observer } from "mobx-react";
import store, {
  setActiveDoor,
  setDoorLayout,
  completeStep,
  removeCompletedStep,
  resetActiveField,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import fieldLayouts from "../fieldLayouts";
import FieldButton from "./FieldButton";

const Fields = observer(() => {
  useEffect(() => {
    setActiveDoor(null);
    resetActiveField();
    stepCompletedCheck();
  }, []);

  const stepCompletedCheck = () => {
    let stepComplete = true;
    store.doors.forEach((door) => {
      if (!door.layout.type) {
        stepComplete = false;
      }
    });

    if (stepComplete) {
      completeStep();
    } else {
      removeCompletedStep();
    }
  };

  const handleChange = (layout) => {
    setDoorLayout(store.activeDoor, layout);
    setCurrentOrder();
    setCurrentPrice();
    stepCompletedCheck();
  };

  return (
    <>
      {Object.keys(fieldLayouts).map((layout) => (
        <FieldButton
          key={layout}
          figure={fieldLayouts[layout].figure}
          onClick={() => handleChange(layout)}
          disabled={store.activeDoor === null}
          infoText={fieldLayouts[layout].info}
        />
      ))}
    </>
  );
});

export default Fields;

import React, { useEffect, useRef } from "react";

const Alert = ({ onClick, text, buttonText }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.focus();
  }, []);

  return (
    <div className="w-screen min-h-screen bg-grey-lighter bg-opacity-90 absolute top-0 inset-x-0 z-50 flex justify-center items-center">
      <div className="py-16 w-full max-w-sm">
        <div className="p-7 mx-8 bg-white rounded-2xl shadow-popup">
          <p>{text}</p>
          <button
            ref={buttonRef}
            className="w-full mt-5 px-8 py-4 bg-yellow font-bold rounded-2xl"
            onClick={onClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;

import React from "react";
import { observer } from "mobx-react";
import store from "../store";

const TrackBar = observer(() => (
  <div className="trackbar-perspective-wrapper absolute -top-[12vh] z-1 w-full">
    <div className="trackbar-perspective-room">
      <div className="trackbar-perspective-room--front mt-8">
        <div
          className={`trackbar-perspective-wardrobe-wrapper placement-${store.placement}`}
        >
          <div className="trackbar-perspective-wardrobe">
            <div
              className="track-bar"
              style={{
                transform: `translateZ(${
                  25 + store.size.depth >= 600
                    ? 1 + (store.size.depth - 600) / 10
                    : 0
                }px)`,
              }}
            >
              {[...Array(store.tracks)].map((track, trackIndex) => (
                <div
                  className={`tracks-${store.tracks} track flex items-center mb-3`}
                  key={trackIndex}
                >
                  {store.doors.map((door, doorIndex) => (
                    <div
                      className={`line flex-1 ${
                        store.trackConfig[store.doors.length][store.tracks][
                          doorIndex
                        ] ==
                        trackIndex + 1
                          ? "bg-profile line-active"
                          : "bg-transparent"
                      }`}
                      key={door.id}
                    ></div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default TrackBar;

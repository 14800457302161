import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { API_URL } from "../constants";

const ColorRadioGroupItem = observer(
  ({
    name,
    value,
    label,
    activeValue,
    color,
    url,
    onChange,
    first,
    last,
    isActive,
  }) => {
    return (
      <li key={value}>
        <input
          type="radio"
          id={`${name}-${value}`}
          name={name}
          value={value}
          className="hidden"
          onChange={onChange}
          checked={activeValue === value}
        />
        <label
          htmlFor={`${name}-${value}`}
          className="cursor-pointer text-center overflow-hidden"
        >
          <div
            className={`min-w-15 h-12 mb-1 ${first ? "rounded-l-lg" : ""} ${
              last ? "rounded-r-lg" : ""
            } ${isActive ? "border-2 box-border border-yellow" : ""}`}
            style={{
              background: url
                ? `url(${API_URL + url}) no-repeat center center / cover`
                : color,
              filter:
                "drop-shadow(0px 1px 2px rgba(50, 50, 71, 0.08)) drop-shadow(0px 0px 1px rgba(50, 50, 71, 0.2))",
            }}
          ></div>
          <p className="text-med text-xs leading-none whitespace-nowrap">
            {label}
          </p>
        </label>
      </li>
    );
  }
);

ColorRadioGroupItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: (props, propName, componentName) => {
    if (!props.color && !props.url) {
      return new Error(
        `One of props 'color' or 'url' was not specified in '${componentName}'.`
      );
    }
  },
  url: (props, propName, componentName) => {
    if (!props.color && !props.url) {
      return new Error(
        `One of props 'url' or 'color' was not specified in '${componentName}'.`
      );
    }
  },
  onChange: PropTypes.func.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ColorRadioGroupItem;

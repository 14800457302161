import React, { useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { observer, useLocalObservable } from "mobx-react";
import store, {
  addPartition,
  addClothesRail,
  addSection,
  getMaxLeftSectionOffset,
  getMaxRightSectionOffset,
  getSection,
  getMaxLeftPartitionOffset,
  getMaxRightPartitionOffset,
  hasSection,
} from "../store";
import Door from "./Door";
import Light from "./Light";
import { useDrop, useDragLayer } from "react-dnd";
import {
  BodyColors,
  DetailColors,
  SectionDragType,
  SectionTypes,
  Steps,
} from "../constants";
import WardrobePartition from "./WardrobePartition";
import WardrobeSection from "./WardrobeSection";
import WardrobeClothesRail from "./WardrobeClothesRail";
import WardrobeTopshelf from "./WardrobeTopshelf";
import TrackBar from "./TrackBar";

const Wardrobe = observer(
  ({
    preserveAspectRatio = false,
    placement,
    forceShowSections = false,
    showTrackbar = false,
    wardrobeScale = 1,
  }) => {
    const wrapperRef = useRef(null);
    const roomRef = useRef(null);
    const wardrobeRef = useRef(null);
    const frontFaceRef = useRef(null);
    const interiorRef = useRef(null);

    const profile = useLocalObservable(() => ({
      color: null,
      setColor() {
        profile.color = store.profiles
          .find(({ id }) => id === store.profile.type)
          .Products.find(({ id }) => id === store.profile.color).color;
      },
    }));

    const [{ isDropping }, drop] = useDrop(() => ({
      accept: Object.values(SectionTypes),
      drop: ({ type }, monitor) => {
        const offset = monitor.getClientOffset();
        const frontFaceRect = frontFaceRef.current.getBoundingClientRect();
        const x = offset.x - frontFaceRect.left;
        // We want the section to be placed on an even mm.
        const pos = Math.round((x / frontFaceRect.width) * store.innerWidth);

        if (type === SectionTypes.Partition) {
          addPartition(pos);
        } else if (
          type === SectionTypes.ClothesRail ||
          type === SectionTypes.DoubleClothesRail
        ) {
          addClothesRail(type, pos);
        } else {
          addSection(type, pos);
        }
      },
      collect: (monitor) => ({
        isDropping: Boolean(monitor.isOver()),
      }),
    }));
    const dropRef = drop(wrapperRef);

    const draggedSection = useDragLayer((monitor) => {
      if (!monitor.isDragging() || monitor.getItemType() !== SectionDragType) {
        return null;
      }

      const item = monitor.getItem();
      const offset = monitor.getDifferenceFromInitialOffset();
      const frontFaceRect = frontFaceRef.current.getBoundingClientRect();

      return {
        index: item.index,
        type: item.type,
        relativePos: offset
          ? // Keep the dragged section on an even mm.
            Math.round((offset.x / frontFaceRect.width) * store.size.width)
          : null,
      };
    });

    const { cappedWidth, cappedHeight } = store;

    useLayoutEffect(() => {
      // If the preserveAspectRatio prop is false we use all available width and height.
      if (!preserveAspectRatio || !placement) {
        if (wardrobeRef.current) {
          wardrobeRef.current.style.removeProperty("width");
          wardrobeRef.current.style.removeProperty("height");
        }

        if (roomRef.current) {
          roomRef.current.style.removeProperty("width");
          roomRef.current.style.removeProperty("height");
        }

        return;
      }

      const updateWardrobeDimensions = () => {
        const aspectRatio = cappedWidth / cappedHeight;
        const parentWidth = wrapperRef.current.clientWidth * wardrobeScale;
        const parentHeight = wrapperRef.current.clientHeight;
        const widthHeightRatio = parentWidth / parentHeight;

        if (widthHeightRatio < aspectRatio) {
          wardrobeRef.current.style.width = `${100 * wardrobeScale}%`;
          wardrobeRef.current.style.height = `${
            wardrobeRef.current.clientWidth / aspectRatio
          }px`;

          roomRef.current.style.width = "100%";
          roomRef.current.style.height = `${
            (roomRef.current.clientWidth * wardrobeScale) / aspectRatio
          }px`;
        } else {
          wardrobeRef.current.style.height = "100%";
          wardrobeRef.current.style.width = `${
            wardrobeRef.current.clientHeight * aspectRatio
          }px`;

          // We only set the height and use all the available width for the room.
          roomRef.current.style.height = "100%";
        }
      };

      updateWardrobeDimensions();

      const resizeObserver = new ResizeObserver(updateWardrobeDimensions);

      resizeObserver.observe(wrapperRef.current);

      return () => resizeObserver.disconnect();
    }, [
      preserveAspectRatio,
      cappedWidth,
      cappedHeight,
      placement,
      wardrobeScale,
    ]);

    useLayoutEffect(() => {
      if (store.profiles) {
        profile.setColor();
      }
    }, [store.profile.color, store.profiles]);

    // Math to handle depth logic
    let depthMath =
      store.size.depth >= 600 ? 1 + (store.size.depth - 600) / 10 : 0;
    if (depthMath > 21) {
      depthMath = 21;
    }

    const { step } = store;

    const shouldRenderSections =
      step === Steps.Sections || step === Steps.Interior || forceShowSections;
    const shouldRenderDoors = !shouldRenderSections && store.doors.length > 0;
    const shouldRenderGrid =
      !shouldRenderSections && step !== Steps.Fields && step !== Steps.Fills;
    const shouldRenderBackface = step !== Steps.Fields && step !== Steps.Fills;

    const wardrobeWidth = store.innerWidth;
    const wardrobeHeight = store.size.height;
    const enableSectionFunctionality =
      Steps.Summary !== step && Steps.Interior !== step && !store.requestSent;
    const { sections, bodyColor, detailColor } = store;

    let shouldMoveLeftSection = false;
    let shouldMoveRightSection = false;
    let dragOffset = 0;

    if (draggedSection && hasSection(draggedSection.index)) {
      const sectionIndex = draggedSection.index;
      const leftSection = getSection(sectionIndex - 1);
      const leftSecondSection = getSection(sectionIndex - 2);
      const rightSection = getSection(sectionIndex + 1);
      const rightSecondSection = getSection(sectionIndex + 2);

      dragOffset = draggedSection.relativePos;

      let offsetLeftFunc;
      let offsetRightFunc;

      if (draggedSection.type !== SectionTypes.Partition) {
        offsetLeftFunc = getMaxLeftSectionOffset;
        offsetRightFunc = getMaxRightSectionOffset;
        // If the section has empty space to the left, we want to move the partition to the left of it.
        shouldMoveLeftSection =
          !leftSecondSection ||
          leftSecondSection.type === SectionTypes.Partition;
        // If the section has empty space to the right, we want to move the partition to the right of it.
        shouldMoveRightSection =
          !rightSecondSection ||
          rightSecondSection.type === SectionTypes.Partition;
      } else {
        offsetLeftFunc = getMaxLeftPartitionOffset;
        offsetRightFunc = getMaxRightPartitionOffset;

        shouldMoveLeftSection =
          leftSection &&
          (leftSection.type === SectionTypes.ClothesRail ||
            leftSection.type === SectionTypes.DoubleClothesRail);
        shouldMoveRightSection =
          rightSection &&
          (rightSection.type === SectionTypes.ClothesRail ||
            rightSection.type === SectionTypes.DoubleClothesRail);
      }

      if (dragOffset < 0) {
        dragOffset = Math.max(
          dragOffset,
          -offsetLeftFunc(draggedSection.index)
        );
      } else {
        dragOffset = Math.min(
          dragOffset,
          offsetRightFunc(draggedSection.index)
        );
      }
    }

    return (
      <div className="w-full h-full flex items-center relative" ref={dropRef}>
        <div
          className="room-wrapper absolute right-0 bottom-0 left-0"
          style={{
            transform: `perspective(440px) translateZ(-${depthMath}px)`,
          }}
          ref={roomRef}
        >
          <div className="room">
            <div className="room__face room__face--front">
              {placement && (
                <div
                  className={`wardrobe-wrapper placement-${placement} transition-opacity duration-200`}
                  style={{ opacity: isDropping ? 0.5 : 1 }}
                >
                  <div
                    className="wardrobe"
                    style={
                      store.step === Steps.Summary || store.requestSent
                        ? { width: "100%" }
                        : null
                    }
                    ref={wardrobeRef}
                  >
                    <div
                      className={`wardrobe__face wardrobe__face--front ${
                        shouldRenderGrid ? "wardrobe__face--front--grid" : ""
                      }`}
                      style={{
                        borderColor: shouldRenderDoors ? "transparent" : null,
                        transform: `rotateY(0deg) translateZ(${
                          25 + depthMath
                        }px)`,
                      }}
                      ref={frontFaceRef}
                    >
                      {showTrackbar && store.doors.length > 0 && <TrackBar />}
                      {shouldRenderDoors && (
                        <>
                          <div
                            className="h-1.5 sm:h-2 xl:h-4 shadow-popup absolute top-0 right-0 left-0 z-40"
                            style={{ backgroundColor: profile.color }}
                          ></div>
                          {store.doors.map((door, i) => (
                            <Door
                              key={door.id}
                              door={door}
                              index={i}
                              profileColor={profile.color}
                            />
                          ))}
                          {store.lighting && <Light />}
                        </>
                      )}
                      {shouldRenderSections && (
                        <div className="flex flex-col h-full preserve-3d">
                          <WardrobeTopshelf
                            depth={depthMath}
                            wardrobeHeight={wardrobeHeight}
                            color={BodyColors[bodyColor]}
                          />
                          <div
                            className="flex items-center justify-center flex-grow preserve-3d"
                            ref={interiorRef}
                          >
                            {store.interiorLighting && <Light />}
                            {store.step === Steps.Sections &&
                            sections.length === 0 ? (
                              <div className="w-full flex justify-center items-center">
                                <div className="flex flex-col items-center text-dark">
                                  <div className="mb-2">
                                    <svg
                                      width="37"
                                      height="53"
                                      viewBox="0 0 37 53"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g width="37" height="53">
                                        <path
                                          d="M5.3814 18.853C5.3814 17.5961 4.30411 16.5791 2.9754 16.5791C1.64729 16.5791 0.57 17.5955 0.57 18.853V28.2343C0.57 32.0016 3.80246 35.055 7.7874 35.055H17.412C21.3969 35.055 24.63 31.9982 24.63 28.2343V16.8633C24.63 15.6064 23.5515 14.5899 22.224 14.5899C20.8953 14.5899 19.818 15.6064 19.818 16.8633V19.1395V14.5893V14.5871C19.818 13.3318 18.7407 12.3154 17.412 12.3154C16.0845 12.3154 15.006 13.3318 15.006 14.5871V16.8633V12.3154V12.3137C15.006 11.0579 13.9287 10.0397 12.6 10.0397C11.2701 10.0397 10.1946 11.0579 10.1946 12.3137L10.1928 16.8633V3.21896H10.1952C10.1952 1.96373 9.11732 0.945 7.7874 0.945C6.45869 0.945 5.3814 1.96373 5.3814 3.21896V23.6863V18.853V18.853Z"
                                          stroke="currentColor"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="pointer-hand"
                                        />
                                        <path
                                          d="M1 4.5C0.723858 4.5 0.5 4.27614 0.5 4C0.5 3.72386 0.723858 3.5 1 3.5V4.5ZM13.9536 3.64645C14.1488 3.84171 14.1488 4.15829 13.9536 4.35355L10.7716 7.53553C10.5763 7.7308 10.2597 7.7308 10.0645 7.53553C9.8692 7.34027 9.8692 7.02369 10.0645 6.82843L12.8929 4L10.0645 1.17157C9.8692 0.976311 9.8692 0.659728 10.0645 0.464466C10.2597 0.269204 10.5763 0.269204 10.7716 0.464466L13.9536 3.64645ZM1 3.5H13.6V4.5H1V3.5Z"
                                          fill="currentColor"
                                          className="pointer-arrow"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                  <span className="font-normal text-sm select-none">
                                    Dra sektioner hit
                                  </span>
                                </div>
                              </div>
                            ) : (
                              sections.map((section, i) => {
                                let offset = 0;

                                if (draggedSection) {
                                  if (
                                    shouldMoveLeftSection &&
                                    i === draggedSection.index - 1
                                  ) {
                                    offset = dragOffset;
                                  } else if (draggedSection.index === i) {
                                    offset = dragOffset;
                                  } else if (
                                    shouldMoveRightSection &&
                                    i === draggedSection.index + 1
                                  ) {
                                    offset = dragOffset;
                                  }
                                }

                                if (section.type === SectionTypes.Partition) {
                                  return (
                                    <WardrobePartition
                                      key={section.id}
                                      section={section}
                                      sectionIndex={i}
                                      wardrobeWidth={wardrobeWidth}
                                      depth={depthMath}
                                      offset={offset}
                                      frontFaceRef={frontFaceRef}
                                      color={BodyColors[bodyColor]}
                                      canRemove={enableSectionFunctionality}
                                      canMove={enableSectionFunctionality}
                                    />
                                  );
                                }

                                if (
                                  section.type === SectionTypes.ClothesRail ||
                                  section.type ===
                                    SectionTypes.DoubleClothesRail
                                ) {
                                  const dragIndex =
                                    draggedSection && draggedSection.index;

                                  return (
                                    <WardrobeClothesRail
                                      key={section.id}
                                      section={section}
                                      sectionIndex={i}
                                      wardrobeWidth={wardrobeWidth}
                                      depth={depthMath}
                                      offset={offset}
                                      move={dragIndex === i}
                                      fromLeft={dragIndex === i - 1}
                                      fromRight={dragIndex === i + 1}
                                      double={
                                        section.type ===
                                        SectionTypes.DoubleClothesRail
                                      }
                                      frontFaceRef={frontFaceRef}
                                      color={DetailColors[detailColor]}
                                      canRemove={enableSectionFunctionality}
                                      canMove={enableSectionFunctionality}
                                    />
                                  );
                                }

                                // Section is either S, M, L, or XL.
                                return (
                                  <WardrobeSection
                                    key={section.id}
                                    section={section}
                                    sectionIndex={i}
                                    wardrobeWidth={wardrobeWidth}
                                    offset={offset}
                                    frontFaceRef={frontFaceRef}
                                    interiorRef={interiorRef}
                                    canRemove={enableSectionFunctionality}
                                    canMove={enableSectionFunctionality}
                                    showSectionType={enableSectionFunctionality}
                                  />
                                );
                              })
                            )}
                            {draggedSection &&
                              draggedSection.type !==
                                SectionTypes.Partition && (
                                <>
                                  {!shouldMoveLeftSection && (
                                    <WardrobePartition
                                      section={
                                        sections[draggedSection.index - 1]
                                      }
                                      sectionIndex={draggedSection.index - 1}
                                      wardrobeWidth={wardrobeWidth}
                                      depth={depthMath}
                                      offset={dragOffset}
                                      frontFaceRef={frontFaceRef}
                                      color={BodyColors[bodyColor]}
                                    />
                                  )}
                                  {!shouldMoveRightSection && (
                                    <WardrobePartition
                                      section={
                                        sections[draggedSection.index + 1]
                                      }
                                      sectionIndex={draggedSection.index + 1}
                                      wardrobeWidth={wardrobeWidth}
                                      depth={depthMath}
                                      offset={dragOffset}
                                      frontFaceRef={frontFaceRef}
                                      color={BodyColors[bodyColor]}
                                    />
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                    {shouldRenderBackface && (
                      <div
                        className="wardrobe__face wardrobe__face--back"
                        style={{
                          transform: `rotateY(180deg) translateZ(${
                            25 + depthMath
                          }px)`,
                        }}
                      ></div>
                    )}
                    <div
                      className="wardrobe__face wardrobe__face--right"
                      style={{
                        width: `calc(50px + ${depthMath}px * 2)`,
                        right: `calc(-25px - ${depthMath}px)`,
                        transform: "rotateY(90deg)",
                        borderColor: shouldRenderDoors ? "transparent" : null,
                      }}
                    ></div>
                    <div
                      className="wardrobe__face wardrobe__face--left"
                      style={{
                        width: `calc(50px + ${depthMath}px * 2)`,
                        transform: `rotateY(-90deg) translateZ(${
                          75 + depthMath
                        }px)`,
                        borderColor: shouldRenderDoors ? "transparent" : null,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            {step !== Steps.Summary && !store.requestSent && (
              <>
                <div
                  className="room__face room__face--back"
                  style={{
                    transform: `rotateY(180deg) translateZ(${
                      50 + depthMath
                    }px)`,
                  }}
                ></div>
                <div
                  className="room__face room__face--right"
                  style={{
                    width: `calc(100px + ${depthMath}px * 2)`,
                    right: `calc(-50px - ${depthMath}px)`,
                    transform: "rotateY(90deg)",
                  }}
                ></div>
                <div
                  className="room__face room__face--left"
                  style={{
                    width: `calc(100px + ${depthMath}px * 2)`,
                    transform: `rotateY(-90deg) translateZ(${
                      100 + depthMath
                    }px)`,
                  }}
                ></div>
                <div
                  className="room__face room__face--top"
                  style={{
                    height: `calc(100px + ${depthMath}px * 2)`,
                    transform: `rotateX(90deg) translateZ(${
                      100 + depthMath
                    }px)`,
                  }}
                ></div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

Wardrobe.propTypes = {
  preserveAspectRatio: PropTypes.bool,
  placement: PropTypes.string,
  forceShowSections: PropTypes.bool,
  showTrackbar: PropTypes.bool,
  // wardrobeScale is used to indicate how much room space the wardrobe
  // should take up when the wardrobe size is close to the room size,
  // as to give some padding to the wardrobe in the room.
  wardrobeScale: PropTypes.number,
};

export default Wardrobe;

import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import store, {
  completeStep,
  removeCompletedStep,
  setBodyColor,
  setCurrentOrder,
  setCurrentPrice,
  setShouldHaveTopShelf,
} from "../store";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";
import { useDrag } from "react-dnd";
import {
  BodyColorNames,
  BodyColors,
  OrderType,
  SectionTypes,
  SectionWidths,
} from "../constants";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import InteriorColorRadioGroup from "./InteriorColorRadioGroup";
import DraggingIconPortal from "./DraggingIconPortal";
import WhileDragging from "../assets/while_dragging.gif";

const Section = ({ name, type, description, children }) => {
  const draggingIconRef = useRef(null);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: {
        type,
      },
      collect: (monitor) => ({
        isDragging: Boolean(monitor.isDragging()),
      }),
    }),
    [type]
  );

  const handleMoveEvent = (e) => {
    draggingIconRef.current.style.top = `${
      e.pageY || e.changedTouches[0].pageY
    }px`;
    draggingIconRef.current.style.left = `${
      e.pageX || e.changedTouches[0].pageX
    }px`;
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMoveEvent);
      document.addEventListener("touchmove", handleMoveEvent);
    }
    return () => {
      document.removeEventListener("mousemove", handleMoveEvent);
      document.removeEventListener("touchmove", handleMoveEvent);
    };
  }, [isDragging]);

  return (
    <div
      className="flex flex-col items-center transition-opacity duration-200"
      style={{
        cursor: isDragging ? "grabbing" : "grab",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div className="flex flex-col items-center" ref={drag}>
        <span className="font-medium text-sm whitespace-nowrap mb-1">
          {name}
        </span>
        <div className="mb-1 h-12">{children}</div>
      </div>

      <span className="font-normal text-xs text-med whitespace-nowrap">
        {description}
      </span>
      {isDragging && (
        <DraggingIconPortal>
          <img
            ref={draggingIconRef}
            src={WhileDragging}
            className="w-36 h-36 lg:w-16 lg:h-16 absolute top-0 left-0 z-40 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          />
        </DraggingIconPortal>
      )}
    </div>
  );
};

const SizeSection = ({ name, px }) => (
  <Section
    name={name}
    type={name}
    description={`${SectionWidths[name]} mm`}
    width={SectionWidths[name]}
  >
    <div
      className="h-full border-2 border-black mb-1"
      style={{ width: px }}
    ></div>
  </Section>
);

const Partition = () => (
  <Section
    name="Mellanvägg"
    type={SectionTypes.Partition}
    description={`${SectionWidths[SectionTypes.Partition]} mm`}
  >
    <div className="h-full bg-black mb-1" style={{ width: 2 }}></div>
  </Section>
);

const ClothesRail = () => {
  const [min, max] = SectionWidths[SectionTypes.ClothesRail];

  return (
    <Section
      name="Klädstång"
      type={SectionTypes.ClothesRail}
      description={`${min} - ${max} mm`}
    >
      <div className="h-full mb-1 w-8">
        <div className="bg-black mt-3" style={{ height: 2 }}></div>
      </div>
    </Section>
  );
};

const DoubleClothesRail = () => {
  const [min, max] = SectionWidths[SectionTypes.DoubleClothesRail];

  return (
    <Section
      name="2X Klädstång"
      type={SectionTypes.DoubleClothesRail}
      description={`${min} - ${max} mm`}
    >
      <div className="h-full mb-1 w-8">
        <div className="bg-black mt-3" style={{ height: 2 }}></div>
        <div className="bg-black mt-4" style={{ height: 2 }}></div>
      </div>
    </Section>
  );
};

const Sections = observer(() => {
  useEffect(() => {
    if (
      store.orderType !== OrderType.OnlyInterior ||
      store.sections.length > 0
    ) {
      completeStep();
    }
    setShouldHaveTopShelf(true);
    setCurrentOrder();
    setCurrentPrice();
  }, []);

  useEffectSkipFirst(() => {
    store.orderType !== OrderType.OnlyInterior || store.sections.length > 0
      ? completeStep()
      : removeCompletedStep();
    setCurrentOrder();
    setCurrentPrice();
  }, [store.sections.length, store.bodyColor]);

  const hasClothesRailSection = store.sections.some(
    (section) =>
      section.type === SectionTypes.ClothesRail ||
      section.type === SectionTypes.DoubleClothesRail
  );

  return (
    <div className="flex space-x-16 select-none">
      <SizeSection name={SectionTypes.S} px={15} />
      <SizeSection name={SectionTypes.M} px={20} />
      <SizeSection name={SectionTypes.L} px={25} />
      <SizeSection name={SectionTypes.XL} px={29} />
      <Partition />
      <ClothesRail />
      <DoubleClothesRail />

      <ColorRadioGroup title="Stomme">
        <ColorRadioGroupItem
          name="stomme"
          value={BodyColorNames.White}
          label="Vit"
          color={BodyColors[BodyColorNames.White]}
          first
          last={false}
          isActive={store.bodyColor === BodyColorNames.White}
          onChange={() => setBodyColor(BodyColorNames.White)}
        />
        <ColorRadioGroupItem
          name="stomme"
          value={BodyColorNames.Antracit}
          label="Antracit"
          color={BodyColors[BodyColorNames.Antracit]}
          first={false}
          last
          isActive={store.bodyColor === BodyColorNames.Antracit}
          onChange={() => setBodyColor(BodyColorNames.Antracit)}
        />
      </ColorRadioGroup>

      {hasClothesRailSection && <InteriorColorRadioGroup />}
    </div>
  );
});

export default Sections;

import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { useForm } from "react-hook-form";
import Input from "./Input";
import { patch, post } from "../api";
import store, {
  setShippingOption,
  setCustomerData,
  toggleModalTerms,
} from "../store";
import RadioGroup from "./RadioGroup";
import RadioGroupItem from "./RadioGroupItem";
import Checkbox from "./Checkbox";

const OrderSummary = () => (
  <>
    <p className="text-xl sm:text-2xl md:text-3xl font-bold mt-8 mb-6">
      Ordersammanfattning
    </p>
    <div className="flex text-sm">
      <table className="w-full">
        <tbody>
          <tr>
            <td>Materialkostnad:</td>
            <td className="text-right">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(
                (store.currentPrice.original_amount -
                  store.currentPrice.shipping_price) /
                  100
              )}
            </td>
          </tr>

          <tr>
            <td>Frakt:</td>
            <td className="text-right">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(store.currentPrice.shipping_price / 100)}
            </td>
          </tr>

          <tr>
            <td>Totalsumma exkl. moms:</td>
            <td className="text-right">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(
                (store.currentPrice.total_amount -
                  store.currentPrice.tax_amount) /
                  100
              )}
            </td>
          </tr>

          {store.campaign && (
            <tr className="text-red">
              <td>Rabatt:</td>
              <td className="text-right">
                -
                {new Intl.NumberFormat("sv-SE", {
                  style: "currency",
                  currency: "SEK",
                }).format(
                  (store.currentPrice.original_amount -
                    store.currentPrice.total_amount) /
                    100
                )}
              </td>
            </tr>
          )}

          <tr>
            <td>Momsbelopp 25%:</td>
            <td className="text-right">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(store.currentPrice.tax_amount / 100)}
            </td>
          </tr>

          <tr>
            <td className="font-bold">Totalsumma att betala inkl. moms:</td>
            <td className="font-bold text-right">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(store.currentPrice.total_amount / 100)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

const KlarnaCheckout = observer(() => {
  const formRef = useRef(null);
  const klarnaWidget = useLocalObservable(() => ({
    isLoaded: false,
    setIsLoaded() {
      klarnaWidget.isLoaded = true;
    },
  }));
  const paymentMethod = useLocalObservable(() => ({
    categories: [],
    setCategories(categories) {
      paymentMethod.categories = categories;
    },
    selectedMethod: "pay_now",
    setSelectedMethod(selectedMethod) {
      paymentMethod.selectedMethod = selectedMethod;

      const elem = document.getElementById("klarna-payments-container");
      elem.querySelectorAll("*").forEach((n) => n.remove());

      Klarna.Payments.load({
        container: "#klarna-payments-container",
        payment_method_category: paymentMethod.selectedMethod,
      });
    },
  }));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
    mode: "all",
    defaultValues: {
      name:
        store.orderInfo.customer.first_name ||
        store.orderInfo.customer.contact_first_name,
      lastname:
        store.orderInfo.customer.last_name ||
        store.orderInfo.customer.contact_last_name,
      email:
        store.orderInfo.customer.email ||
        store.orderInfo.customer.contact_email,
      phone: store.orderInfo.customer.contact_phone,
      delivery_adress: store.orderInfo.customer.delivery_address,
      postal_code: store.orderInfo.customer.delivery_zipcode,
      city: store.orderInfo.customer.delivery_city,
      comment: store.orderInfo.customer_note,
    },
  });

  const orderLocalePrice = {
    locale: "sv-SE",
    purchase_country: "SE",
    purchase_currency: "SEK",
    order_amount: store.currentPrice.total_amount,
    order_lines: [
      {
        name: "Garderob",
        quantity: 1,
        total_amount: store.currentPrice.total_amount,
        unit_price: store.currentPrice.total_amount,
      },
    ],
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.onload = async () => {
      const klarnaSessionRes = await post("/klarna/session", orderLocalePrice);
      const { client_token, payment_method_categories } = klarnaSessionRes.data;

      paymentMethod.setCategories(payment_method_categories);

      Klarna.Payments.init({
        client_token,
      });
      Klarna.Payments.load(
        {
          container: "#klarna-payments-container",
          payment_method_category: paymentMethod.selectedMethod,
        },
        () => {
          klarnaWidget.setIsLoaded();
        }
      );
    };
    script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js";
    script.id = "klarna-js";

    document.body.appendChild(script);

    return () => {
      document.getElementById("klarna-js").remove();
    };
  }, []);

  const onSubmit = async (data) => {
    const shipping_address = {
      given_name: data.name,
      family_name: data.lastname,
      email: data.email,
      street_address: data.delivery_adress,
      postal_code: data.postal_code,
      city: data.city,
      phone: data.phone,
      country: "SE",
    };

    const billing_address = {
      ...shipping_address,
    };

    Klarna.Payments.authorize(
      {
        container: "#klarna_container",
        payment_method_category: paymentMethod.selectedMethod,
      },
      {
        ...orderLocalePrice,
        billing_address,
        shipping_address,
      },
      async (authorizeRes) => {
        const { authorization_token } = authorizeRes;

        setCustomerData(data);

        const { order } = store;

        order.customer = {
          first_name: data.name,
          last_name: data.lastname,
          email: data.email,
          contact_phone: data.phone,
          billing_address: data.billing_adress
            ? data.billing_adress
            : data.delivery_adress,
          billing_zipcode: data.billing_postal_code
            ? data.billing_postal_code
            : data.postal_code,
          billing_city: data.billing_city ? data.billing_city : data.city,
          delivery_address: data.delivery_adress,
          delivery_zipcode: data.postal_code,
          delivery_city: data.city,
          type: "person",
          customer_note: data.comment,
        };

        order.is_klarna_order = true;

        if (authorization_token) {
          let id = 0;
          if (store.orderInfo.isRetrievedOrder) {
            await patch(`/public/order/${store.orderInfo.globalId}`, order);
            id = store.orderInfo.orderId;
          } else {
            const { data } = await post("/public/order", order);
            id = data.id;
          }

          await post(`/klarna/order/${authorization_token}`, {
            ...orderLocalePrice,
            billing_address,
            shipping_address,
            merchant_reference1: id.toString(),
          });

          store.requestSent = true;
        }
      }
    );
  };

  const klarnaShippingOptions = store.shipping.filter(
    (shipping) => shipping.value !== "assembly"
  );

  const handleShippingChange = async (e) => {
    setShippingOption(e.target.value);

    const currentPrice = store.currentPrice;

    const { data: newPrice } = await post("/public/order/dry", store.order);
    store.currentPrice = newPrice;

    if (currentPrice.total_amount !== newPrice.total_amount) {
      Klarna.Payments.load(
        {
          container: "#klarna-payments-container",
          payment_method_category: paymentMethod.selectedMethod,
        },
        {
          // Data to be updated
          order_amount: store.currentPrice.total_amount,
          order_lines: [
            {
              name: "Garderob",
              quantity: 1,
              total_amount: store.currentPrice.total_amount,
              unit_price: store.currentPrice.total_amount,
            },
          ],
        }
      );
    }
  };

  return (
    <div className="lg:flex flex-1 flex-wrap px-4 lg:px-12 pb-20 mt-20 lg:mx-auto">
      <form
        className="lg:flex max-w-screen-xl"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-wrap content-start justify-center space-y-3 sm:justify-start lg:mr-24">
          <div className="w-full sm:flex sm:space-x-4 space-y-3 sm:space-y-0">
            <Input
              label="Förnamn"
              info={errors.name && "Måste fylla i ett förnamn."}
              full
              type="text"
              {...register("name", { required: true })}
            />
            <Input
              label="Efternamn"
              info={errors.lastname && "Måste fylla i ett efternamn."}
              full
              type="text"
              {...register("lastname", { required: true })}
            />
          </div>
          <div className="w-full sm:flex sm:space-x-4 space-y-3 sm:space-y-0">
            <Input
              label="E-post"
              info={errors.email && "Inte en giltig e-post address."}
              full
              type="email"
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+\.\S+$/i,
              })}
            />
            <Input
              label="Telefon"
              info={errors.phone && "Måste fylla i ett telefonnummer."}
              full
              type="tel"
              {...register("phone", {
                required: true,
                minLength: 5,
                pattern: /^\+?[0-9]+$/i,
              })}
            />
          </div>
          <div className="w-full">
            <p className="text-2xl md:text-3xl font-bold mt-8 mb-4">
              Leverans och montering
            </p>
            <RadioGroup>
              {klarnaShippingOptions.map((shipping, index) => (
                <RadioGroupItem
                  key={shipping.value}
                  name="shipping"
                  value={shipping.value}
                  label={shipping.label}
                  activeValue={store.shippingOption}
                  index={index}
                  options={klarnaShippingOptions}
                  {...register("shipping", {
                    required: true,
                    onChange: handleShippingChange,
                  })}
                />
              ))}
            </RadioGroup>
            {errors.shipping && (
              <div className="flex mt-2">
                <p className="text-med text-xs">Måste välja leverans.</p>
              </div>
            )}
          </div>
          <div className="w-full flex flex-wrap md:flex-nowrap md:space-x-4 space-y-4 md:space-y-0">
            <div className="w-full space-y-3">
              <div className="w-full flex space-x-4">
                <Input
                  label="Leveransadress"
                  info={
                    errors.delivery_adress && "Måste fylla i en leveransadress."
                  }
                  full
                  type="text"
                  {...register("delivery_adress", { required: true })}
                />
              </div>
              <div className="w-full flex space-x-4">
                <Input
                  label="Postnummer"
                  info={errors.postal_code && "Obligatoriskt"}
                  full
                  type="text"
                  {...register("postal_code", {
                    required: true,
                    pattern: /^[0-9]+$/i,
                  })}
                />
                <Input
                  label="Ort"
                  info={errors.city && "Måste fylla i en stad."}
                  full
                  type="text"
                  {...register("city", { required: true, pattern: /^\D+$/i })}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full flex flex-col mb-8">
              <OrderSummary />
            </div>
          </div>
        </div>
        <div>
          <div className="w-full flex flex-col mb-4">
            {/* We render this invisible span so that the RadioGroup is */}
            {/* aligned with the form to the left on larger screens. */}
            <span className="invisible hidden lg:inline-block font-medium text-sm mb-2">
              Betalningsmetod
            </span>
            {klarnaWidget.isLoaded && (
              <RadioGroup>
                {paymentMethod.categories.map((category, index) => (
                  <RadioGroupItem
                    key={category.identifier}
                    name={category.name}
                    value={category.identifier}
                    label={category.name}
                    activeValue={paymentMethod.selectedMethod}
                    onChange={(e) =>
                      paymentMethod.setSelectedMethod(e.target.value)
                    }
                    index={index}
                    options={paymentMethod.categories}
                  />
                ))}
              </RadioGroup>
            )}
          </div>
          <div
            id="klarna-payments-container"
            className="bg-toolbar flex justify-center xl:w-600 lg:flex-shrink-0 rounded-lg p-6 md:py-10 mb-10 lg:mb-0 shadow-checkout"
          ></div>
          <div className="mt-9">
            <Checkbox
              label={
                <>
                  Jag godkänner&nbsp;
                  <a
                    href="#"
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleModalTerms();
                    }}
                  >
                    Garderobsfabrikens köpvillkor
                  </a>
                  .
                </>
              }
              name="terms"
              {...register("terms", { required: true })}
            />
            {errors.terms && (
              <p className="text-med text-xs mt-2">
                Måste godkänna köpvillkoren.
              </p>
            )}
          </div>
          {klarnaWidget.isLoaded && (
            <div className="flex justify-end">
              <button className="bg-dark text-white font-medium text-lg rounded-lg uppercase px-6 py-3 mt-5">
                Betala
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
});

export default KlarnaCheckout;

import axios from "axios";
import { API_URL } from "./constants";

async function request(method, path, data) {
  return await axios({
    method,
    url: API_URL + path,
    data,
  });
}

export const get = (path, data) => request("get", path, data);
export const post = (path, data) => request("post", path, data);
export const patch = (path, data) => request("patch", path, data);

import React, { useEffect, useRef, Fragment } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { useDrag } from "react-dnd";
import store, {
  safeToAddProducts,
  completeStep,
  setCurrentOrder,
  setCurrentPrice,
  setHandleColor,
} from "../store";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import {
  API_URL,
  ClothesRailName,
  DrawerWithHandleName,
  HandleColors,
  JewelryBoxName,
  OrderType,
  ProductDropType,
  ShelfName,
  TrouserHangerName,
} from "../constants";
import InfoBox from "./InfoBox";
import DoorCollision from "../assets/door_collision.png";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";
import ToggleSwitch from "./ToggleSwitch";
import InteriorColorRadioGroup from "./InteriorColorRadioGroup";

const Product = observer(
  ({
    productId,
    configId,
    src,
    name,
    width,
    height,
    depth,
    safeToAdd,
    overflowImg,
  }) => {
    const productRef = useRef();
    const infoBoxButtonRef = useRef();

    const infoBox = useLocalObservable(() => ({
      isVisible: false,
      toggle() {
        infoBox.isVisible = !infoBox.isVisible;
      },
      hide() {
        infoBox.isVisible = false;
      },
    }));

    useEffect(() => {
      if (safeToAdd) return;

      const handleClick = (e) => {
        if (
          e.target !== infoBoxButtonRef.current &&
          !document.getElementById("infobox-root").contains(e.target)
        ) {
          infoBox.hide();
        }
      };

      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [safeToAdd]);

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: ProductDropType,
        item: {
          productId,
          configId,
        },
        collect: (monitor) => ({
          isDragging: Boolean(monitor.isDragging()),
        }),
      }),
      [productId, configId]
    );

    return (
      <div
        className="self-stretch flex flex-col items-center justify-between transition-opacity duration-200 text-center select-none"
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        ref={productRef}
      >
        <span
          className={`font-medium text-sm whitespace-nowrap mb-1 ${
            !safeToAdd ? "opacity-25" : ""
          }`}
        >
          {name}
        </span>
        <div
          ref={safeToAdd ? drag : undefined}
          className={`relative mb-1 ${!safeToAdd ? "opacity-25" : ""} ${
            overflowImg ? "h-10 overflow-hidden" : ""
          }`}
          style={{
            cursor: safeToAdd
              ? isDragging
                ? "grabbing"
                : "grab"
              : "not-allowed",
            maxWidth: 200,
          }}
        >
          {(name === DrawerWithHandleName || name === JewelryBoxName) && (
            <div
              className={`w-1/4 h-1 border-b border-b-gray-300 transform ${
                name === JewelryBoxName
                  ? "absolute left-1/2 -translate-x-1/2"
                  : "translate-y-full mx-auto"
              }`}
              style={{
                backgroundColor: store.handleColor,
                top: name === JewelryBoxName ? "10%" : null,
              }}
            ></div>
          )}
          <img
            src={API_URL + src}
            onDragStart={(e) => e.preventDefault()}
            style={{ maxHeight: !overflowImg ? 50 : null }}
          />
        </div>

        <span className="font-normal text-xs text-med whitespace-nowrap">
          <span className={!safeToAdd ? "opacity-25" : ""}>
            {width} x {height} x {depth} mm
          </span>
          {!safeToAdd && (
            <span
              className="w-4 h-4 inline-flex justify-center items-center text-xs text-warning leading-none rounded-full border border-warning cursor-pointer ml-1 z-10"
              onClick={infoBox.toggle}
              ref={infoBoxButtonRef}
            >
              ?
            </span>
          )}
        </span>

        {infoBox.isVisible && (
          <InfoBox ref={productRef} error>
            <img src={DoorCollision} className="w-full rounded-t-sm" />
            <div className="max-h-64 lg:max-h-full overflow-auto px-6 py-3">
              <h3 className="text-light font-medium">Dörrkollision</h3>
              <p className="text-white text-sm">
                För att undvika kollision mellan inredning och dörr så kan du
                inte välja eller placera alternativet i denna sektion. Om en
                dörr placeras framför inredningen så går det inte dra ut lådan
                eller korgen.
              </p>
            </div>
            <span className="w-2.5 h-2.5 absolute bottom-0 left-1/2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-warning rounded-sm"></span>
          </InfoBox>
        )}
      </div>
    );
  }
);

const Interior = observer(() => {
  const state = useLocalObservable(() => ({
    get filteredProducts() {
      const { activeSection, bodyColor, detailColor } = store;
      const activeSectionType = activeSection.type;
      const isSafeToAddProducts = safeToAddProducts(
        activeSection.pos,
        activeSection.width
      );
      const sectionProducts = [];

      store.interiorProducts.forEach((product) => {
        // We check against either the body or detail color depending on which
        // one decides the color of the product.
        const selectedColor =
          product.color_group === "body" ? bodyColor : detailColor;
        const productConfig = product.configurations.find(
          (config) =>
            config.section_type === activeSectionType &&
            config.color === selectedColor
        );

        if (
          store.orderType !== OrderType.OnlyInterior &&
          (product.name === DrawerWithHandleName ||
            product.name === JewelryBoxName) &&
          ((store.tracks === 2 && store.size.depth < 610) ||
            (store.tracks === 3 && store.size.depth < 650))
        ) {
          return;
        }

        if (productConfig) {
          sectionProducts.push({
            id: product.id,
            configId: productConfig.id,
            src: productConfig.image,
            name: product.name.trim(),
            width: productConfig.width,
            height: productConfig.height,
            depth: productConfig.depth,
            safeToAdd:
              product.name === ShelfName ||
              product.name === ClothesRailName ||
              store.tracks === 3 ||
              store.doors.length > 3 ||
              isSafeToAddProducts,
            isTrouserHanger: product.name.includes(TrouserHangerName),
          });
        }
      });

      return sectionProducts;
    },
    get sectionProductCount() {
      if (!store.activeSection) {
        return 0;
      }
      if (store.activeSection.products) {
        return store.activeSection.products.length;
      }
      return 0;
    },
  }));

  useEffect(() => {
    completeStep();
  }, []);

  useEffectSkipFirst(() => {
    setCurrentOrder();
    setCurrentPrice();
  }, [
    state.sectionProductCount,
    store.handleColor,
    store.detailColor,
    store.interiorLighting,
  ]);

  return (
    <>
      {store.activeSection ? (
        <>
          <ToggleSwitch
            label="Lägg till inredningsbelysning"
            name="interiorLighting"
            info={{
              title: "Inredningsbelysning",
              description: (
                <>
                  Belysning på inredningen är en detalj som inte bara är
                  praktiskt och gör att ni kommer se innehållet i er garderob
                  utan den ger också den där extra wow känslan. När ni väljer
                  belysning kommer det sitta en aluminiumskena på inredningens
                  topphylla på 2080 mm från golvet där en LED-list sitter längs
                  hela garderobens bredd.
                  <br />
                  {store.orderType !== OrderType.OnlyInterior ? (
                    <>
                      <b>Med skjutdörrar:</b> då sitter det en rörelsesensor
                      bakom varje dörr som gör att belysningen tänds när man
                      öppnar dörrarna.
                    </>
                  ) : (
                    <>
                      <b>Bara inredning:</b> planerar du en walk in closet där
                      du inte ska ha skjutdörrar framför tänds belysning
                      istället med hjälp av en trådlös och dimbar dosa som kan
                      fästas på valfri plats i rummet.
                    </>
                  )}
                </>
              ),
            }}
          />
          <InteriorColorRadioGroup />
          {state.filteredProducts.map((product) => (
            <Fragment key={product.id}>
              <Product
                productId={product.id}
                configId={product.configId}
                src={product.src}
                type={product.name.trim()}
                name={product.name}
                width={product.width}
                height={product.height}
                depth={product.depth}
                safeToAdd={product.safeToAdd}
                overflowImg={product.isTrouserHanger}
              />
              {product.name === DrawerWithHandleName &&
                store.activeSectionHasDrawerWithHandle &&
                (store.size.depth >= 630 ||
                  store.orderType === OrderType.OnlyInterior) && (
                  <ColorRadioGroup title="Handtag">
                    <ColorRadioGroupItem
                      name="handle"
                      value={HandleColors.White}
                      label="Vit"
                      color={HandleColors.White}
                      first
                      last={false}
                      isActive={store.handleColor === HandleColors.White}
                      onChange={() => setHandleColor(HandleColors.White)}
                    />
                    <ColorRadioGroupItem
                      name="handle"
                      value={HandleColors.Silver}
                      label="Silver"
                      color={HandleColors.Silver}
                      first={false}
                      last={false}
                      isActive={store.handleColor === HandleColors.Silver}
                      onChange={() => setHandleColor(HandleColors.Silver)}
                    />
                    <ColorRadioGroupItem
                      name="handle"
                      value={HandleColors.Black}
                      label="Svart"
                      color={HandleColors.Black}
                      first={false}
                      last
                      isActive={store.handleColor === HandleColors.Black}
                      onChange={() => setHandleColor(HandleColors.Black)}
                    />
                  </ColorRadioGroup>
                )}
            </Fragment>
          ))}
        </>
      ) : (
        <p className="font-medium text-sm whitespace-nowrap block md:hidden">
          Välj sektion
        </p>
      )}
    </>
  );
});

export default Interior;

import React from "react";

const LandscapeOverlay = () => (
  <div
    className="flex justify-center items-center flex-col absolute inset-0 z-50"
    style={{ backgroundColor: "#C9C5BC" }}
  >
    <h3 className="text-lg font-bold">
      Ritverktyget är inte tillgänglig för använding i liggande läge.
    </h3>
    <p>Vänligen rotera din enhet</p>
  </div>
);

export default LandscapeOverlay;

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import store, {
  setMeasurement,
  completeStep,
  removeCompletedStep,
  setSidewall,
  setShowAlertPopup,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import Input from "./Input";
import { OrderType } from "../constants";

const Size = observer(() => {
  useEffect(() => {
    const shouldCompleteStep =
      store.orderType !== OrderType.OnlyDoors
        ? store.size.width && store.size.height && store.size.depth
        : store.size.width && store.size.height;
    if (shouldCompleteStep) {
      completeStep();
    }
  }, []);

  const { dimensions } = store;

  const handleChange = (e) => {
    setMeasurement(e.target.name, e.target.value);

    checkDimensions();
  };

  const handleBlur = (e) => {
    if (parseInt(e.target.value, 10) < parseInt(e.target.min, 10)) {
      setShowAlertPopup(true, `Minst ${e.target.min} mm`, "Ok");
      setMeasurement(e.target.name, e.target.min);
    } else if (parseInt(e.target.value, 10) > parseInt(e.target.max, 10)) {
      setShowAlertPopup(true, `Max ${e.target.max} mm`, "Ok");
      setMeasurement(e.target.name, e.target.max);
    }

    checkDimensions();
  };

  const checkDimensions = () => {
    const validWidth =
      store.size.width &&
      store.size.width <= dimensions.width.max &&
      store.size.width >= dimensions.width.min;
    const validHeight =
      store.size.height &&
      store.size.height <= dimensions.height.max &&
      store.size.height >= dimensions.height.min;
    const validDepth =
      dimensions.depth &&
      store.size.depth <= dimensions.depth.max &&
      store.size.depth >= dimensions.depth.min;

    if (validWidth && validHeight && validDepth) {
      if (
        store.placement !== "wall_to_wall" &&
        store.orderType !== OrderType.OnlyInterior
      ) {
        setSidewall();
        setCurrentOrder();
        setCurrentPrice();
      }
      completeStep();
    } else {
      removeCompletedStep();
    }
  };

  return (
    <>
      <Input
        label="Bredd"
        info={`${dimensions.width.min} - ${dimensions.width.max} mm`}
        name="width"
        min={dimensions.width.min}
        max={dimensions.width.max}
        onChange={handleChange}
        onBlur={handleBlur}
        value={store.size.width || ""}
        help={{
          title: "Bredd",
          description: (
            <>
              Går garderoben mellan två väggar ska man gärna mäta bredden på 3
              punkter. Högst upp mot taket, mitten och längst ner vid golvet. Om
              det är snett så ska man i normala fall välja den största bredden
              så vi är säkra på att dörrarna täcker upp hela ytan. <br />
              Läs gärna vår mätguide{" "}
              <a
                className="underline"
                href="https://garderobsfabriken.se/hur-man-bast-mater-infor-kop-av-mattanpassade-skjutdorrar/"
                target="_blank"
                rel="noreferrer"
              >
                här
              </a>
              .
              <br />
              Vi kan kostnadsfritt skicka en avståndsmätare/lasermätare till er
              med förbetald retursedel. Vi finns här för att hjälpa till så är
              ni osäkra på måtten, tveka inte på att höra av er.
            </>
          ),
        }}
      />
      <Input
        name="height"
        label="Höjd"
        info={
          store.orderType !== OrderType.OnlyInterior
            ? `${dimensions.height.min} - ${dimensions.height.max} mm`
            : null
        }
        min={dimensions.height.min}
        max={dimensions.height.max}
        onChange={handleChange}
        onBlur={handleBlur}
        value={store.size.height || ""}
        help={
          store.orderType !== OrderType.OnlyInterior
            ? {
                title: "Höjd",
                description: (
                  <>
                    På höjden rekommenderar vi att ni ska mäta på 3 punkter för
                    att se om det skiljer något. På vänster sida, mitten och
                    höger sida. I vanliga fall ska lägsta måttet användas. Det
                    finns ca 10 mm att justermån i höjdled. <br />
                    Läs gärna vår mätguide{" "}
                    <a
                      className="underline"
                      href="https://garderobsfabriken.se/hur-man-bast-mater-infor-kop-av-mattanpassade-skjutdorrar/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      här
                    </a>
                    .
                    <br />
                    Vi kan kostnadsfritt skicka en avståndsmätare/lasermätare
                    till er med förbetald retursedel. Vi finns här för att
                    hjälpa till så är ni osäkra på måtten, tveka inte på att
                    höra av er.
                  </>
                ),
              }
            : null
        }
        disabled={store.orderType === OrderType.OnlyInterior}
      />
      {dimensions.depth && (
        <Input
          label="Djup"
          info={
            store.orderType !== OrderType.OnlyInterior
              ? `${dimensions.depth.min} - ${dimensions.depth.max} mm`
              : null
          }
          name="depth"
          min={dimensions.depth.min}
          max={dimensions.depth.max}
          onChange={handleChange}
          onBlur={handleBlur}
          value={store.size.depth || ""}
          help={
            store.orderType !== OrderType.OnlyInterior
              ? {
                  title: "Djup",
                  description: (
                    <>
                      När ni fyller i djupet så ange det största djupet ni kan
                      ha då detta val kommer att påverka möjliga val på er
                      garderob. Inredningen är 505 mm djup. 2 spårig skena 82 mm
                      djup och 3 spårig skena 122 mm djup. Även val som handtag
                      på lådor och belysning kan begränsas av ett för litet
                      totaldjup på garderob.
                      <br />
                      Vi finns här för att hjälpa till så är ni osäkra på
                      måtten, tveka inte på att höra av er.
                    </>
                  ),
                }
              : null
          }
          disabled={store.orderType === OrderType.OnlyInterior}
        />
      )}
    </>
  );
});

export default Size;

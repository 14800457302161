import { observable, runInAction, toJS } from "mobx";
import uniqueId from "lodash/uniqueId";
import { post, get } from "./api";
import {
  SectionTypes,
  SectionWidths,
  OrderType,
  SideWallWidth,
  Steps,
  WardrobeHoles,
  AllowedMiddleShelfOffset,
  ShelfName,
  BodyColorNames,
  DetailColorNames,
  HandleColors,
  HandleColorNames,
  DrawerWithHandleName,
  JewelryBoxName,
  InteriorWardrobeMaxHeight,
  ClothesRailName,
} from "./constants";
import fieldLayouts from "./fieldLayouts";

const store = observable({
  orderType: null,
  orderInfo: {
    isRetrievedOrder: false,
    globalId: 0,
    orderId: 0,
    wardrobeId: 0,
    customer: {},
    customer_wants_sms: false,
    customer_note: "",
  },
  wardrobeTypes: [
    { label: "Hel garderob", value: OrderType.FullWardrobe },
    { label: "Bara skjutdörrar", value: OrderType.OnlyDoors },
    { label: "Bara inredning", value: OrderType.OnlyInterior },
  ],
  placements: [
    { label: "Vägg till vägg", value: "wall_to_wall" },
    { label: "Centrerad", value: "center" },
    { label: "Till vänster", value: "left" },
    { label: "Till höger", value: "right" },
  ],
  get shipping() {
    const shippingPrice = store.orderType === OrderType.OnlyDoors ? 1700 : 2200;
    return [
      { label: `Frakt +${shippingPrice}kr`, value: "ship" },
      { label: "Montering", value: "assembly" },
      { label: "Hämta i Skövde", value: "pickup_skövde" },
      { label: "Hämta i Örebro", value: "pickup_örebro" },
    ];
  },
  trackConfig: {
    2: {
      2: [2, 1],
    },
    3: {
      2: [1, 2, 1],
      3: [3, 2, 1],
    },
    4: {
      2: [2, 1, 2, 1],
    },
    5: {
      2: [2, 1, 2, 1, 2],
      3: [1, 3, 2, 3, 1],
    },
    6: {
      2: [2, 1, 2, 1, 2, 1],
      3: [1, 2, 3, 2, 1, 2],
    },
  },
  profiles: null,
  areaColors: null,
  sidewalls: null,
  beads: null,
  step: Steps.Placements,
  completedSteps: [],
  placement: null,
  size: {
    width: null,
    height: null,
    depth: null,
  },
  get dimensions() {
    return {
      width: {
        min: 800,
        max: store.orderType === OrderType.OnlyInterior ? 6000 : 4800,
      },
      height: {
        min:
          store.orderType === OrderType.OnlyDoors
            ? 800
            : InteriorWardrobeMaxHeight,
        max: 2540,
      },
      depth: {
        min: store.orderType === OrderType.OnlyInterior ? 505 : 600,
        max: 800,
      },
    };
  },
  get cappedWidth() {
    const { dimensions, size } = store;
    const minWidth = dimensions.width.min;
    const maxWidth = dimensions.width.max;

    return size.width
      ? Math.min(Math.max(minWidth, size.width), maxWidth)
      : (maxWidth + minWidth) / 2;
  },
  get cappedHeight() {
    const { dimensions, size } = store;
    const minHeight = dimensions.height.min;
    const maxHeight = dimensions.height.max;

    return size.height
      ? Math.min(Math.max(minHeight, size.height), maxHeight)
      : (maxHeight + minHeight) / 2;
  },
  get innerWidth() {
    const {
      size: { width },
      placement,
      orderType,
    } = store;
    if (orderType === OrderType.OnlyInterior || placement === null)
      return width;

    switch (placement) {
      case "wall_to_wall":
        return width;
      case "center":
        return width - 2 * SideWallWidth;
      case "left":
      case "right":
      default:
        return width - SideWallWidth;
    }
  },
  tracks: 2,
  muffler: false,
  softClosing: false,
  profile: { type: 1, color: 1 },
  lighting: false,
  interiorLighting: false,
  doors: [],
  activeDoor: null,
  get hasActiveField() {
    return store.doors.some((door) =>
      door.layout.fields.some((field) => field.active)
    );
  },
  customerData: null,
  requestQuotation: false,
  requestSent: false,
  bead: false,
  beadProductId: null,
  beadConfig: null,
  defaultBeadConfig: null,
  sidewallProductId: null,
  sidewallConfig: null,
  shippingOption: null,
  paymentType: null,
  order: null,
  currentPrice: null,
  sections: [],
  interiorProducts: [],
  bodyColor: BodyColorNames.White,
  detailColor: DetailColorNames.White,
  handleColor: HandleColors.White,
  activeSection: null,
  get activeSectionHasDrawerWithHandle() {
    if (store.activeSection === null) return false;

    return store.activeSection.products.some(
      (product) => product.name === DrawerWithHandleName
    );
  },
  windowInnerWidth: window.innerWidth,
  get isMobile() {
    return store.windowInnerWidth < 768;
  },
  interiorAccessories: [],
  possibleInteriorAccessories: [],
  accessories: [],
  handleConfigs: null,
  handleConfig: null,
  orientation: window.orientation || 0,
  shouldHaveTopShelf: false,
  topShelf: null,
  get topShelfWidth() {
    return store.size.width <= 1700 ? 1700 : 2500;
  },
  get topShelfConfigId() {
    if (store.shouldHaveTopShelf) {
      const config = store.topShelf.configurations.find(
        (config) =>
          config.width === store.topShelfWidth &&
          config.color === store.bodyColor
      );
      if (config) {
        return config.id;
      }
      return null;
    }
    return null;
  },

  showOnboardingModal: true,
  showSizeModal: true,
  showSectionsModal: true,
  showInteriorModal: true,
  showInteriorSectionInfoModal: false,
  showInteriorSectionInfoModalSeen: false,

  showConfirmPopup: false,
  confirmText: "",
  confirmTrigger: "",
  sectionsWithUnsafeProducts: [],

  showAlertPopup: false,
  alertText: "",
  alertButtonText: "",

  modalProduct: null,
  termsModal: false,
  campaign: null,
});

export const setStep = (step) => {
  store.step = step;
};

export const setProfiles = (profiles) => {
  store.profiles = profiles;
};

export const setAreaColors = (colors) => {
  store.areaColors = colors;
};

export const setBase = (base) => {
  store.sidewalls = base.find((group) => group.name === "Sidovägg").Products[0];
  store.sidewallProductId = store.sidewalls.id;
  store.beads = base.find((group) => group.name === "Anslagslist").Products[0];
  store.beadProductId = store.beads.id;
  store.beadConfig = store.defaultBeadConfig = store.beads.configurations[0];
  store.topShelf = base
    .find((group) => group.name === "Stomme")
    .Products.find((product) => product.name === "Topphylla");
};

export const setInterior = (interior) => {
  // There is just one group of products in the array,
  // but we loop just to make sure.
  store.interiorProducts = interior
    .reduce((products, group) => {
      products.push(...group.Products);

      return products;
    }, [])
    .sort((a, b) => a.id - b.id);
};

export const setShouldHaveTopShelf = (shouldHaveTopShelf) => {
  store.shouldHaveTopShelf = shouldHaveTopShelf;
};

export const setInteriorAccessories = (interiorAccessories) => {
  // There is just one group of products in the array,
  // but we loop just to make sure.
  store.interiorAccessories = interiorAccessories.reduce((products, group) => {
    products.push(...group.Products);

    return products;
  }, []);
};

export const setAccessories = (accessories) => {
  // There is just one group of products in the array,
  // but we loop just to make sure.
  accessories
    .reduce((products, group) => {
      products.push(...group.Products);

      return products;
    }, [])
    .forEach((accessory) => {
      if (accessory.configurations.length > 1) {
        accessory.configurations.forEach((config) => {
          store.accessories.push({
            product_id: accessory.id,
            name: `${accessory.name.trim()} ${
              config.width ? `${config.width}mm` : null
            }`,
            config_id: config.id,
            price: config.price,
            amount: 0,
            image: accessory.image,
          });
        });
      } else {
        store.accessories.push({
          product_id: accessory.id,
          name: accessory.name,
          config_id: accessory.configurations[0].id,
          price: accessory.configurations[0].price,
          amount: 0,
          image: accessory.image,
        });
      }
    });
};

export const setMeasurement = (measurement, value) => {
  if (store.doors.length > 0 || store.sections.length < 0) {
    setShowConfirmPopup(
      true,
      "Om du ändrar måtten på garderoben så kommer dörrar och inredning att återställas. Vill du återställa dessa?",
      measurement
    );
    return;
  }
  store.size[measurement] = parseInt(value, 10);
};

export const setSoftClosing = (softClosing) => {
  store.softClosing = softClosing;
};

export const setBead = (bead) => {
  store.bead = bead;
};

export const setMuffler = (muffler) => {
  store.muffler = muffler;
};

export const setLighting = (lighting, interiorLighting) => {
  store.lighting = lighting;
  store.interiorLighting = interiorLighting;
};

export const setBeadConfig = (beadConfig) => {
  store.beadConfig = beadConfig;

  setSidewall();
};

export const setSidewall = () => {
  const currentDepth = store.size.depth > 640 ? 800 : 640;
  const sidewallConfig = store.sidewalls.configurations.find(
    (config) =>
      config.depth === currentDepth && config.color === store.beadConfig.color
  );
  store.sidewallConfig = sidewallConfig;
};

export const setTracks = (amount) => {
  if (store.tracks === 2) {
    store.tracks = amount;
    return;
  }

  const currentTracks = store.tracks;
  store.tracks = amount;

  // Clear unsafe products if changing tracks
  store.sections.map((section) => {
    if (section.products && haveUnsafeProducts(section)) {
      store.sectionsWithUnsafeProducts.push(section);
    }
  });

  if (store.sectionsWithUnsafeProducts.length > 0) {
    setShowConfirmPopup(
      true,
      "Vissa produkter passar inte i nuvarande konfiguration.\nVill du återställa dessa?",
      "tracks"
    );
    store.tracks = currentTracks;
  }
};

export const setDoors = (doors) => {
  store.doors = doors;
};

export const setProfileType = (type) => {
  store.profile.type = type;
};

export const setProfileColor = (color) => {
  store.profile.color = color;
};

export const setActiveDoor = (doorId) => {
  store.doors.forEach(
    (door) => (door.active = door.id === doorId ? true : false)
  );
  store.activeDoor = store.doors.find((door) => door.active) || null;
};

export const setActiveField = (doorId, fieldIndex) => {
  store.doors.forEach((door) =>
    door.layout.fields.forEach(
      (field, i) =>
        (field.active = door.id === doorId && i === fieldIndex ? true : false)
    )
  );
};

export const setFieldProduct = (areaColorId, productId) => {
  store.doors.forEach((door) =>
    door.layout.fields.forEach((field) => {
      if (field.active) {
        field.fill = {
          group: areaColorId,
          product: productId,
        };
      }
    })
  );
};

export const setDoorLayout = (door, layout) => {
  const fields = [];
  fieldLayouts[layout].fields.forEach((field) => {
    fields.push({
      size: field,
      fill: { group: 3, product: 8 },
      active: false,
    });
  });
  door.layout = {
    type: fieldLayouts[layout].id,
    fields,
  };
};

export const resetActiveField = () => {
  store.doors.forEach(
    (door) =>
      door.layout.fields &&
      door.layout.fields.forEach((field) => (field.active = false))
  );
};

export const getActiveProfileInfo = () => {
  if (store.profile.type && store.profile.color) {
    const profile = store.profiles.find(({ id }) => id === store.profile.type);
    const color = profile.Products.find(({ id }) => id === store.profile.color);

    return { type: profile.name, color: color.name };
  }
};

export const getFieldProductName = (groupId, productId) => {
  const areaGroup = store.areaColors.find((group) => group.id === groupId);
  const productName = areaGroup.Products.find(
    (products) => products.id === productId
  ).name;

  return `${areaGroup.name}: ${productName}`;
};

export const getPlacementLabel = () => {
  return store.placements.find(({ value }) => value === store.placement).label;
};

export const prevStep = () => {
  // If the user navigates back from either the quotation or klarna checkout pages
  // we reset the payment type.
  if (store.step === Steps.Summary) {
    store.paymentType = null;
    store.shippingOption = null;

    // Remove completed steps from and including summary step
    const summaryStepIndex = store.completedSteps.indexOf(Steps.Summary);
    if (summaryStepIndex >= 0) {
      store.completedSteps.splice(
        summaryStepIndex,
        store.completedSteps.length - summaryStepIndex
      );
    }
  }

  const currentCompletedStepIndex = store.completedSteps.indexOf(store.step);
  if (currentCompletedStepIndex > -1) {
    store.step = store.completedSteps[currentCompletedStepIndex - 1];
  } else {
    store.step = store.completedSteps[store.completedSteps.length - 1];
  }
  // If the user navigates back from the interior step
  // reset active section
  if (store.step === Steps.Sections) {
    store.activeSection = null;
  }
};

export const nextStep = () => {
  // If the user navigates from interior step
  // reset active section
  if (store.step === Steps.Interior) {
    store.activeSection = null;
  }

  // Skip steps for furnishings type
  if (store.step === Steps.Size && store.orderType === OrderType.OnlyInterior) {
    store.step = Steps.Sections;
  } else if (
    store.orderType === OrderType.OnlyDoors &&
    store.step === Steps.Lighting
  ) {
    store.step = Steps.Accessories;
  } else if (store.step === Steps.Sections) {
    // Skip interior step if no section can contain products
    const shouldShowInterior = store.sections.some(
      (section) =>
        [
          SectionTypes.S,
          SectionTypes.M,
          SectionTypes.L,
          SectionTypes.XL,
        ].indexOf(section.type) > -1
    );
    if (shouldShowInterior) {
      store.step++;
    } else {
      store.step = Steps.Accessories;
    }
  } else if (store.step === Steps.Interior) {
    // Skip interior accessories step if there
    // are no relevant products
    if (store.possibleInteriorAccessories.length > 0) {
      store.step++;
    } else {
      store.step = Steps.Accessories;
    }
  } else {
    store.step++;
  }
  if (store.step === Steps.Summary) {
    setCurrentOrder();
    setCurrentPrice();
  }
};

export const setPlacement = (placement) => {
  if (store.bead || store.sections.length > 0) {
    setShowConfirmPopup(
      true,
      "Om du ändrar placering på garderoben så kommer inredning och anslagslister att återställas. Vill du återställa dessa?",
      "placement"
    );
    return;
  }

  store.placement = placement;
  setSidewall();
};

export const setShippingOption = (shippingOption) => {
  store.shippingOption = shippingOption;
  setCurrentOrder();
};

export const completeStep = () => {
  if (store.completedSteps.indexOf(store.step) < 0) {
    store.completedSteps.push(store.step);
    store.completedSteps.sort((a, b) => a - b);
  }
};

export const removeCompletedStep = () => {
  const stepIndex = store.completedSteps.indexOf(store.step);
  if (stepIndex > -1) {
    store.completedSteps.splice(stepIndex, 1);
  }
};

export const setCustomerData = (data) => {
  store.customerData = data;
};

export const setRequestQuotation = () => {
  store.requestQuotation = !store.requestQuotation;
};

export const setOrderType = (orderType) => {
  // Reset completed steps and size
  store.completedSteps = [];
  store.orderType = null;
  store.placement = null;
  store.size = {
    width: null,
    height: null,
    depth: null,
  };
  store.tracks = 2;
  store.muffler = false;
  store.softClosing = false;
  store.profile = {
    type: 1,
    color: 1,
  };
  store.lighting = false;
  store.interiorLighting = false;
  store.doors = [];
  store.activeDoor = null;
  store.customerData = null;
  store.bead = false;
  store.sidewallConfig = null;
  store.shippingOption = null;
  store.paymentType = null;
  store.currentPrice = null;
  if (orderType === OrderType.OnlyInterior) {
    store.placement = "center";
    store.size = {
      width: null,
      height: InteriorWardrobeMaxHeight,
      depth: 505,
    };
    completeStep();
  }
  store.orderType = orderType;
  store.sections.forEach((section, index) => {
    removeSection(index);
  });
  store.sections = [];
  store.possibleInteriorAccessories = [];
  store.order = null;
  store.shouldHaveTopShelf = false;
};

export const setPaymentType = (type) => {
  store.paymentType = type;
};

export const setCurrentOrder = () => {
  const order = {
    assembly_option: "no",
    delivery_option: store.shippingOption,
    wardrobe: {
      id: store.orderInfo.wardrobeId,
      placement: store.placement,
      width: parseInt(store.size.width, 10),
      height: parseInt(store.size.height, 10),
      depth: parseInt(store.size.depth, 10),
      bead_product_id: store.bead ? parseInt(store.beadProductId, 10) : null,
      bead_product_configuration_id: store.bead
        ? parseInt(store.beadConfig.id, 10)
        : null,
      sidewall_product_id: parseInt(store.sidewallProductId, 10),
      sidewall_product_configuration_id: store.sidewallConfig
        ? parseInt(store.sidewallConfig.id, 10)
        : null,
      top_shelf_product_id: store.topShelf.id || null,
      top_shelf_product_configuration_id: store.topShelfConfigId,
      door_count: store.doors.length,
      doors: [],
      soft_close: store.softClosing,
      damping_strips: store.muffler,
      rail_count: store.tracks,
      bead: store.bead,
      profile_id: store.profile.color,
      door_lighting: store.lighting,
      has_interior_lighting: store.interiorLighting,
      side_wall_count:
        store.placement === "center"
          ? 2
          : store.placement === "wall_to_wall"
          ? 0
          : 1,
      wardrobe_type: store.orderType,
      interior_sections: [],
      body_color: store.bodyColor,
      detail_color: store.detailColor,
    },
    rows: store.order ? store.order.rows : [],
    edit: store.orderInfo.isRetrievedOrder,
  };

  store.doors.forEach((door) => {
    const sections = door.layout.fields.map((field) => ({
      height: 0,
      area_color_id: field.fill.product,
    }));
    order.wardrobe.doors.push({
      sections,
      layout_id: door.layout.type,
    });
  });

  order.wardrobe.interior_sections = store.sections.map((section) => {
    const interiorSection = {
      type: section.type,
      width: section.width,
      position_x: section.pos,
    };

    if (section.products) {
      interiorSection.products = section.products.map((product) => ({
        position_y: product.pos,
        product_id: product.productId,
        product_configuration_id: product.configId,
      }));
    }

    return interiorSection;
  });

  if (store.handleConfig) {
    let handleProduct = order.rows.find(
      (row) => row.product_id === store.handleConfig.product_id
    );
    if (handleProduct) {
      handleProduct.product_configuration_id =
        store.handleConfig.product_configuration_id;
      handleProduct.quantity = store.handleConfig.quantity;
    } else {
      order.rows.push(store.handleConfig);
    }
  }

  if (store.possibleInteriorAccessories.length > 0) {
    store.possibleInteriorAccessories.forEach((accessory) => {
      const productIndex = order.rows.findIndex(
        (row) =>
          row.product_id === accessory.product_id &&
          row.product_configuration_id === accessory.config_id
      );
      if (productIndex > -1 && accessory.amount === 0) {
        order.rows.splice(productIndex, 1);
      }
      if (accessory.amount > 0) {
        if (productIndex > -1) {
          order.rows[productIndex].quantity = accessory.amount;
        } else {
          order.rows.push({
            product_id: accessory.product_id,
            quantity: accessory.amount,
            product_configuration_id: accessory.config_id,
            type: "interior_accessories",
          });
        }
      }
    });
  }

  store.accessories.forEach((accessory) => {
    const productIndex = order.rows.findIndex(
      (row) =>
        row.product_id === accessory.product_id &&
        row.product_configuration_id === accessory.config_id
    );
    if (productIndex > -1 && accessory.amount === 0) {
      order.rows.splice(productIndex, 1);
    }
    if (accessory.amount > 0) {
      if (productIndex > -1) {
        order.rows[productIndex].quantity = accessory.amount;
      } else {
        order.rows.push({
          product_id: accessory.product_id,
          quantity: accessory.amount,
          product_configuration_id: accessory.config_id,
          type: "other_accessories",
        });
      }
    }
  });

  store.order = order;
};

export const setCurrentPrice = async () => {
  const currentPrice = await post("/public/order/dry", store.order);

  store.currentPrice = currentPrice.data;
};

export const getOrder = async (globalId) => {
  const { data: order } = await get(`/public/order/${globalId}`);

  store.showOnboardingModal = false;
  store.showSizeModal = false;
  store.showSectionsModal = false;
  store.showInteriorModal = false;

  store.orderInfo.isRetrievedOrder = true;
  store.orderInfo.globalId = order.global_id;
  store.orderInfo.orderId = order.id;

  if (order.order_status === "drawing_in_progress") {
    store.orderInfo.customer = order.customer;

    return;
  }

  runInAction(() => {
    store.orderInfo.wardrobeId = order.wardrobe.id;
    store.orderInfo.customer = order.customer;
    store.orderInfo.shipping = order.delivery_option;
    store.orderInfo.customer_wants_sms = order.customer_wants_sms;
    store.orderInfo.customer_note = order.customer_note;

    setOrderType(order.wardrobe.wardrobe_type);
    setPlacement(order.wardrobe.placement);
    setBead(order.wardrobe.bead_count > 0);
    if (order.wardrobe.bead_count > 0) {
      setBeadConfig(order.wardrobe.bead_product_configuration);
    }
    setMeasurement("width", order.wardrobe.width);
    setMeasurement("height", order.wardrobe.height);
    setMeasurement("depth", order.wardrobe.depth);
    store.bodyColor = order.wardrobe.body_color;
    store.detailColor = order.wardrobe.detail_color;
    store.sidewallConfig = order.wardrobe.side_wall_product_configuration;

    const doors = [];
    order.wardrobe.doors.forEach((door) => {
      doors.push({
        id: door.id,
        layout: {
          type: null,
          fields: null,
        },
        active: false,
      });
    });

    setTracks(order.wardrobe.rail_count);
    setSoftClosing(order.wardrobe.soft_close_count > 0);
    setMuffler(order.wardrobe.damping_strips > 0);
    setProfileType(order.wardrobe.profile.group_id);
    setProfileColor(order.wardrobe.profile.id);

    doors.forEach((door, index) => {
      const id = order.wardrobe.doors[index].layout_id;
      Object.keys(fieldLayouts).forEach((key) => {
        if (fieldLayouts[key].id === id) {
          setDoorLayout(door, key);
        }
      });
    });

    order.wardrobe.doors.forEach((door, doorIndex) => {
      door.sections.forEach((section, sectionIndex) => {
        doors[doorIndex].layout.fields[sectionIndex].fill = {
          group: section.area_color.group_id,
          product: section.area_color.id,
        };
      });
    });

    doors.forEach((door) =>
      door.layout.fields.forEach((field) => {
        field.fill.group = field.fill.group || 3;
        field.fill.product = field.fill.product || 8;
      })
    );

    setDoors(doors);
    setLighting(
      order.wardrobe.door_lighting,
      order.wardrobe.has_interior_lighting
    );
    setShippingOption(order.delivery_option);

    if (order.rows.length > 0) {
      const handleProduct = order.rows.find(
        (row) => row.product.name === "Handtag"
      );

      if (handleProduct) {
        // Set handle color
        store.handleColor = Object.keys(HandleColorNames).find(
          (key) =>
            HandleColorNames[key] === handleProduct.product_configuration.color
        );
      }
    }

    store.sections = order.wardrobe.interior_sections.map((interiorSection) => {
      const section = {
        id: uniqueId(),
        type: interiorSection.type,
        width: interiorSection.width,
        pos: interiorSection.position_x,
      };

      if (
        interiorSection.products &&
        ![
          SectionTypes.Partition,
          SectionTypes.ClothesRail,
          SectionTypes.DoubleClothesRail,
        ].includes(interiorSection.type)
      ) {
        section.products = interiorSection.products.map((product) => {
          const interiorProduct = store.interiorProducts.find(
            (p) => p.id === product.product_id
          );
          const config = interiorProduct.configurations.find(
            (c) => c.id === product.product_configuration_id
          );

          if (config.accessories) {
            addAvailableProductAccessories(
              interiorProduct.name,
              config.accessories
            );
          }

          return {
            id: uniqueId(),
            name: interiorProduct.name,
            src: config.image,
            pos: product.position_y,
            height: interiorProduct.number_of_holes,
            productId: product.product_id,
            configId: product.product_configuration_id,
            isShelf: interiorProduct.name === ShelfName,
            isClothesRail: interiorProduct.name === ClothesRailName,
            accessories: config.accessories,
          };
        });
      }

      return section;
    });

    if (order.rows.length > 0) {
      // Update accessory quantity from order
      order.rows.forEach((row) => {
        store.order.rows.push({
          product_id: row.product_id,
          quantity: row.quantity,
          product_configuration_id: row.product_configuration_id,
          type: row.type,
        });

        const availableInteriorAccessory =
          store.possibleInteriorAccessories.find(
            (availableInteriorAccessory) =>
              availableInteriorAccessory.product_id === row.product_id &&
              availableInteriorAccessory.config_id ===
                row.product_configuration_id
          );

        if (availableInteriorAccessory) {
          availableInteriorAccessory.amount = row.quantity;
          return;
        }

        const availableAccessory = store.accessories.find(
          (availableInteriorAccessory) =>
            availableInteriorAccessory.product_id === row.product_id &&
            availableInteriorAccessory.config_id ===
              row.product_configuration_id
        );

        if (availableAccessory) {
          availableAccessory.amount = row.quantity;
          return;
        }
      });
    }

    setCurrentOrder();
    setCurrentPrice();
    completeStep();
  });
};

export const hasSection = (index) => {
  return 0 <= index && index < store.sections.length;
};

export const getSection = (index) => {
  return hasSection(index) ? store.sections[index] : null;
};

export const getMaxLeftSectionOffset = (index) => {
  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const section = store.sections[index];
  const leftSection = getSection(index - 2);

  // The max offset to the left is the distance from the section's
  // left partition to the wall if there is no section to the left.
  if (!leftSection) return section.pos - partitionWidth;
  // If the section has another section directly to the left of it,
  // it can't be moved to the left at all.
  if (leftSection.type !== SectionTypes.Partition) return 0;
  // If there is a section to the left we just take its position into
  // consideration, since the section's left partition width cancels out
  // the width of the left section, which is also a partition.
  return section.pos - partitionWidth - leftSection.pos;
};

export const getMaxRightSectionOffset = (index) => {
  const wardrobeWidth = store.innerWidth;
  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const section = store.sections[index];
  const rightSection = getSection(index + 2);

  // The max offset to the right is the distance from the section's
  // right partition to the wall if there is no section to the right.
  if (!rightSection)
    return wardrobeWidth - (section.pos + section.width + partitionWidth);
  // If the section has another section directly to the right of it,
  // it can't be moved to the right at all.
  if (rightSection.type !== SectionTypes.Partition) return 0;

  return rightSection.pos - (section.pos + section.width);
};

export const getMaxLeftPartitionOffset = (index) => {
  const partition = store.sections[index];
  const leftSection = getSection(index - 1);
  const rightSection = getSection(index + 1);

  let offset;

  if (!leftSection) {
    // The max offset to the left is the distance from the partition
    // to the wall if there is no section to the left.
    offset = partition.pos;
  } else if (
    leftSection.type === SectionTypes.ClothesRail ||
    leftSection.type === SectionTypes.DoubleClothesRail
  ) {
    // If the partition is right of a clothes rail section the left movement
    // will be capped by the remaining shrink of the clothes rail section.
    const [railMin] = SectionWidths[leftSection.type];

    offset = partition.pos - (leftSection.pos + railMin);
  } else if (leftSection.type !== SectionTypes.Partition) {
    // If there is no clothes rail to the left and the partition doesn't
    // have empty space to the left of it, it can't be moved to the left at all.
    offset = 0;
  } else {
    offset = partition.pos - leftSection.pos;
  }

  // If the partition is left of a clothes rail section the left movement
  // will be capped by the remaining give of the clothes rail section.
  if (
    rightSection &&
    (rightSection.type === SectionTypes.ClothesRail ||
      rightSection.type === SectionTypes.DoubleClothesRail)
  ) {
    const [, railMax] = SectionWidths[rightSection.type];
    const remainingRailLength = railMax - rightSection.width;

    return Math.min(offset, remainingRailLength);
  } else {
    return offset;
  }
};

export const getMaxRightPartitionOffset = (index) => {
  const wardrobeWidth = store.innerWidth;
  const partition = store.sections[index];
  const rightSection = getSection(index + 1);
  const leftSection = getSection(index - 1);

  let offset;

  if (!rightSection) {
    // The max offset to the right is the distance from the partition
    // to the wall if there is no section to the right.
    offset = wardrobeWidth - (partition.pos + partition.width);
  } else if (
    rightSection.type === SectionTypes.ClothesRail ||
    rightSection.type === SectionTypes.DoubleClothesRail
  ) {
    // If the partition is left of a clothes rail section the right movement
    // will be capped by the remaining shrink of the clothes rail section.
    const [railMin] = SectionWidths[rightSection.type];

    offset =
      rightSection.pos +
      rightSection.width -
      railMin -
      (partition.pos + partition.width);
  } else if (rightSection.type !== SectionTypes.Partition) {
    // If the partition doesn't have empty space to the right of it,
    // it can't be moved to the right at all.
    offset = 0;
  } else {
    offset = rightSection.pos - partition.pos;
  }

  // If the partition is right of a clothes rail section the right movement
  // will be capped by the remaining give of the clothes rail section.
  if (
    leftSection &&
    (leftSection.type === SectionTypes.ClothesRail ||
      leftSection.type === SectionTypes.DoubleClothesRail)
  ) {
    const [, railMax] = SectionWidths[leftSection.type];
    const remainingRailLength = railMax - leftSection.width;

    return Math.min(offset, remainingRailLength);
  } else {
    return offset;
  }
};

export const canRemovePartition = (sectionIndex) => {
  const leftSection = getSection(sectionIndex - 1);
  const rightSection = getSection(sectionIndex + 1);

  // A partition can only be removed if there is empty space
  // or a wall next to it both to the left and the right.
  return (
    (!leftSection || leftSection.type === SectionTypes.Partition) &&
    (!rightSection || rightSection.type === SectionTypes.Partition)
  );
};

export const removePartition = (sectionIndex) => {
  store.sections.splice(sectionIndex, 1);
};

export const canDragPartition = (sectionIndex) => {
  const wardrobeWidth = store.innerWidth;
  const leftSection = getSection(sectionIndex - 1);
  const partition = getSection(sectionIndex);
  const rightSection = getSection(sectionIndex + 1);

  // Wall or empty space to the left, wall or empty space to the right
  if (
    (!leftSection || leftSection.type === SectionTypes.Partition) &&
    (!rightSection || rightSection.type === SectionTypes.Partition)
  ) {
    return true;
  }

  // Wall to the left, clothes rail to the right
  if (
    partition.pos === 0 &&
    rightSection &&
    (rightSection.type === SectionTypes.ClothesRail ||
      rightSection.type === SectionTypes.DoubleClothesRail)
  ) {
    const [rightMin] = SectionWidths[rightSection.type];

    return rightSection.width !== rightMin;
  }

  // Clothes rail to the left, wall to the right
  if (
    partition.pos + partition.width === wardrobeWidth &&
    leftSection &&
    (leftSection.type === SectionTypes.ClothesRail ||
      leftSection.type === SectionTypes.DoubleClothesRail)
  ) {
    const [leftMin] = SectionWidths[leftSection.type];

    return leftSection.width !== leftMin;
  }

  // Empty space to the left, clothes rail to the right
  if (
    (!leftSection || leftSection.type === SectionTypes.Partition) &&
    rightSection &&
    (rightSection.type === SectionTypes.ClothesRail ||
      rightSection.type === SectionTypes.DoubleClothesRail)
  ) {
    return true;
  }
  // Clothes rail to the left, empty space to the right
  if (
    leftSection &&
    (leftSection.type === SectionTypes.ClothesRail ||
      leftSection.type === SectionTypes.DoubleClothesRail) &&
    (!rightSection || rightSection.type === SectionTypes.Partition)
  ) {
    return true;
  }

  // Connected to clothes rails on both sides
  if (
    leftSection &&
    (leftSection.type === SectionTypes.ClothesRail ||
      leftSection.type === SectionTypes.DoubleClothesRail) &&
    rightSection &&
    (rightSection.type === SectionTypes.ClothesRail ||
      rightSection.type === SectionTypes.DoubleClothesRail)
  ) {
    const [leftMin, leftMax] = SectionWidths[leftSection.type];
    const [rightMin, rightMax] = SectionWidths[rightSection.type];

    // There is no give on either side
    if (leftSection.width === leftMin && rightSection.width === rightMin) {
      return false;
    }
    if (leftSection.width === leftMax && rightSection.width === rightMax) {
      return false;
    }

    // At least one side can be made smaller or bigger
    return true;
  }

  return false;
};

export const movePartition = (sectionIndex, offset) => {
  if (offset === 0) return;

  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const { sections } = store;
  const leftSection = getSection(sectionIndex - 1);
  const partition = sections[sectionIndex];
  const rightSection = getSection(sectionIndex + 1);

  let finalOffset = 0;

  if (offset < 0) {
    const maxOffset = getMaxLeftPartitionOffset(sectionIndex);

    finalOffset = Math.max(offset, -maxOffset);

    if (leftSection) {
      if (leftSection.type === SectionTypes.Partition) {
        // Trying to compensate for a partition that was dragged part way through
        // another partition has a lot of edge cases that are hard to account for,
        // so we don't do any moving at all in this very special case.
        if (maxOffset - partitionWidth < -offset && -offset < maxOffset) return;
        // If the partition was dragged further than the position of the next partition we
        // remove the partition it collided with and let the dragged partition replace it.
        if (partition.pos + finalOffset <= leftSection.pos) {
          sections.splice(sectionIndex - 1, 1);
        }
      } else if (
        leftSection.type === SectionTypes.ClothesRail ||
        leftSection.type === SectionTypes.DoubleClothesRail
      ) {
        leftSection.width += finalOffset;
      }
    }

    if (
      rightSection &&
      (rightSection.type === SectionTypes.ClothesRail ||
        rightSection.type === SectionTypes.DoubleClothesRail)
    ) {
      rightSection.pos += finalOffset;
      rightSection.width -= finalOffset;
    }
  } else {
    // offset > 0
    const maxOffset = getMaxRightPartitionOffset(sectionIndex);

    finalOffset = Math.min(offset, maxOffset);

    if (rightSection) {
      if (rightSection.type === SectionTypes.Partition) {
        // Trying to compensate for a partition that was dragged part way through
        // another partition has a lot of edge cases that are hard to account for,
        // so we don't do any moving at all in this very special case.
        if (maxOffset - partitionWidth < offset && offset < maxOffset) return;
        // If the partition was dragged further than the position of the next partition we
        // remove the partition it collided with and let the dragged partition replace it.
        if (partition.pos + finalOffset >= rightSection.pos) {
          sections.splice(sectionIndex + 1, 1);
        }
      } else if (
        rightSection.type === SectionTypes.ClothesRail ||
        rightSection.type === SectionTypes.DoubleClothesRail
      ) {
        rightSection.pos += finalOffset;
        rightSection.width -= finalOffset;
      }
    }

    if (
      leftSection &&
      (leftSection.type === SectionTypes.ClothesRail ||
        leftSection.type === SectionTypes.DoubleClothesRail)
    ) {
      leftSection.width += finalOffset;
    }
  }

  partition.pos += finalOffset;
};

export const addPartition = (pos) => {
  const minPartitionSpace = 200;
  const { sections } = store;
  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const wardrobeWidth = store.innerWidth;

  if (sections.length === 0) {
    const newPartition = {
      id: uniqueId(),
      type: SectionTypes.Partition,
      pos: minPartitionSpace,
      width: partitionWidth,
    };

    sections.push(newPartition);
  } else {
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];

      if (section.type !== SectionTypes.Partition) continue;
      if (section.pos > pos) continue;

      const nextSection = getSection(i + 1);
      const distanceToTheRight =
        (nextSection ? nextSection.pos : wardrobeWidth) -
        (section.pos + section.width);

      if (distanceToTheRight > minPartitionSpace * 2 + partitionWidth) {
        const newPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: section.pos + section.width + minPartitionSpace,
          width: partitionWidth,
        };

        sections.splice(i + 1, 0, newPartition);
      }

      return;
    }

    // If no empty space was found in the loop, the only potential remaining
    // empty space is between the left wall and the first partition.
    const firstPartition = getSection(0);
    const distanceToTheRight = firstPartition.pos;

    if (distanceToTheRight > minPartitionSpace * 2 + partitionWidth) {
      const newPartition = {
        id: uniqueId(),
        type: SectionTypes.Partition,
        pos: 0 + minPartitionSpace,
        width: partitionWidth,
      };

      sections.unshift(newPartition);
    }
  }
};

export const addClothesRail = (type, pos) => {
  const { sections } = store;
  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const wardrobeWidth = store.innerWidth;
  const [railMinWidth, railMaxWidth] = SectionWidths[type];

  // We try to place the rail in conjunction with the wall or partition
  // to the left of the drop position.
  //
  // There are four types of empty spaces the rail can be placed in:
  // 1. Wall to wall
  // 2. Wall to partition
  // 3. Partition to wall
  // 4. Partition to partition

  // 1. Wall to wall
  if (sections.length === 0) {
    const leftPartition = {
      id: uniqueId(),
      type: SectionTypes.Partition,
      pos: 0,
      width: partitionWidth,
    };
    const newRail = {
      id: uniqueId(),
      type,
      pos: leftPartition.width,
      // The clothes rail section can be [railMinWidth, railMaxWidth] in width, but
      // we need to make sure that we have space for a partition to the right of it
      width: Math.min(wardrobeWidth - 2 * partitionWidth, railMaxWidth),
    };
    const rightPartition = {
      id: uniqueId(),
      type: SectionTypes.Partition,
      pos: newRail.pos + newRail.width,
      width: partitionWidth,
    };

    store.sections.push(leftPartition, newRail, rightPartition);
  } else {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const nextSection = getSection(i + 1);

      if (section.type !== SectionTypes.Partition) {
        continue;
      }

      if (i === 0 && pos < section.pos) {
        // 2. Wall to partition
        const leftPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: 0,
          width: partitionWidth,
        };
        const availableSpace = section.pos - leftPartition.width;

        if (availableSpace < railMinWidth) return;

        const newRail = {
          id: uniqueId(),
          type,
          pos: leftPartition.pos + leftPartition.width,
          width: availableSpace,
        };

        if (railMinWidth <= availableSpace && availableSpace <= railMaxWidth) {
          sections.unshift(leftPartition, newRail);

          break;
        }

        if (availableSpace - railMinWidth < partitionWidth) return;

        newRail.width = Math.min(availableSpace, railMaxWidth);

        // If the new clothes rail and it's right partition take up more
        // space than what is available we must decrease the clothes rail
        // with the overflow.
        const overflow = newRail.width + partitionWidth - availableSpace;
        if (overflow > 0) {
          newRail.width -= overflow;
        }

        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newRail.pos + newRail.width,
          width: partitionWidth,
        };

        sections.unshift(leftPartition, newRail, rightPartition);

        break;
      } else if (
        section.pos + section.width < pos &&
        !nextSection &&
        railMinWidth + partitionWidth <=
          wardrobeWidth - (section.pos + section.width)
      ) {
        // 3. Partition to wall
        const availableSpace =
          wardrobeWidth - (section.pos + section.width) - partitionWidth;
        const newRailWidth = Math.min(availableSpace, railMaxWidth);
        const newRail = {
          id: uniqueId(),
          type,
          pos: section.pos + section.width,
          width: newRailWidth,
        };
        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newRail.pos + newRail.width,
          width: partitionWidth,
        };

        store.sections.splice(i + 1, 0, newRail, rightPartition);

        break;
      } else if (
        nextSection &&
        nextSection.type === SectionTypes.Partition &&
        nextSection.pos > pos
      ) {
        // 4. Partition to partition
        const availableSpace = nextSection.pos - (section.pos + section.width);

        let newRailWidth = Math.min(availableSpace, railMaxWidth);
        newRailWidth = Math.max(newRailWidth, railMinWidth);

        const newRail = {
          id: uniqueId(),
          type,
          pos: section.pos + section.width,
          width: newRailWidth,
        };

        if (railMinWidth <= availableSpace && availableSpace <= railMaxWidth) {
          store.sections.splice(i + 1, 0, newRail);

          break;
        }
        if (availableSpace - railMinWidth < partitionWidth) return;

        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newRail.pos + newRail.width,
          width: partitionWidth,
        };

        store.sections.splice(i + 1, 0, newRail, rightPartition);

        break;
      }
    }
  }
};

export const addSection = (type, pos) => {
  const { sections, bodyColor } = store;
  const wardrobeWidth = store.innerWidth;
  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const newSectionWidth = SectionWidths[type];

  // We know the shelf goes by the body color.
  const shelfProduct = store.interiorProducts.find((p) => p.name === ShelfName);
  const shelfConfig = shelfProduct.configurations.find(
    (c) => c.section_type === type && c.color === bodyColor
  );
  const productHeight = shelfProduct.number_of_holes;

  const defaultProducts = [
    {
      id: uniqueId(),
      name: ShelfName,
      src: shelfConfig.image,
      pos: 0,
      height: productHeight,
      productId: shelfProduct.id,
      configId: shelfConfig.id,
      isShelf: true,
    },
    {
      id: uniqueId(),
      name: ShelfName,
      src: shelfConfig.image,
      pos: Math.floor(WardrobeHoles / 2),
      height: productHeight,
      productId: shelfProduct.id,
      configId: shelfConfig.id,
      isShelf: true,
    },
  ];

  // We try to place the section in conjunction with the wall or partition
  // to the left of the drop position.
  //
  // There are four types of empty spaces the section can be placed in:
  // 1. Wall to wall
  // 2. Wall to partition
  // 3. Partition to wall
  // 4. Partition to partition

  // 1. Wall to wall
  if (sections.length === 0) {
    const leftPartition = {
      id: uniqueId(),
      type: SectionTypes.Partition,
      pos: 0,
      width: partitionWidth,
    };
    const newSection = {
      id: uniqueId(),
      type,
      pos: leftPartition.pos + leftPartition.width,
      width: newSectionWidth,
      products: defaultProducts,
    };
    const rightPartition = {
      id: uniqueId(),
      type: SectionTypes.Partition,
      pos: newSection.pos + newSection.width,
      width: partitionWidth,
    };

    store.sections.push(leftPartition, newSection, rightPartition);
  } else {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const nextSection = getSection(i + 1);

      if (section.type !== SectionTypes.Partition) {
        continue;
      }

      if (i === 0 && pos < section.pos) {
        // 2. Wall to partition
        const leftPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: 0,
          width: partitionWidth,
        };
        const newSection = {
          id: uniqueId(),
          type,
          pos: leftPartition.pos + leftPartition.width,
          width: newSectionWidth,
          products: defaultProducts,
        };
        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newSection.pos + newSection.width,
          width: partitionWidth,
        };

        if (newSectionWidth + partitionWidth === section.pos) {
          store.sections.unshift(leftPartition, newSection);
        } else if (newSectionWidth + partitionWidth * 2 <= section.pos) {
          store.sections.unshift(leftPartition, newSection, rightPartition);
        }

        break;
      } else if (
        section.pos + section.width < pos &&
        !nextSection &&
        newSectionWidth < wardrobeWidth - (section.pos + section.width)
      ) {
        // 3. Partition to wall
        const newSection = {
          id: uniqueId(),
          type,
          pos: section.pos + section.width,
          width: newSectionWidth,
          products: defaultProducts,
        };
        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newSection.pos + newSection.width,
          width: partitionWidth,
        };

        store.sections.splice(i + 1, 0, newSection, rightPartition);

        break;
      } else if (
        nextSection &&
        nextSection.type === SectionTypes.Partition &&
        nextSection.pos > pos
      ) {
        // 4. Partition to partition
        const newSection = {
          id: uniqueId(),
          type,
          pos: section.pos + section.width,
          width: newSectionWidth,
          products: defaultProducts,
        };
        const rightPartition = {
          id: uniqueId(),
          type: SectionTypes.Partition,
          pos: newSection.pos + newSection.width,
          width: partitionWidth,
        };

        if (
          nextSection.pos - (section.pos + section.width) ===
          newSectionWidth
        ) {
          store.sections.splice(i + 1, 0, newSection);
        } else if (
          newSectionWidth + partitionWidth <=
          nextSection.pos - (section.pos + section.width)
        ) {
          store.sections.splice(i + 1, 0, newSection, rightPartition);
        }

        break;
      }
    }
  }

  sectionsChange();
};

export const removeSection = (sectionIndex) => {
  const { sections } = store;

  const leftSection = getSection(sectionIndex - 2);
  const rightSection = getSection(sectionIndex + 2);

  const hasAdjacentLeftSection =
    leftSection && leftSection.type !== SectionTypes.Partition;
  const hasAdjacentRightSection =
    rightSection && rightSection.type !== SectionTypes.Partition;

  // Remove all accessory products when removing section
  if (sections[sectionIndex].products) {
    sections[sectionIndex].products.forEach((product) => {
      if (product.accessories) {
        if (
          product.name === DrawerWithHandleName ||
          product.name === JewelryBoxName
        ) {
          store.handleConfig.quantity--;
          if (store.handleConfig.quantity === 0) {
            const index = store.order.rows.findIndex(
              (row) =>
                row.product_configuration_id ===
                  store.handleConfig.product_configuration_id &&
                row.quantity === store.handleConfig.quantity
            );

            if (index >= 0) {
              store.order.rows.splice(index, 1);
            }
            store.handleConfig = null;
          }
        } else {
          removeAvailableProductAccessories(product.accessories);
        }
      }
    });
  }

  if (!hasAdjacentLeftSection && !hasAdjacentRightSection) {
    sections.splice(sectionIndex - 1, 3);
  } else if (hasAdjacentLeftSection && hasAdjacentRightSection) {
    sections.splice(sectionIndex, 1);
  } else if (hasAdjacentLeftSection) {
    sections.splice(sectionIndex, 2);
  } else if (hasAdjacentRightSection) {
    sections.splice(sectionIndex - 1, 2);
  }

  sectionsChange();
};

export const canDragSection = (sectionIndex) => {
  const wardrobeWidth = store.innerWidth;
  const leftPartition = getSection(sectionIndex - 1);
  const rightPartition = getSection(sectionIndex + 1);
  const leftSection = getSection(sectionIndex - 2);
  const rightSection = getSection(sectionIndex + 2);

  const isAgainstLeftWall = leftPartition && leftPartition.pos === 0;
  const isAgainstRightWall =
    rightPartition &&
    rightPartition.pos + rightPartition.width === wardrobeWidth;
  const hasAdjacentLeftSection =
    leftSection && leftSection.type !== SectionTypes.Partition;
  const hasAdjacentRightSection =
    rightSection && rightSection.type !== SectionTypes.Partition;

  if (isAgainstLeftWall) return !hasAdjacentRightSection;
  if (isAgainstRightWall) return !hasAdjacentLeftSection;

  return !hasAdjacentLeftSection || !hasAdjacentRightSection;
};

export const moveSection = (sectionIndex, offset) => {
  if (offset === 0) return;

  const partitionWidth = SectionWidths[SectionTypes.Partition];
  const { sections } = store;
  const leftSection = getSection(sectionIndex - 2);
  const leftPartition = getSection(sectionIndex - 1);
  const section = sections[sectionIndex];
  const rightPartition = getSection(sectionIndex + 1);
  const rightSection = getSection(sectionIndex + 2);

  let finalOffset = 0;

  if (offset < 0) {
    // We don't allow movement to the left if there is no empty space there.
    if (leftSection && leftSection.type !== SectionTypes.Partition) return;
    // We don't allow movement smaller than a partition's width to the left if
    // there is a section to the right as to not have overlapping partitions.
    if (
      rightSection &&
      rightSection.type !== SectionTypes.Partition &&
      -offset < partitionWidth
    ) {
      return;
    }

    const maxOffset = getMaxLeftSectionOffset(sectionIndex);
    finalOffset =
      leftSection && -offset >= maxOffset - partitionWidth
        ? -maxOffset
        : Math.max(offset, -maxOffset);

    // Remove products that are no longer safe after move
    if (
      section.products &&
      !safeToAddProducts(section.pos + finalOffset, section.width)
    ) {
      if (haveUnsafeProducts(section)) {
        let message =
          "Du kan inte flytta sektionen hit eftersom dörrarna inte gör det möjligt att dra ut valda inredningsdetaljer. Vänligen ta bort utdragbara inredningsdetaljer.";
        if (store.doors.length === 3 && store.tracks === 2) {
          message +=
            "\nDu kan även gå tillbaka och välja 3 spårig skena (kräver minst 630 mm djup).";
        }
        setShowAlertPopup(true, message, "Jag förstår");
        return;
      }
    }
    // Since we move the section and surrounding partitions, we have to add a new
    // partition on the same location as before the dragging started if there was
    // a section next to it, as to not have a section with a missing partition.
    if (rightSection && rightSection.type !== SectionTypes.Partition) {
      const partition = toJS(rightPartition);
      partition.id = uniqueId();
      sections.splice(sectionIndex + 2, 0, partition);
    }
    // If the user dragged the section into or further than the section to the left,
    // we remove the partition of the section the dragged section collided with and
    // let the dragged section's left partition replace it.
    if (leftSection && -offset >= maxOffset - partitionWidth) {
      sections.splice(sectionIndex - 2, 1);
    }
  } else {
    // offset > 0
    // We don't allow movement to the right if there is no empty space there.
    if (rightSection && rightSection.type !== SectionTypes.Partition) return;
    // We don't allow movement smaller than a partition's width to the right if
    // there is a section to the left as to not have overlapping partitions.
    if (
      leftSection &&
      leftSection.type !== SectionTypes.Partition &&
      offset < partitionWidth
    ) {
      return;
    }

    const maxOffset = getMaxRightSectionOffset(sectionIndex);
    finalOffset =
      rightSection && offset >= maxOffset - partitionWidth
        ? maxOffset
        : Math.min(offset, maxOffset);

    // Remove products that are no longer safe after move
    if (
      section.products &&
      !safeToAddProducts(section.pos + finalOffset, section.width)
    ) {
      if (haveUnsafeProducts(section)) {
        let message =
          "Du kan inte flytta sektionen hit eftersom dörrarna inte gör det möjligt att dra ut valda inredningsdetaljer. Vänligen ta bort utdragbara inredningsdetaljer.";
        if (store.doors.length === 3 && store.tracks === 2) {
          message +=
            "\nDu kan även gå tillbaka och välja 3 spårig skena (kräver minst 630 mm djup).";
        }
        setShowAlertPopup(true, message, "Jag förstår");
        return;
      }
    }
    // Since we move the section and surrounding partitions, we have to add a new
    // partition on the same location as before the dragging started if there was
    // a section next to it, as to not have a section with a missing partition.
    if (leftSection && leftSection.type !== SectionTypes.Partition) {
      const partition = toJS(leftPartition);
      partition.id = uniqueId();
      sections.splice(sectionIndex - 1, 0, partition);
    }
    // If the user dragged the section into or further than the section to the right,
    // we remove the partition of the section the dragged section collided with and
    // let the dragged section's right partition replace it.
    if (rightSection && offset >= maxOffset - partitionWidth) {
      sections.splice(sectionIndex + 2, 1);
    }
  }

  leftPartition.pos += finalOffset;
  section.pos += finalOffset;
  rightPartition.pos += finalOffset;
};

// Remove Interior from completedSteps when sections changes
const sectionsChange = () => {
  const interiorStepIndex = store.completedSteps.indexOf(Steps.Interior);
  if (interiorStepIndex > -1) {
    store.completedSteps.splice(
      interiorStepIndex,
      store.completedSteps.length - interiorStepIndex
    );
  }
};

export const hasProduct = (index) => {
  return 0 <= index && index < store.activeSection.products.length;
};

export const getProduct = (index) => {
  return hasProduct(index) ? store.activeSection.products[index] : null;
};

export const addProduct = (productId, configId, pos) => {
  const products = store.activeSection.products;
  const interiorProduct = store.interiorProducts.find(
    (p) => p.id === productId
  );
  const config = interiorProduct.configurations.find((c) => c.id === configId);
  const newProductHeight = interiorProduct.number_of_holes;
  const isShelf = interiorProduct.name === ShelfName;
  const isClothesRail = interiorProduct.name === ClothesRailName;

  // There is always two default products
  // so we do not need to do a product.length check
  for (let i = products.length - 1; i >= 0; i--) {
    const product = products[i];

    if (product.pos > pos) continue;

    const nextProduct = getProduct(i + 1);
    const distanceToTheTop =
      (nextProduct ? nextProduct.pos : WardrobeHoles) -
      (product.pos + product.height);

    if (distanceToTheTop >= newProductHeight) {
      const newProduct = {
        id: uniqueId(),
        name: interiorProduct.name,
        src: config.image,
        pos: product.pos + product.height,
        height: newProductHeight,
        productId,
        configId,
        isShelf,
        isClothesRail,
        accessories: config.accessories,
      };

      products.splice(i + 1, 0, newProduct);

      if (config.accessories) {
        addAvailableProductAccessories(
          interiorProduct.name,
          config.accessories
        );
      }
    }

    return;
  }
};

export const addAvailableProductAccessories = (productName, accessories) => {
  if (productName === DrawerWithHandleName || productName === JewelryBoxName) {
    // Set handle config if not set
    if (!store.handleConfig) {
      const interiorAccessoryProduct = store.interiorAccessories.find(
        (interiorAccessory) =>
          interiorAccessory.id === accessories[0].product_id
      );
      const interiorAccessoryConfig =
        interiorAccessoryProduct.configurations.find(
          (config) => config.color === HandleColorNames[store.handleColor]
        );
      // Save handle configs for easier update
      store.handleConfigs = interiorAccessoryProduct.configurations;
      store.handleConfig = {
        product_id: interiorAccessoryProduct.id,
        quantity: 1,
        product_configuration_id: interiorAccessoryConfig.id,
        type: "interior_accessories",
      };
    } else {
      store.handleConfig.quantity++;
    }
  } else {
    accessories.forEach((accessory) => {
      // Check if accessory config already have been added
      const availableAccessory = store.possibleInteriorAccessories.find(
        (availableInteriorAccessory) =>
          availableInteriorAccessory.product_id === accessory.product_id &&
          availableInteriorAccessory.config_id === accessory.config_id
      );

      // If the accessory config already exists
      // just add to max amount
      if (availableAccessory) {
        availableAccessory.max++;
      } else {
        const interiorAccessoryProduct = store.interiorAccessories.find(
          (interiorAccessory) => interiorAccessory.id === accessory.product_id
        );
        const interiorAccessoryConfig =
          interiorAccessoryProduct.configurations.find(
            (config) => config.id === accessory.config_id
          );
        store.possibleInteriorAccessories.push({
          name: `${interiorAccessoryProduct.name} ${
            interiorAccessoryConfig.section_type
              ? interiorAccessoryConfig.section_type
              : ""
          }`.trim(),
          amount: 0,
          price: interiorAccessoryConfig.price,
          max: 1,
          image: accessory.product.image,
          ...accessory,
        });
      }
    });
  }
};

export const removeAvailableProductAccessories = (accessories) => {
  accessories.forEach((accessory) => {
    const accessoryIndex = store.possibleInteriorAccessories.findIndex(
      (availableInteriorAccessory) =>
        availableInteriorAccessory.product_id === accessory.product_id &&
        availableInteriorAccessory.config_id === accessory.config_id
    );

    const currentAccessory = store.possibleInteriorAccessories[accessoryIndex];
    if (currentAccessory.max === 1) {
      store.possibleInteriorAccessories.splice(accessoryIndex, 1);
    } else {
      currentAccessory.max--;
      if (currentAccessory.max < currentAccessory.amount) {
        currentAccessory.amount--;
      }
    }
  });

  // Remove InteriorAccessories from completed step
  const summaryStepIndex = store.completedSteps.indexOf(
    Steps.InteriorAccessories
  );
  if (summaryStepIndex >= 0) {
    store.completedSteps.splice(
      summaryStepIndex,
      store.completedSteps.length - summaryStepIndex
    );
  }
};

export const safeToAddProducts = (sectionPos, sectionWidth) => {
  let beadCount = 0;
  if (store.bead) {
    beadCount = 1;
    if (store.placement === "wall_to_wall") {
      beadCount = 2;
    }
  }

  // Calculate width of doors
  const openingWidth = store.innerWidth - beadCount * SideWallWidth;
  const profileOverlap = store.profiles.find(
    (profile) => profile.id === store.profile.type
  ).overlap;
  const doorWidth =
    (openingWidth + profileOverlap * (store.doors.length - 1)) /
    store.doors.length;

  // Check if sections collides where doors overlaps
  for (let index = 0; index < store.doors.length - 1; index++) {
    const doorPosition = doorWidth * (index + 1);
    const sectionEndPosition = sectionPos + sectionWidth;

    if (
      (doorPosition - profileOverlap > sectionPos &&
        doorPosition - profileOverlap < sectionEndPosition) ||
      (doorPosition > sectionPos && doorPosition < sectionEndPosition)
    ) {
      return false;
    }
  }
  return true;
};

export const haveUnsafeProducts = (section) => {
  const unsafeProducts = [];
  section.products.forEach((product, i) => {
    if (
      !product.isShelf &&
      !product.isClothesRail &&
      store.tracks !== 3 &&
      canRemoveProduct(i, section)
    ) {
      unsafeProducts.push(i);
    }
  });

  if (unsafeProducts.length > 0) {
    return true;
  }
  return false;
};

export const removeUnsafeProducts = (section) => {
  const unsafeProducts = [];
  section.products.forEach((product, i) => {
    if (
      !product.isShelf &&
      !product.isClothesRail &&
      canRemoveProduct(i, section)
    ) {
      unsafeProducts.push(i);
    }
  });

  if (unsafeProducts.length > 0) {
    for (let i = unsafeProducts.length - 1; i >= 0; i--) {
      removeProduct(unsafeProducts[i], section);
    }
    setCurrentOrder();
    setCurrentPrice();
  }
};

export const canRemoveProduct = (productIndex, section) => {
  const { products } = section;
  const product = products[productIndex];
  const { isShelf, pos } = product;

  if (!isShelf) return true;
  if (pos === 0) return false;

  const middle = Math.floor(WardrobeHoles / 2);
  const min = middle - AllowedMiddleShelfOffset;
  const max = middle + AllowedMiddleShelfOffset;

  if (pos < min || max < pos) return true;

  const hasNearbyShelf = products.some((p, i) => {
    if (productIndex === i) return false;

    return p.isShelf && min <= p.pos && p.pos <= max;
  });

  return hasNearbyShelf;
};

export const calculateProductMovement = (productIndex, offset) => {
  const { products } = store.activeSection;
  const movedProduct = products[productIndex];

  if (offset === 0) {
    return { newIndex: productIndex, newPosition: movedProduct.pos };
  }

  let minPos = 0;
  let maxPos = WardrobeHoles - movedProduct.height;
  let newPos = movedProduct.pos + offset;
  let newIndex = productIndex;

  // If the moved product is a middle shelf with no surrounding shelves,
  // we restrict its movement.
  if (movedProduct.isShelf) {
    const middle = Math.floor(WardrobeHoles / 2);
    const min = middle - AllowedMiddleShelfOffset;
    const max = middle + AllowedMiddleShelfOffset;

    if (min <= movedProduct.pos && movedProduct.pos <= max) {
      const hasNearbyShelf = products.some((p, i) => {
        if (productIndex === i) return false;

        return p.isShelf && min <= p.pos && p.pos <= max;
      });

      if (!hasNearbyShelf) {
        minPos = min;
        maxPos = max;
      }
    }
  }

  newPos = Math.min(newPos, maxPos);
  newPos = Math.max(newPos, minPos);

  // We treat every series of "products" and "spaces with higher height
  // than the moved product" as blocks that we use for collision detection.
  // For downward movement we only create blocks downwards, and for upward
  // movement we only create blocks upwards.
  if (offset < 0) {
    let i = productIndex - 1;

    while (i >= 0) {
      const topBlockProduct = products[i];
      const block = {
        start: topBlockProduct.pos,
        end: topBlockProduct.pos + topBlockProduct.height,
        numProducts: 1,
      };

      let prevProduct = topBlockProduct;
      let currentProduct;

      --i;

      while (i >= 0) {
        currentProduct = products[i];

        if (
          prevProduct.pos - (currentProduct.pos + currentProduct.height) <
          movedProduct.height
        ) {
          block.start = currentProduct.pos;
          ++block.numProducts;
        } else {
          break;
        }

        prevProduct = currentProduct;
        --i;
      }

      // Before the block
      if (newPos >= block.end) break;
      // Beyond the block
      if (newPos + movedProduct.height <= block.start) {
        if (block.start >= minPos + movedProduct.height) {
          newIndex -= block.numProducts;
          continue;
        } else {
          newPos = block.end;
          break;
        }
      }
      // Partially or completely inside the block
      if (block.end > minPos && minPos + movedProduct.height > block.start) {
        newPos = block.end;
        break;
      }
      if (newPos < block.start + (block.end - block.start) / 2) {
        newIndex -= block.numProducts;
        newPos = block.start - movedProduct.height;
      } else {
        newPos = block.end;
      }

      break;
    }
  } else {
    let i = productIndex + 1;

    while (i < products.length) {
      const bottomBlockProduct = products[i];
      const block = {
        start: bottomBlockProduct.pos,
        end: bottomBlockProduct.pos + bottomBlockProduct.height,
        numProducts: 1,
      };

      let prevProduct = bottomBlockProduct;
      let currentProduct;

      ++i;

      while (i < products.length) {
        currentProduct = products[i];

        if (
          currentProduct.pos - (prevProduct.pos + prevProduct.height) <
          movedProduct.height
        ) {
          block.end = currentProduct.pos + currentProduct.height;
          ++block.numProducts;
        } else {
          break;
        }

        prevProduct = currentProduct;
        ++i;
      }

      // There is always a shelf at the absolute bottom, but there is no guarantee
      // that there is a product at the top, so we do a final check against the potential
      // empty space at the top and see if that should be incluced in the block.
      const isLastBlock = i === products.length;
      if (isLastBlock && WardrobeHoles - block.end < movedProduct.height) {
        block.end = WardrobeHoles;
      }

      // Before the block
      if (block.start > newPos + movedProduct.height) break;
      // Beyond the block
      if (newPos >= block.end) {
        if (block.end <= maxPos) {
          newIndex += block.numProducts;
          continue;
        } else {
          newPos = block.start - movedProduct.height;
          break;
        }
      }
      // Partially or completely inside the block
      if (block.start <= maxPos && maxPos + movedProduct.height <= block.end) {
        newPos = block.start - movedProduct.height;
        break;
      }
      if (
        newPos + movedProduct.height / 2 >=
        block.start + (block.end - block.start) / 2
      ) {
        newIndex += block.numProducts;
        newPos = block.end;
      } else {
        newPos = block.start - movedProduct.height;
      }

      break;
    }
  }

  return { newIndex: newIndex, newPosition: newPos };
};

export const moveProduct = (productIndex, offset) => {
  if (offset === 0) return;

  const { products } = store.activeSection;
  const movedProduct = products[productIndex];
  const { newIndex, newPosition } = calculateProductMovement(
    productIndex,
    offset
  );

  if (newIndex !== productIndex) {
    products.splice(productIndex, 1);
    products.splice(newIndex, 0, movedProduct);
  }

  movedProduct.pos = newPosition;
};

export const removeProduct = (productIndex, section = store.activeSection) => {
  if (section.products[productIndex].accessories) {
    if (
      section.products[productIndex].name === DrawerWithHandleName ||
      section.products[productIndex].name === JewelryBoxName
    ) {
      store.handleConfig.quantity--;
      if (store.handleConfig.quantity === 0) {
        const index = store.order.rows.findIndex(
          (row) =>
            row.product_configuration_id ===
              store.handleConfig.product_configuration_id &&
            row.quantity === store.handleConfig.quantity
        );

        if (index >= 0) {
          store.order.rows.splice(index, 1);
        }
        store.handleConfig = null;
      }
    } else {
      removeAvailableProductAccessories(
        section.products[productIndex].accessories
      );
    }
  }
  section.products.splice(productIndex, 1);
};

export const canDragProduct = (productIndex) => {
  const product = getProduct(productIndex);
  const isBottomShelf = product.isShelf && product.pos === 0;

  return !isBottomShelf;
};

export const setBodyColor = (color) => {
  const { interiorProducts } = store;

  // Update the configuration of all the products relying on body color
  // to a configuration of the new body color.
  store.sections.forEach((section) => {
    if (!section.products) return;

    section.products.forEach((product) => {
      const interiorProduct = interiorProducts.find(
        (p) => p.id === product.productId
      );

      if (interiorProduct.color_group !== "body") return;

      const newConfig = interiorProduct.configurations.find(
        (c) => c.section_type === section.type && c.color === color
      );
      // If for some reason there is no configuration for the new color,
      // we don't update it.
      if (newConfig) {
        product.configId = newConfig.id;
        product.src = newConfig.image;
      }
    });
  });

  store.bodyColor = color;
};

export const setDetailColor = (color) => {
  const { interiorProducts } = store;
  // Update the configuration of all the products relying on detail color
  // to a configuration of the new detail color.
  store.sections.forEach((section) => {
    if (!section.products) return;

    section.products.forEach((product) => {
      const interiorProduct = interiorProducts.find(
        (p) => p.id === product.productId
      );

      if (interiorProduct.color_group !== "detail") return;

      const newConfig = interiorProduct.configurations.find(
        (c) => c.section_type === section.type && c.color === color
      );
      // If for some reason there is no configuration for the new color,
      // we don't update it.
      if (newConfig) {
        product.configId = newConfig.id;
        product.src = newConfig.image;
      }
    });
  });

  store.detailColor = color;
};

export const setHandleColor = (color) => {
  if (store.handleConfigs && store.handleConfig) {
    const handleConfig = store.handleConfigs.find(
      (config) => config.color === HandleColorNames[color]
    );
    store.handleConfig.product_configuration_id = handleConfig.id;
  }
  store.handleColor = color;
};

export const hideOnboardingModal = () => {
  store.showOnboardingModal = false;
};

export const hideSizeModal = () => {
  store.showSizeModal = false;
};

export const hideSectionsModal = () => {
  store.showSectionsModal = false;
};

export const hideInteriorModal = () => {
  store.showInteriorModal = false;
};

export const hideInteriorSectionInfoModal = () => {
  store.showInteriorSectionInfoModal = false;
};

export const setShowConfirmPopup = (showConfirm, text, trigger) => {
  store.confirmText = text;
  store.confirmTrigger = trigger;
  store.showConfirmPopup = showConfirm;
};

export const setShowAlertPopup = (showAlert, text, buttonText) => {
  store.alertText = text;
  store.alertButtonText = buttonText;
  store.showAlertPopup = showAlert;
};

export const setModalProduct = (product) => {
  store.modalProduct = product;
};

export const toggleModalTerms = () => {
  store.termsModal = !store.termsModal;
};

export const resetWardobe = () => {
  // We need to reset different fields on the wardrobe
  // depending on what triggered the confirm
  if (
    store.confirmTrigger === "width" ||
    store.confirmTrigger === "height" ||
    store.confirmTrigger === "depth"
  ) {
    store.size[store.confirmTrigger] = null;
    store.profile = { type: 1, color: 1 };
    store.completedSteps.splice(1);
    store.doors = [];
    store.tracks = 2;
    store.shouldHaveTopShelf = false;
    store.sections.forEach((section, index) => {
      removeSection(index);
    });
    store.sections = [];
    store.possibleInteriorAccessories = [];
  } else if (store.confirmTrigger === "placement") {
    store.bead = false;
    store.beadConfig = store.defaultBeadConfig;
    store.shouldHaveTopShelf = false;
    store.sections.forEach((section, index) => {
      removeSection(index);
    });
    store.sections = [];
    store.possibleInteriorAccessories = [];
  } else if (store.confirmTrigger === "tracks") {
    store.sectionsWithUnsafeProducts.forEach((section) => {
      removeUnsafeProducts(section);
    });
    store.sectionsWithUnsafeProducts = [];
  }
  setCurrentOrder();
  setCurrentPrice();
  store.showConfirmPopup = false;
};

export const setCampaign = (campaign) => {
  store.campaign = campaign;
};

export default store;

import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import InfoBox from "./InfoBox";

const ColorRadioGroup = observer(({ title, children, info }) => {
  const colorRadioGroupRef = useRef();
  const infoBoxButtonRef = useRef();
  const infoBox = useLocalObservable(() => ({
    isVisible: false,
    toggle() {
      infoBox.isVisible = !infoBox.isVisible;
    },
    hide(e) {
      if (
        e.target !== infoBoxButtonRef.current &&
        !document.getElementById("infobox-root").contains(e.target)
      ) {
        infoBox.isVisible = false;
      }
    },
  }));

  useEffect(() => {
    if (info && info.title && info.description) {
      document.addEventListener("mousedown", infoBox.hide);
      return () => {
        document.removeEventListener("mousedown", infoBox.hide);
      };
    }
  }, []);

  return (
    <div className="flex flex-col items-center" ref={colorRadioGroupRef}>
      {title && <p className="font-medium text-center text-sm mb-1">{title}</p>}
      <ul className="flex">{children}</ul>
      {info && info.title && info.description && (
        <span
          className="w-4 h-4 inline-flex justify-center items-center text-xs text-dark leading-none rounded-full border border-dark cursor-pointer mt-1"
          onClick={() => infoBox.toggle()}
          ref={infoBoxButtonRef}
        >
          ?
        </span>
      )}
      {info && info.title && info.description && infoBox.isVisible && (
        <InfoBox ref={colorRadioGroupRef}>
          {info.image && (
            <img src={info.image} className="w-full rounded-t-sm" />
          )}
          <div className="px-6 py-3 max-h-64 lg:max-h-full overflow-auto">
            <h3 className="text-dark font-medium">{info.title}</h3>
            <p className="text-med text-sm">{info.description}</p>
          </div>
          <span className="w-2.5 h-2.5 absolute bottom-0 left-1/2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-yellow rounded-sm"></span>
        </InfoBox>
      )}
    </div>
  );
});

ColorRadioGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  info: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    image: PropTypes.string,
  }),
};

export default ColorRadioGroup;

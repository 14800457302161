import React, { useEffect } from "react";
import { observer } from "mobx-react";
import store, {
  resetActiveField,
  completeStep,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import ToggleSwitch from "./ToggleSwitch";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";

const Lighting = observer(() => {
  useEffect(() => {
    resetActiveField();
    completeStep();
  }, []);

  useEffectSkipFirst(() => {
    setCurrentOrder();
    setCurrentPrice();
  }, [store.lighting]);

  return (
    <ToggleSwitch
      label="Lägg till dörrbelysning"
      name="lighting"
      info={{
        title: "Dörrbelysning",
        description: (
          <>
            När ni beställer utvändig belysning framför skjutdörrarna sätter vi
            en extra takskena framför skenan för dörrarna (+40mm på djupet). I
            denna skena sätts sedan en LED-list på hela garderobens bredd.
            Belysningen tänds och släcks med medföljare trådlösa fjärrkontroll.
            LED monteras i aluminiumskenan på plats och kan klippas varje 5 cm
            för att täcka hela garderobens bredd.
          </>
        ),
      }}
    />
  );
});

export default Lighting;

import React from "react";
import { observer } from "mobx-react-lite";
import {
  SectionTypes,
  SectionWidths,
  InteriorWardrobeMaxHeight,
} from "../constants";

const partitionWidth = SectionWidths[SectionTypes.Partition];

const WardrobeTopshelf = observer(({ depth, wardrobeHeight, color }) => {
  const emptySpaceAbove = wardrobeHeight - InteriorWardrobeMaxHeight;

  return (
    <>
      {emptySpaceAbove !== 0 && (
        <div
          style={{ height: `${(emptySpaceAbove / wardrobeHeight) * 100}%` }}
        />
      )}
      <div
        style={{
          height: `${(partitionWidth / wardrobeHeight) * 100}%`,
          background: color,
        }}
      />
      <div className="relative preserve-3d">
        <div
          className="absolute w-full h-64"
          style={{
            height: `${50 + depth * 2}px`,
            // translateZ negative half the height of the element
            transform: `rotateX(-90deg) translateZ(-${
              25 + depth
            }px) translateY(${25 + depth}px)`,
            borderRight: "1px dashed #1D1211",
            borderBottom: "1px dashed #1D1211",
            borderLeft: "1px dashed #1D1211",
          }}
        />
      </div>
    </>
  );
});

export default WardrobeTopshelf;

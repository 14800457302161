import React from "react";

export const Chevron = ({ rotate }) => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rotate ? `transform rotate-${rotate}` : ""}
  >
    <circle cx="17.5" cy="18" r="17.5" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.544 7.7253C21.2436 7.4249 20.7565 7.4249 20.4561 7.7253L11.2254 16.9561C10.925 17.2565 10.925 17.7435 11.2254 18.0439L20.4561 27.2747C20.7565 27.5751 21.2436 27.5751 21.544 27.2747C21.8444 26.9743 21.8444 26.4872 21.544 26.1868L12.8571 17.5L21.544 8.81316C21.8444 8.51276 21.8444 8.02571 21.544 7.7253Z"
      fill={rotate ? "#1D1211" : "#C8C5BC"}
    />
  </svg>
);

export const Edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="w-4 h-4"
  >
    <title>Redigera</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M364.13 125.25L87 403l-23 45 44.99-23 277.76-277.13-22.62-22.62zM420.69 68.69l-22.62 22.62 22.62 22.63 22.62-22.63a16 16 0 000-22.62h0a16 16 0 00-22.62 0z"
    />
  </svg>
);

export const Expand = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47123 16.5289C9.15393 16.2116 8.63948 16.2116 8.32218 16.5289L1.66587 23.1852L1.66587 18.6876C1.66587 18.2388 1.3021 17.875 0.853365 17.8751C0.404633 17.875 0.040863 18.2388 0.0408636 18.6876L0.040863 25.1467C0.040863 25.5955 0.404633 25.9592 0.853364 25.9592L7.31247 25.9592C7.7612 25.9592 8.12497 25.5955 8.12497 25.1467C8.12497 24.698 7.7612 24.3342 7.31247 24.3342L2.81491 24.3342L9.47123 17.6779C9.78853 17.3606 9.78853 16.8462 9.47123 16.5289Z"
      fill="#1D1211"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5289 16.5289C16.8462 16.2116 17.3606 16.2116 17.6779 16.5289L24.3342 23.1852L24.3342 18.6876C24.3342 18.2388 24.698 17.875 25.1467 17.8751C25.5955 17.875 25.9592 18.2388 25.9592 18.6876L25.9592 25.1467C25.9592 25.5955 25.5955 25.9592 25.1467 25.9592L18.6876 25.9592C18.2389 25.9592 17.8751 25.5955 17.8751 25.1467C17.8751 24.698 18.2389 24.3342 18.6876 24.3342L23.1852 24.3342L16.5289 17.6779C16.2116 17.3606 16.2116 16.8462 16.5289 16.5289Z"
      fill="#1D1211"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5288 9.47119C16.8461 9.78849 17.3605 9.78849 17.6778 9.47119L24.3341 2.81488L24.3341 7.31251C24.3341 7.76124 24.6979 8.12501 25.1466 8.12501C25.5954 8.12501 25.9591 7.76124 25.9591 7.31251L25.9591 0.853327C25.9591 0.404595 25.5954 0.0408258 25.1466 0.0408258L18.6875 0.0408306C18.2388 0.0408297 17.875 0.4046 17.875 0.853331C17.875 1.30206 18.2388 1.66583 18.6875 1.66583L23.1851 1.66583L16.5288 8.32214C16.2115 8.63944 16.2115 9.15389 16.5288 9.47119Z"
      fill="#1D1211"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47123 9.47119C9.15393 9.78849 8.63948 9.78849 8.32218 9.47119L1.66587 2.81488L1.66587 7.31251C1.66587 7.76124 1.3021 8.12501 0.853365 8.12501C0.404633 8.12501 0.040863 7.76124 0.0408636 7.31251L0.040863 0.853327C0.040863 0.404595 0.404633 0.0408258 0.853364 0.0408258L7.31247 0.0408306C7.7612 0.0408297 8.12497 0.4046 8.12497 0.853331C8.12497 1.30206 7.7612 1.66583 7.31247 1.66583L2.81491 1.66583L9.47123 8.32214C9.78853 8.63944 9.78853 9.15389 9.47123 9.47119Z"
      fill="#1D1211"
    />
  </svg>
);

export const PointerMoveHorizontally = () => (
  <svg
    className="w-full"
    width={30}
    height={36}
    viewBox="0 0 30 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.922 1L1 3.747l2.922 2.747L1 3.747 3.922 1zM20.01 6.494l2.924-2.747L20.01 1l2.925 2.747-2.925 2.747zM9.627 18.858c0-1.215-1.048-2.198-2.34-2.198-1.291 0-2.339.982-2.339 2.198v9.067c0 3.642 3.143 6.593 7.018 6.593h9.36c3.875 0 7.018-2.955 7.018-6.593v-10.99c0-1.215-1.048-2.198-2.34-2.198-1.291 0-2.339.983-2.339 2.198v2.2V14.735c0-1.214-1.047-2.196-2.34-2.196-1.29 0-2.34.982-2.34 2.196v2.2-4.398c0-1.214-1.047-2.198-2.339-2.198-1.293 0-2.339.984-2.339 2.198l-.002 4.398V3.747h.003c0-1.214-1.048-2.198-2.342-2.198-1.292 0-2.34.984-2.34 2.198v19.782-4.671 0zM1 3.747h5.847H1zM17.084 3.747h5.85-5.85z"
      stroke="#231A11"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Arrow = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C5.34518 10 5.625 9.72018 5.625 9.375V2.13388L8.30806 4.81694C8.55214 5.06102 8.94786 5.06102 9.19194 4.81694C9.43602 4.57286 9.43602 4.17714 9.19194 3.93306L5.44194 0.183058C5.19787 -0.0610199 4.80214 -0.0610199 4.55806 0.183058L0.808058 3.93306C0.563981 4.17714 0.563981 4.57286 0.808058 4.81694C1.05214 5.06102 1.44787 5.06102 1.69194 4.81694L4.375 2.13388V9.375C4.375 9.72018 4.65482 10 5 10Z"
      fill="currentColor"
    />
  </svg>
);

export const Cross = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#FFDD90" />
    <rect
      x="1"
      y="1"
      width="30"
      height="30"
      rx="15"
      stroke="#FFDD90"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L16 14.5858L21.2929 9.29289C21.6834 8.90237 22.3166 8.90237 22.7071 9.29289C23.0976 9.68342 23.0976 10.3166 22.7071 10.7071L17.4142 16L22.7071 21.2929C23.0976 21.6834 23.0976 22.3166 22.7071 22.7071C22.3166 23.0976 21.6834 23.0976 21.2929 22.7071L16 17.4142L10.7071 22.7071C10.3166 23.0976 9.68342 23.0976 9.29289 22.7071C8.90237 22.3166 8.90237 21.6834 9.29289 21.2929L14.5858 16L9.29289 10.7071C8.90237 10.3166 8.90237 9.68342 9.29289 9.29289Z"
      fill="#231A11"
    />
  </svg>
);

export const CrossCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
      fill="#231A11"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#231A11"
    />
  </svg>
);

export const FillCrossCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="30" height="30" rx="15" fill="#1D1211" />
    <rect
      x="1"
      y="1"
      width="30"
      height="30"
      rx="15"
      stroke="#1D1211"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L16 14.5858L21.2929 9.29289C21.6834 8.90237 22.3166 8.90237 22.7071 9.29289C23.0976 9.68342 23.0976 10.3166 22.7071 10.7071L17.4142 16L22.7071 21.2929C23.0976 21.6834 23.0976 22.3166 22.7071 22.7071C22.3166 23.0976 21.6834 23.0976 21.2929 22.7071L16 17.4142L10.7071 22.7071C10.3166 23.0976 9.68342 23.0976 9.29289 22.7071C8.90237 22.3166 8.90237 21.6834 9.29289 21.2929L14.5858 16L9.29289 10.7071C8.90237 10.3166 8.90237 9.68342 9.29289 9.29289Z"
      fill="#C8C5BC"
    />
  </svg>
);

export const ZoomIn = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.2574C8.27614 3.2574 8.5 3.48126 8.5 3.7574L8.5 7.50004L12.2426 7.50004C12.5188 7.50004 12.7426 7.7239 12.7426 8.00004C12.7426 8.27618 12.5188 8.50004 12.2426 8.50004L8.5 8.50004V12.2427C8.5 12.5188 8.27614 12.7427 8 12.7427C7.72386 12.7427 7.5 12.5188 7.5 12.2427V8.50004L3.75736 8.50004C3.48122 8.50004 3.25736 8.27618 3.25736 8.00004C3.25736 7.7239 3.48122 7.50004 3.75736 7.50004L7.5 7.50004L7.5 3.7574C7.5 3.48126 7.72386 3.2574 8 3.2574Z"
      fill="#231A11"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#231A11"
    />
    <path
      d="M21.5588 17.4418C21.4017 17.6689 21.0903 17.7257 20.8632 17.5686L14.0001 12.8224L14.5689 11.9999L21.432 16.7462C21.6591 16.9032 21.7159 17.2147 21.5588 17.4418Z"
      fill="#231A11"
    />
  </svg>
);

export const ZoomOut = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#231A11"
    />
    <path
      d="M21.5588 17.4418C21.4017 17.6689 21.0903 17.7257 20.8632 17.5686L14.0001 12.8224L14.5689 11.9999L21.432 16.7462C21.6591 16.9032 21.7159 17.2147 21.5588 17.4418Z"
      fill="#231A11"
    />
    <rect
      x="13"
      y="8"
      width="10"
      height="1"
      rx="0.5"
      transform="rotate(180 13 8)"
      fill="#231A11"
    />
  </svg>
);

export const Select = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 36"
    className="w-9 max-w-full"
  >
    <path
      d="M26.564 4.552v5.76h3.456v-5.76A4.033 4.033 0 0 0 25.988.52h-5.76v3.456h5.76a.577.577 0 0 1 .576.576ZM4.676 4.552c0-.318.258-.576.576-.576h5.76V.52h-5.76A4.032 4.032 0 0 0 1.22 4.552v5.76h3.456v-5.76ZM5.252 29.32h5.76v-3.456h-5.76a.575.575 0 0 1-.576-.576v-5.76H1.22v5.76a4.032 4.032 0 0 0 4.032 4.032ZM19.076 32.719a3.456 3.456 0 0 0 3.11 2.361h.167a3.459 3.459 0 0 0 3.157-2.05l1.78-4.032 6.186 6.22 2.442-2.442-6.197-6.198 4.031-1.78a3.455 3.455 0 0 0-.334-6.422l-14.976-4.994a3.456 3.456 0 0 0-4.371 4.372l5.005 14.965Zm13.248-11.077-6.912 3.07-3.07 6.912-4.994-14.976 14.976 4.994Z"
      fill="currentColor"
    />
  </svg>
);

export const Deselect = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    className="w-9 max-w-full"
  >
    <path
      d="M29.064 4.552v5.76h3.456v-5.76A4.033 4.033 0 0 0 28.488.52h-5.76v3.456h5.76a.577.577 0 0 1 .576.576ZM28.488 27.336h-5.76v3.456h5.76a4.033 4.033 0 0 0 4.032-4.032V21h-3.456v5.76a.578.578 0 0 1-.576.576ZM4.176 4.552c0-.318.258-.576.576-.576h5.76V.52h-5.76A4.032 4.032 0 0 0 .72 4.552v5.76h3.456v-5.76ZM4.752 30.792h5.76v-3.456h-5.76a.576.576 0 0 1-.576-.576V21H.72v5.76a4.032 4.032 0 0 0 4.032 4.032Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      d="m12.121 11 9.193 9.192M21.314 11.121l-9.193 9.193"
    />
  </svg>
);

export const ClothesRailJacket = (props) => (
  <svg
    viewBox="0 0 35 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="m28.882 15.322-2.743 4.115s-2.744-4.116-4.573-3.546c-1.829.57-7.2 10.404-7.2 10.404l4.344-10.974s1.829-3.545 5.487-2.63c3.658.914 4.685 2.63 4.685 2.63ZM8.992 92.48l-.802-1.607a2.975 2.975 0 0 1-.305-1.448c.714-18.143 6.49-63.301 6.49-63.301M12.421 99.795l-3.518-1.137a.555.555 0 0 1-.286-.847c1.024-1.433.375-5.326.375-5.326l8.569 1.024-.756 6.371a.378.378 0 0 1-.418.333l-3.963-.418s9.49 11.547 14.977 6.06c5.487-5.487-1.26-86.42-1.26-86.42"
        stroke="currentColor"
        strokeWidth={1.35}
        strokeMiterlimit={10}
      />
      <path
        d="M17.56 93.508s4.698-17.497 1.55-43.554"
        stroke="currentColor"
        strokeWidth={1.35}
        strokeMiterlimit={10}
      />
      <path
        d="M23.657 12.76a.715.715 0 0 0 .222-.537v-4.02a4.217 4.217 0 0 0 1.798-.763c.53-.39.959-.9 1.25-1.486a4.085 4.085 0 0 0-.056-3.755A4.15 4.15 0 0 0 25.576.751a4.242 4.242 0 0 0-3.77-.526 4.195 4.195 0 0 0-1.65 1.037 4.175 4.175 0 0 0-1.143 2.868 1.417 1.417 0 0 0 0 .376.748.748 0 0 0 .26.539.77.77 0 0 0 .87.097.764.764 0 0 0 .375-.469.747.747 0 0 0 .019-.304v-.244c0-.523.158-1.035.453-1.47a2.677 2.677 0 0 1 1.205-.974 2.718 2.718 0 0 1 2.924.573 2.645 2.645 0 0 1 .774 1.882 2.617 2.617 0 0 1-.804 1.87 2.68 2.68 0 0 1-1.905.755H23.1a.767.767 0 0 0-.54.22.747.747 0 0 0-.222.532v4.735c0 .2.08.39.223.532a.767.767 0 0 0 1.096-.02Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="currentColor"
          transform="matrix(-1 0 0 1 35 0)"
          d="M0 0h35v108H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ScrollIcon = ({ rotate }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={rotate ? `transform rotate-${rotate}` : ""}
  >
    <path
      d="M15.5293 25.6727C15.5293 26.4058 16.0825 27 16.7646 27C17.4468 27 18 26.4058 18 25.6727C18 24.9392 17.4468 24.345 16.7646 24.345C16.0824 24.345 15.5293 24.9392 15.5293 25.6727ZM12.353 22.4484C12.353 23.1816 12.9058 23.7761 13.5883 23.7761C14.2705 23.7761 14.8234 23.1816 14.8234 22.4484C14.8234 21.7153 14.2705 21.1206 13.5883 21.1206C12.9058 21.1206 12.353 21.7153 12.353 22.4484ZM9.17632 19.2244C9.17632 19.9572 9.72918 20.5517 10.4117 20.5517C11.0942 20.5517 11.647 19.9572 11.647 19.2244C11.647 18.4912 11.0942 17.8967 10.4117 17.8967C9.72918 17.8967 9.17632 18.4913 9.17632 19.2244ZM6 16C6 16.7331 6.55285 17.3277 7.23536 17.3277C7.91755 17.3277 8.47041 16.7331 8.47041 16C8.47041 15.2668 7.91755 14.6721 7.23536 14.6721C6.55285 14.6721 6 15.2669 6 16ZM9.17632 12.7757C9.17632 13.5089 9.72918 14.1034 10.4117 14.1034C11.0942 14.1034 11.647 13.5089 11.647 12.7757C11.647 12.0426 11.0942 11.4481 10.4117 11.4481C9.72918 11.4481 9.17632 12.0426 9.17632 12.7757ZM12.353 9.55171C12.353 10.2849 12.9058 10.8794 13.5883 10.8794C14.2705 10.8794 14.8234 10.2848 14.8234 9.55171C14.8234 8.81859 14.2705 8.22384 13.5883 8.22384C12.9058 8.22384 12.353 8.81859 12.353 9.55171ZM15.5293 6.32768C15.5293 7.06047 16.0825 7.65536 16.7646 7.65536C17.4468 7.65536 18 7.06061 18 6.32768C18 5.59451 17.4468 5 16.7646 5C16.0824 5 15.5293 5.59456 15.5293 6.32768Z"
      fill="#9E7349"
    />
    <circle cx="17.25" cy="16.25" r="1.25" fill="#9E7349" />
  </svg>
);

export const PromoIcon = ({ className }) => (
  <svg
    width="26"
    height="17"
    viewBox="0 0 26 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.748 8.01401C12.5108 8.01401 11.504 7.03335 11.504 5.82829C11.504 4.62324 12.5108 3.64258 13.748 3.64258C14.9852 3.64258 15.992 4.62324 15.992 5.82829C15.992 7.03335 14.9852 8.01401 13.748 8.01401ZM13.748 5.09972C13.3366 5.09972 13 5.42611 13 5.82829C13 6.23047 13.3366 6.55686 13.748 6.55686C14.1594 6.55686 14.496 6.23047 14.496 5.82829C14.496 5.42611 14.1594 5.09972 13.748 5.09972Z"
      fill="black"
    />
    <path
      d="M18.236 13.8431C16.9988 13.8431 15.992 12.8624 15.992 11.6574C15.992 10.4523 16.9988 9.47168 18.236 9.47168C19.4732 9.47168 20.48 10.4523 20.48 11.6574C20.48 12.8624 19.4732 13.8431 18.236 13.8431ZM18.236 10.9288C17.8246 10.9288 17.488 11.2567 17.488 11.6574C17.488 12.0581 17.8246 12.386 18.236 12.386C18.6474 12.386 18.984 12.0581 18.984 11.6574C18.984 11.2567 18.6474 10.9288 18.236 10.9288Z"
      fill="black"
    />
    <path
      d="M12.252 13.8426C12.083 13.8426 11.9139 13.7873 11.7733 13.6736C11.4561 13.4157 11.4128 12.9568 11.6776 12.6478L19.1576 3.90492C19.4239 3.59593 19.8951 3.5582 20.2108 3.81174C20.5281 4.06966 20.5698 4.52861 20.305 4.83906L12.825 13.5819C12.6784 13.7522 12.4659 13.8426 12.252 13.8426L12.252 13.8426Z"
      fill="black"
    />
    <path d="M7.01599 3.64258H8.51199V5.09972H7.01599V3.64258Z" fill="black" />
    <path d="M7.01599 12.3857H8.51199V13.8429H7.01599V12.3857Z" fill="black" />
    <path d="M7.01599 9.47168H8.51199V10.9288H7.01599V9.47168Z" fill="black" />
    <path d="M7.01599 6.55762H8.51199V8.01476H7.01599V6.55762Z" fill="black" />
    <path
      d="M23.472 16.7571H2.52798C1.68864 16.7571 1.03198 16.1175 1.03198 15.2999V11.6571C1.03198 11.2549 1.36708 10.9285 1.77998 10.9285C3.01717 10.9285 4.02398 9.94785 4.02398 8.74279C4.02398 7.53774 3.01717 6.55708 1.77998 6.55708C1.36708 6.55708 1.03198 6.23069 1.03198 5.82851V2.18566C1.03198 1.38277 1.70369 0.728516 2.52798 0.728516H23.472C24.3113 0.728516 24.968 1.36812 24.968 2.18566V15.2999C24.968 16.1175 24.3113 16.7571 23.472 16.7571ZM2.52798 12.3128V15.2999H23.472V2.18566H2.52798V5.1728C4.23334 5.51236 5.51998 6.98253 5.51998 8.74279C5.51998 10.5016 4.23334 11.9747 2.52798 12.3128Z"
      fill="black"
    />
  </svg>
);

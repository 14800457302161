import React from "react";
import { observer } from "mobx-react";
import store, { prevStep, nextStep } from "../store";
import { Steps, StepsTitles } from "../constants";
import { Chevron, Expand } from "./Icons";

const TopBar = () => {
  const fullscreenHandler = () => {
    const isFullscreen = Boolean(
      document.fullscreenElement ||
        document.mozFullscreenEnabled ||
        document.webkitFullscreenElement
    );

    if (isFullscreen) {
      const exitMethod =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (exitMethod) exitMethod.call(document);
    } else {
      const el = document.documentElement;
      const requestMethod =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullScreen;

      if (requestMethod) requestMethod.call(el);
    }
  };

  const showNextButton =
    store.step !== Steps.Summary && store.step !== Steps.Payment;

  return (
    <div
      className={`flex items-center absolute top-0 right-0 left-0 z-30 px-4 ${
        store.campaign ? "mt-12" : "mt-5"
      }`}
    >
      {store.step !== Steps.Placements && (
        <button
          className="w-8 h-8 flex justify-center items-center mr-3"
          onClick={() => {
            window.scrollTo({ top: 0 });
            prevStep();
          }}
        >
          <Chevron />
        </button>
      )}
      <h2 className="text-darkest font-medium break-words min-w-0">
        {store.step === Steps.Payment && store.paymentType
          ? store.paymentType === "buy"
            ? "Checkout"
            : "Offert"
          : StepsTitles[store.step]}
      </h2>
      <div className="flex ml-auto">
        <div className="px-4 py-1 bg-dark text-white whitespace-nowrap rounded-2xl mr-0 lg:mr-8">
          {new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK",
          }).format(
            (store.currentPrice && store.currentPrice.total_amount / 100) || 0
          )}
        </div>
        {(document.fullscreenEnabled ||
          document.mozFullscreenEnabled ||
          document.webkitFullscreenEnabled) && (
          <button
            className="w-8 h-8 hidden lg:flex justify-center items-center"
            onClick={() => fullscreenHandler()}
          >
            <Expand />
          </button>
        )}
        {showNextButton && (
          <button
            className="w-8 h-8 flex justify-center items-center self-end ml-3 text-green disabled:text-red disabled:cursor-not-allowed transition-opacity duration-500"
            disabled={
              store.step === Steps.Payment ||
              store.completedSteps.indexOf(store.step) < 0
            }
            onClick={() => {
              window.scrollTo({ top: 0 });
              nextStep();
            }}
          >
            <Chevron rotate="180" />
          </button>
        )}
      </div>
    </div>
  );
};

export default observer(TopBar);

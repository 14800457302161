import React from "react";
import { observer } from "mobx-react";
import { SectionDragType, SectionTypes, SectionWidths } from "../constants";
import {
  canDragPartition,
  canRemovePartition,
  getSection,
  movePartition,
  removePartition,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import { Arrow, CrossCircle } from "./Icons";
import { useDrag } from "react-dnd";

const partitionWidth = SectionWidths[SectionTypes.Partition];

const WardrobePartition = observer(
  ({
    section,
    sectionIndex,
    depth,
    wardrobeWidth,
    offset = 0,
    frontFaceRef,
    color,
    canRemove,
    canMove,
  }) => {
    const [, drag] = useDrag(
      () => ({
        type: SectionDragType,
        item: {
          type: section.type,
          index: sectionIndex,
        },
        end(_item, monitor) {
          const offset = monitor.getDifferenceFromInitialOffset();
          const frontFaceRect = frontFaceRef.current.getBoundingClientRect();
          // Move the dragged section to an even mm.
          movePartition(
            sectionIndex,
            Math.round((offset.x / frontFaceRect.width) * wardrobeWidth)
          );

          const leftSection = getSection(sectionIndex - 1);
          const rightSection = getSection(sectionIndex + 1);
          const clothesRailToTheLeft =
            leftSection &&
            (leftSection.type === SectionTypes.ClothesRail ||
              leftSection.type === SectionTypes.DoubleClothesRail);
          const clothesRailToTheRight =
            rightSection &&
            (rightSection.type === SectionTypes.ClothesRail ||
              rightSection.type === SectionTypes.DoubleClothesRail);

          // Updating the width of a clothes rail section can affect the amount of rails
          // needed, so we update the order and get the new price just to make sure.
          if (clothesRailToTheLeft || clothesRailToTheRight) {
            setCurrentOrder();
            setCurrentPrice();
          }
        },
      }),
      [section.type, sectionIndex]
    );

    const isDraggable = canMove && canDragPartition(sectionIndex);
    const isRemovable = canRemove && canRemovePartition(sectionIndex);

    return (
      <div
        className={`absolute h-full preserve-3d ${isDraggable ? "z-10" : ""}`}
        style={{
          left: `${((section.pos + offset) / wardrobeWidth) * 100}%`,
          width: `${(partitionWidth / wardrobeWidth) * 100}%`,
          background: color,
        }}
      >
        {/* We let the borders of the wardrobe outline the depth
          instead of rendering it twice for partitions on the leftmost
          and rightmost parts of the wardrobe */}
        {section.pos + offset !== 0 &&
          section.pos + section.width + offset !== wardrobeWidth && (
            <div
              className="absolute h-full"
              style={{
                width: `calc(50px + ${depth}px * 2)`,
                // Half the width of the parent element minus
                // half the width of the element itself
                right: `calc(50% - 25px - ${depth}px)`,
                transform: `rotateY(-90deg) translateX(${(25 + depth) * -1}px)`,
                borderLeft: "1px dashed #1D1211",
                borderTop: "1px dashed #1D1211",
                borderBottom: "1px dashed #1D1211",
              }}
            />
          )}

        {isDraggable && (
          <>
            <div
              className="absolute h-full top-0 right-0 bottom-0 left-0 cursor-move"
              style={{
                width: "600%",
                left: "-250%",
              }}
              ref={drag}
            ></div>
            <div
              className="absolute transform -rotate-90"
              style={{ bottom: "25%", right: "100%" }}
            >
              <Arrow />
            </div>
            <div
              className="absolute transform rotate-90"
              style={{ bottom: "25%", left: "100%" }}
            >
              <Arrow />
            </div>
          </>
        )}

        {isRemovable && (
          <button
            onClick={() => removePartition(sectionIndex)}
            className="absolute focus:outline-none"
            style={{
              left: "calc(50% - 8px)",
              bottom: "-32px",
            }}
          >
            <CrossCircle />
          </button>
        )}
      </div>
    );
  }
);

export default WardrobePartition;

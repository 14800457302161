import React, { forwardRef, useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import InfoBox from "./InfoBox";

const Input = forwardRef(
  ({ label, info, name, full, disabled, help, ...props }, ref) => {
    const inputRef = useRef();
    const infoBoxButtonRef = useRef();
    const infoBox = useLocalObservable(() => ({
      isVisible: false,
      toggle() {
        infoBox.isVisible = !infoBox.isVisible;
      },
      hide(e) {
        if (
          e.target !== infoBoxButtonRef.current &&
          !document.getElementById("infobox-root").contains(e.target)
        ) {
          infoBox.isVisible = false;
        }
      },
    }));

    useEffect(() => {
      if (help && help.title && help.description) {
        document.addEventListener("mousedown", infoBox.hide);
        return () => {
          document.removeEventListener("mousedown", infoBox.hide);
        };
      }
    }, []);

    return (
      <div
        className={`${full ? "w-full" : "w-32 lg:w-64"} flex flex-col`}
        ref={inputRef}
      >
        <label htmlFor={name} className="font-medium text-sm mb-2">
          {label}
        </label>
        <input
          type="number"
          id={name}
          name={name}
          className={`w-full rounded-md p-3 ${
            disabled ? "bg-grey-light" : "bg-light"
          }`}
          ref={ref}
          disabled={disabled}
          {...props}
        />
        <div className="flex mt-2">
          {info && <p className="text-med text-xs">{info}</p>}
          {help && help.title && help.description && (
            <span
              className="w-4 h-4 inline-flex justify-center items-center text-xs text-dark leading-none rounded-full border border-dark cursor-pointer ml-1"
              onClick={() => infoBox.toggle()}
              ref={infoBoxButtonRef}
            >
              ?
            </span>
          )}
        </div>
        {help && help.title && help.description && infoBox.isVisible && (
          <InfoBox ref={inputRef}>
            {help.image && (
              <img src={help.image} className="w-full rounded-t-sm" />
            )}
            <div className="max-h-64 lg:max-h-full overflow-auto px-6 py-3">
              <h3 className="text-dark font-medium">{help.title}</h3>
              <p className="text-med text-sm">{help.description}</p>
            </div>
            <span className="w-2.5 h-2.5 absolute bottom-0 left-1/2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-yellow rounded-sm"></span>
          </InfoBox>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

Input.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  help: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    image: PropTypes.string,
  }),
};

export default observer(Input);

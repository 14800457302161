import React from "react";
import store, {
  completeStep,
  setOrderType,
  setBeadConfig,
  setPlacement,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import { observer } from "mobx-react";
import RadioGroup from "./RadioGroup";
import RadioGroupItem from "./RadioGroupItem";
import ToggleSwitch from "./ToggleSwitch";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";
import { OrderType } from "../constants";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";

const Placements = observer(() => {
  useEffectSkipFirst(() => {
    setCurrentOrder();
    setCurrentPrice();
  }, [store.bead, store.beadConfig, store.sidewallConfig, store.placement]);

  return (
    <>
      <RadioGroup
        info={{
          title: "Vad har ni för behov?",
          description: (
            <>
              Vare sig ni önskar en hel garderob med både skjutdörrar och
              inredning, bara skjutdörrar eller bara inredning har vi lösningen
              för er. Gör ett första val här i resan mot er drömgarderob.
            </>
          ),
        }}
      >
        {store.wardrobeTypes.map((wardrobeType, index) => (
          <RadioGroupItem
            key={wardrobeType.value}
            name="wardrobe"
            value={wardrobeType.value}
            label={wardrobeType.label}
            activeValue={store.orderType}
            onChange={() => {
              setOrderType(wardrobeType.value);
            }}
            index={index}
            options={store.wardrobeTypes}
          />
        ))}
      </RadioGroup>
      {store.orderType !== null && store.orderType !== OrderType.OnlyInterior && (
        <RadioGroup
          info={{
            title: "Hur ska er garderob placeras?",
            description: (
              <>
                Ska er garderob gå mellan två väggar eller behöver ni en eller
                flera sidoväggar? Har ni ett annat behov kan ni göra denna
                notering i sista steget innan offertförfrågan skickas. Alla
                dessa alternativ förutsätter att garderoben monteras mot en
                färdig bakvägg då garderoben som standard monteras utan rygg.
                Bra att veta är att sidoväggen är 19 mm och finns i vit (NCS
                0500) och antracit (NCS S 6500-N)
              </>
            ),
          }}
        >
          {store.placements.map((placement, index) => (
            <RadioGroupItem
              key={placement.value}
              name="placement"
              value={placement.value}
              label={placement.label}
              activeValue={store.placement}
              onChange={(e) => {
                completeStep();
                setPlacement(e.target.value);
              }}
              index={index}
              options={store.placements}
            />
          ))}
        </RadioGroup>
      )}
      {store.placement && store.orderType !== OrderType.OnlyInterior && (
        <>
          {store.placement !== "center" && (
            <ToggleSwitch
              label="Anslagslist"
              name="bead"
              info={{
                title: "Anslagslist",
                description: (
                  <>
                    När skjutdörrarna stängs emot en befintlig vägg och inte mot
                    en sidovägg behöver ev. tak- och golvlister kapas bort för
                    att dörren ska stängas direkt mot väggen och inte mot
                    listerna. Stängs dörrarna mot listerna kommer det bli en
                    glipa. Om man tar bort lister får man ha i åtanke att ytan
                    som varit bakom listerna kan komma att synas. Så har man
                    inte tapet eller färg hela vägen ner mot golvet kan detta
                    synas. Som lösning på detta kan vi sätta en anslagslist. Man
                    kapar bort golv- och taklist men sätter en vit eller
                    antracit anslagslist mot väggen. På detta sätt blir det en
                    snygg anslutning med golv och taklist och ytan bakom
                    listerna syns inte.
                  </>
                ),
              }}
            />
          )}
          <ColorRadioGroup
            info={{
              title: "Färg på anslagslist & sidovägg",
              description: (
                <>
                  Vit: NCS 0500
                  <br />
                  Antracit: RAL 7021
                </>
              ),
            }}
          >
            {store.beads &&
              store.beads.configurations.map((config, index) => (
                <ColorRadioGroupItem
                  key={config.id}
                  name="bead_product"
                  value={config.id}
                  label={config.meta.color}
                  color={config.meta.color === "Vit" ? "#fff" : "#666665"}
                  activeValue={store.beadConfig.id}
                  onChange={() => {
                    setBeadConfig(config);
                  }}
                  first={index === 0}
                  last={index === store.beads.configurations.length - 1}
                  isActive={store.beadConfig.id === config.id}
                />
              ))}
          </ColorRadioGroup>
        </>
      )}
    </>
  );
});

export default Placements;

import React from "react";
import {
  Figure1,
  Figure2,
  Figure3,
  Figure4,
  Figure5,
  Figure6,
  Figure7,
  Figure8,
} from "./components/Layouts";

const fieldLayouts = {
  figure1: {
    id: 1,
    figure: <Figure1 />,
    fields: ["h-full"],
    info: "1 fält (standard, inga spörjs)",
  },
  figure2: {
    id: 2,
    figure: <Figure2 />,
    fields: ["h-1/2", "h-1/2"],
    info: "2 lika stora fält",
  },
  figure3: {
    id: 3,
    figure: <Figure3 />,
    fields: ["h-1/3", "h-1/3", "h-1/3"],
    info: "3 lika stora fält",
  },
  figure4: {
    id: 4,
    figure: <Figure4 />,
    fields: ["h-1/4", "h-1/4", "h-1/2"],
    info: "25%, 25%, 50% (uppifrån)",
  },
  figure5: {
    id: 5,
    figure: <Figure5 />,
    fields: ["h-1/4", "h-1/4", "h-1/4", "h-1/4"],
    info: "4 lika stora fält",
  },
  figure6: {
    id: 6,
    figure: <Figure6 />,
    fields: ["h-1/5", "h-3/5", "h-1/5"],
    info: "20%, 60%, 20%",
  },
  figure7: {
    id: 7,
    figure: <Figure7 />,
    fields: ["flex-1", "h-1/10", "flex-1"],
    info: "25 cm fält mitt på dörren",
  },
  figure8: {
    id: 8,
    figure: <Figure8 />,
    fields: ["h-3/5", "h-1/5", "h-1/5"],
    info: "60%, 20%, 20% (uppifrån)",
  },
};

export default fieldLayouts;

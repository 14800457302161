import React, { useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react";
import store, {
  setTracks,
  setDoors,
  setDoorLayout,
  setProfileType,
  setProfileColor,
  completeStep,
  setCurrentOrder,
  setCurrentPrice,
} from "../store";
import ToggleSwitch from "./ToggleSwitch";
import RadioGroup from "./RadioGroup";
import RadioGroupItem from "./RadioGroupItem";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import { OrderType } from "../constants";
import ProfileLuna from "../assets/profile_luna.jpg";
import ProfileNova from "../assets/profile_nova.jpg";

const Doors = observer(() => {
  useEffectSkipFirst(() => {
    setCurrentOrder();
    setCurrentPrice();
  }, [
    store.doors,
    store.profile.color,
    store.tracks,
    store.softClosing,
    store.muffler,
  ]);

  useEffect(() => {
    const shouldCompleteStep =
      store.doors.length &&
      store.tracks &&
      store.profile.type &&
      store.profile.color;
    if (shouldCompleteStep) {
      completeStep();
    }
  }, []);

  const doors = useLocalObservable(() => ({
    choices: [],
    setChoices() {
      const doorWidths = [600, 750, 900, 1050, 1200];
      const doorChoices = [];
      doorWidths.forEach((doorWidth) => {
        const totalDoors = Math.ceil(store.size.width / doorWidth);
        if (
          doorChoices.indexOf(totalDoors) === -1 &&
          totalDoors >= 2 &&
          totalDoors <= 6
        ) {
          doorChoices.push(totalDoors);
        }
      });

      doors.choices = doorChoices.sort();
    },
    activeProfileProducts: null,
  }));

  const threeTracksDoors = [3, 5, 6];
  const shouldHaveThreeTracks =
    threeTracksDoors.indexOf(store.doors.length) > -1 &&
    (store.orderType === OrderType.FullWardrobe
      ? store.size.depth >= 640
      : true);
  const trackOptions = shouldHaveThreeTracks ? [2, 3] : [2];
  const handleChange = (e) => {
    const parsed = parseInt(e.target.value, 10);
    if (e.target.name === "doors") {
      if (threeTracksDoors.indexOf(parsed) === -1) {
        setTracks(2);
      }
      const doors = [];
      for (let index = 0; index < parsed; index++) {
        doors.push({
          id: index,
          layout: { type: null, fields: null },
          active: false,
        });
      }
      setDoors(doors);
      // Set default layout for doors
      store.doors.forEach((door) => {
        setDoorLayout(door, "figure1");
      });
      completeStep();
    } else if (e.target.name === "tracks") {
      setTracks(parsed);
    }
  };

  useEffect(() => {
    doors.setChoices();
  }, []);

  useEffect(() => {
    if (store.profile.type) {
      doors.activeProfileProducts = store.profiles.find(
        (profile) => profile.id === store.profile.type
      ).Products;
    }
  }, [store.profile.type]);

  return (
    <>
      <RadioGroup
        label="Dörrar"
        info={{
          title: "Dörrar",
          description: (
            <>
              Baserat på bredden ser ni antalet dörrar som är möjligt för er att
              välja. Minsta bredden per dörr är 400 mm och största bredden 1200
              mm. När man väljer antal dörrar ska man både tänka både på
              utseende och att det ska vara praktiskt och enkelt att komma åt
              inredningen.
            </>
          ),
        }}
      >
        {doors.choices.map((choice, index) => (
          <RadioGroupItem
            key={choice}
            name="doors"
            value={choice}
            label={choice}
            activeValue={store.doors.length}
            onChange={handleChange}
            index={index}
            options={doors.choices}
          />
        ))}
      </RadioGroup>
      {store.doors.length > 0 && (
        <>
          <RadioGroup
            label="Spår"
            info={{
              title: "Spår",
              description: (
                <>
                  Ni kan välja på 2 eller 3 spårig skena. Denna kommer alltid i
                  samma färg som profilfärgen runt om dörrarna. 2 spårig skena
                  är 82 mm och 3 spårig skena är 122 mm.
                  <br />
                  När man har 2 eller 4 dörrar räcker det med en 2 spårig skena
                  och man kan hela tiden komma åt halva garderoben.
                  <br />
                  Men vid 3 dörrar finns tillvalet med 3 spårig skena. Med 3
                  dörrar på en 3 spårig skena kan man skjuta alla dörrarna och
                  ett och samma hål och man kommer åt två tredjedelar av
                  garderoben. Men 3 dörrar på en 2 spårig skena kommer man åt
                  det som finns bakom en dörr.
                </>
              ),
            }}
          >
            {trackOptions.map((trackOption, index) => (
              <RadioGroupItem
                key={trackOption}
                name="tracks"
                value={trackOption}
                label={trackOption}
                activeValue={store.tracks}
                onChange={handleChange}
                index={index}
                options={trackOptions}
              />
            ))}
          </RadioGroup>

          <ToggleSwitch
            label="Mjukstängning"
            name="softClosing"
            info={{
              title: "Mjukstängning",
              description: (
                <>
                  Mjukstängningen gör att dörrarna stängs sakta sista biten mot
                  väggen. Detta gör att det inte blir en hård smäll mot
                  väggen/sidoväggen vilket gör att dörr, vägg och sidovägg
                  kommer hålla längre. Vanligtvis sätts en mjukstängning per
                  dörr. Mjukstängning rekommenderas inte på dörrar under 550 mm
                  bredd.
                </>
              ),
            }}
          />
          <ToggleSwitch
            label="Filtlist"
            name="muffler"
            info={{
              title: "Filtlist",
              description: (
                <>
                  Kan man inte ha mjukstängning, eller önskar ett komplement
                  till mjukstängning är filtlist ett bra alternativ. Detta är en
                  list men sätter på sidan på profilen som skyddar dörr och vägg
                  och även om man stänger dörren hårt kommer det inte bli en hög
                  smäll. Detta skickas med 2 stycken per dörr. Finns endast i
                  grått.
                </>
              ),
            }}
          />
          <RadioGroup
            label="Rammodell"
            info={{
              image: store.profile.type === 1 ? ProfileNova : ProfileLuna,
              title: store.profile.type === 1 ? "Nova" : "Luna",
              description:
                store.profile.type === 1 ? (
                  <>
                    Kvalité och hjulsystem på våra två profiler håller samma
                    höga kvalitet. Det som skiljer dem åt är utseendet och
                    färgerna de finns i.
                    <br />
                    Profilen Nova är bredare (35 mm) och rundare i formen.
                  </>
                ) : (
                  <>
                    Kvalité och hjulsystem på våra två profiler håller samma
                    höga kvalitet. Det som skiljer dem åt är utseendet och
                    färgerna de finns i. Profilen Luna är smalare (20 mm) och
                    har en mer modern och rakare form. Profilen Luna
                    rekommenderas alltid på smalare dörrar för både större
                    öppningsmått och för bättre intryck.
                  </>
                ),
            }}
          >
            {store.profiles.map((profile, index) => (
              <RadioGroupItem
                key={profile.id}
                name="profile"
                value={profile.id}
                label={profile.name}
                activeValue={store.profile.type}
                onChange={() => {
                  setProfileType(parseInt(profile.id, 10));
                  setProfileColor(profile.Products[0].id);
                }}
                index={index}
                options={store.profiles}
              />
            ))}
          </RadioGroup>
          {doors.activeProfileProducts && (
            <ColorRadioGroup
              info={{
                title: "Färg på rammodell",
                description: (
                  <>
                    Här väljer du färg på ramen. Det blir alltid samma färg på
                    ramen som det blir på skenor och ev. spröjs.
                    <br />
                    Vit profil: NCS 0500
                    <br />
                    Antracit: RAL 7021
                  </>
                ),
              }}
            >
              {doors.activeProfileProducts.map((product, index) => (
                <ColorRadioGroupItem
                  key={product.id}
                  name="profile_product"
                  value={product.id}
                  label={product.name}
                  color={product.color}
                  activeValue={store.profile.color}
                  onChange={(e) => {
                    setProfileColor(parseInt(e.target.value, 10));
                  }}
                  first={index === 0}
                  last={index === doors.activeProfileProducts.length - 1}
                  isActive={store.profile.color === product.id}
                />
              ))}
            </ColorRadioGroup>
          )}
        </>
      )}
    </>
  );
});

export default Doors;

import { observer, useLocalObservable } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { BodyColors, InteriorWardrobeMaxHeight } from "../constants";
import { useDraggedProduct } from "../hooks/useDraggedProduct";
import { useDropProducts } from "../hooks/useDropProducts";
import store, { calculateProductMovement } from "../store";
import { ZoomOut } from "./Icons";
import WardrobeProduct from "./WardrobeProduct";

const SelectedSection = observer(() => {
  const wrapperRef = useRef(null);
  const interiorRef = useRef(null);

  const section = useLocalObservable(() => ({
    width: null,
    setSectionWidth() {
      if (interiorRef.current) {
        section.width =
          interiorRef.current.offsetHeight *
          (store.activeSection.width / InteriorWardrobeMaxHeight);
      }
    },
  }));

  const drop = useDropProducts(wrapperRef);
  const draggedProduct = useDraggedProduct(wrapperRef);

  useEffect(() => {
    window.addEventListener("resize", section.setSectionWidth);

    return () => {
      window.removeEventListener("resize", section.setSectionWidth);
    };
  }, []);

  useEffect(() => {
    section.setSectionWidth();
  }, []);

  return (
    <div
      className="w-full absolute inset-0 z-40"
      style={{
        backgroundColor: "#c9c5bc",
        paddingTop: "72px",
        marginBottom: "136px",
      }}
      ref={wrapperRef}
    >
      <div className="w-full h-full pb-10" ref={drop}>
        <div
          className="h-full border-4 relative mx-auto transition-opacity duration-200"
          style={{
            width: section.width ? `${section.width}px` : null,
            borderColor: BodyColors[store.bodyColor],
            opacity: section.width ? 1 : 0,
          }}
          ref={interiorRef}
        >
          {store.activeSection.products.map((product, i) => {
            let offset = 0;

            if (draggedProduct && i === draggedProduct.index) {
              const { newPosition } = calculateProductMovement(
                draggedProduct.index,
                draggedProduct.relativePos
              );
              offset = newPosition - product.pos;
            }

            return (
              <WardrobeProduct
                key={product.id}
                src={product.src}
                section={store.activeSection}
                product={product}
                productIndex={i}
                wrapperRef={wrapperRef}
                offset={offset}
              />
            );
          })}
        </div>
        <button
          onClick={() => {
            store.activeSection = null;
          }}
          className="block text-white mx-auto mt-3"
        >
          <ZoomOut />
        </button>
      </div>
    </div>
  );
});

export default SelectedSection;

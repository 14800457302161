import { useDrop } from "react-dnd";
import {
  DrawerWithHandleName,
  DrawerWithoutHandleName,
  JewelryBoxName,
  ProductDropType,
  WardrobeHoles,
} from "../constants";
import store, { addProduct, safeToAddProducts } from "../store";

export const useDropProducts = (wrapperRef) => {
  const [, drop] = useDrop(() => ({
    accept: ProductDropType,
    drop: ({ productId, configId }, monitor) => {
      const offset = monitor.getClientOffset();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const y = wrapperRect.bottom - offset.y;
      // We want the product to be placed in a hole.
      const pos = Math.round((y / wrapperRect.height) * WardrobeHoles);

      // Show information modal when pullable product is added in section
      if (!store.showInteriorSectionInfoModalSeen && store.tracks === 3) {
        const interiorProduct = store.interiorProducts.find(
          (p) => p.id === productId
        );
        if (
          interiorProduct.name === DrawerWithHandleName ||
          interiorProduct.name === DrawerWithoutHandleName ||
          interiorProduct.name === JewelryBoxName
        ) {
          const isSafeToAddProducts = safeToAddProducts(
            store.activeSection.pos,
            store.activeSection.width
          );

          if (!isSafeToAddProducts) {
            store.showInteriorSectionInfoModal = true;
            store.showInteriorSectionInfoModalSeen = true;
          }
        }
      }

      addProduct(productId, configId, pos);
    },
  }));

  return drop;
};

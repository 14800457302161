import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { observer, useLocalObservable } from "mobx-react";
import store from "../store";
import { ScrollIcon } from "./Icons";
import { ToolbarScrollSpeed } from "../constants";

const ToolBar = observer(({ children }) => {
  const toolBarRef = useRef(null);
  useEffect(() => {
    if (toolBarRef.current) {
      toolBarRef.current.scrollLeft = 0;
    }
  }, [store.step]);

  const rafIdRef = useRef(null);
  const handleTouchStart = (shouldScrollLeft) => {
    let previousTimeStamp = window.performance.now();

    const step = (timestamp) => {
      const delta = timestamp - previousTimeStamp;

      toolBarRef.current.scrollLeft +=
        ToolbarScrollSpeed * 0.1 * delta * (shouldScrollLeft ? -1 : 1);

      previousTimeStamp = timestamp;

      rafIdRef.current = requestAnimationFrame(step);
    };

    rafIdRef.current = requestAnimationFrame(step);
  };
  useEffect(() => {
    const handleTouchEnd = () => {
      cancelAnimationFrame(rafIdRef.current);
    };

    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("mouseup", handleTouchEnd);
    return () => {
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("mmouseup", handleTouchEnd);
    };
  }, []);

  const buttons = useLocalObservable(() => ({
    shouldRender: true,
    setShouldRender(shouldRender) {
      buttons.shouldRender = shouldRender;
    },
  }));
  useEffect(() => {
    if (typeof ResizeObserver !== "undefined") {
      const resizeObserver = new ResizeObserver(() => {
        if (toolBarRef.current) {
          buttons.setShouldRender(
            toolBarRef.current.offsetWidth < toolBarRef.current.scrollWidth
          );
        }
      });

      resizeObserver.observe(toolBarRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <div
      id="toolbar"
      className="h-34 flex items-center bg-toolbar overflow-x-auto z-30"
      ref={toolBarRef}
    >
      {store.isMobile && buttons.shouldRender && (
        <div className="flex h-30 items-center z-40">
          <button
            onTouchStart={() => handleTouchStart(true)}
            onMouseDown={() => handleTouchStart(true)}
            onContextMenu={(e) => e.preventDefault()}
            className="absolute h-28 p-4 left-0 bg-gradient-to-r via-toolbar from-toolbar"
          >
            <ScrollIcon />
          </button>

          <button
            onTouchStart={() => handleTouchStart(false)}
            onMouseDown={() => handleTouchStart(false)}
            onContextMenu={(e) => e.preventDefault()}
            className="absolute h-28 p-4 right-0 bg-gradient-to-l via-toolbar from-toolbar"
          >
            <ScrollIcon rotate="180" />
          </button>
        </div>
      )}
      <div className="lg:mx-auto px-8.5">
        <div className="flex space-x-8 mx-5">{children}</div>
      </div>
    </div>
  );
});

ToolBar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ToolBar;

import React, { forwardRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";

const Checkbox = forwardRef(
  ({ label, name, onChange, checked = false, ...props }, ref) => {
    const checkbox = useLocalObservable(() => ({
      checked,
      toggleChecked() {
        checkbox.checked = !checkbox.checked;
      },
    }));

    return (
      <label className="w-full flex items-center text-sm cursor-pointer">
        <input
          type="checkbox"
          name={name}
          className="hidden"
          onChange={(e) => {
            checkbox.toggleChecked();
            if (onChange) {
              onChange(e);
            }
          }}
          ref={ref}
          checked={checkbox.checked}
          {...props}
        />
        <span className="flex justify-center items-center w-6 h-6 appearance-none border border-dark outline-none p-0.5 mr-2">
          {checkbox.checked && <div className="w-full h-full bg-dark"></div>}
        </span>
        {label}
      </label>
    );
  }
);

Checkbox.displayName = "Input";

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
};

export default observer(Checkbox);

import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const Button = observer(({ label, active, onClick, children }) => (
  <button
    className={`bg-white whitespace-nowrap ${
      active ? "border-yellow" : "border-white"
    } border-2 rounded-lg text-xs leading-none font-semibold py-4 px-5 focus:outline-none outline-none`}
    onClick={onClick}
  >
    <div className="flex items-center justify-center flex-col sm:flex-row">
      <span className={`${children ? "mr-0 sm:mr-2" : ""}`}>{label}</span>
      <div className="mt-1 sm:mt-0">{children}</div>
    </div>
  </button>
));

Button.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;

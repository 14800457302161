import React from "react";

const Light = () => (
  <div
    className="w-full absolute top-0 z-30 overflow-hidden pointer-events-none"
    style={{
      height: "25%",
    }}
  >
    <div
      className="h-full opacity-75 pointer-events-none"
      style={{
        filter: "blur(5px)",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="978"
        height="206"
        viewBox="0 0 978 206"
        className="w-full absolute transform -translate-y-1/2"
      >
        <defs>
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.5"
            r="0.484"
            gradientTransform="translate(0 -0.058) scale(1 1.116)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff6e3" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
        </defs>
        <ellipse
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="489"
          cy="103"
          rx="489"
          ry="103"
          fill="url(#radial-gradient)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="978"
        height="206"
        viewBox="0 0 978 206"
        className="w-full absolute transform -translate-y-1/2 -translate-x-1/2"
      >
        <defs>
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.5"
            r="0.484"
            gradientTransform="translate(0 -0.058) scale(1 1.116)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff6e3" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
        </defs>
        <ellipse
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="489"
          cy="103"
          rx="489"
          ry="103"
          fill="url(#radial-gradient)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="978"
        height="206"
        viewBox="0 0 978 206"
        className="w-full absolute transform -translate-y-1/2 translate-x-1/2"
      >
        <defs>
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.5"
            r="0.484"
            gradientTransform="translate(0 -0.058) scale(1 1.116)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#fff6e3" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
        </defs>
        <ellipse
          id="Ellipse_8"
          data-name="Ellipse 8"
          cx="489"
          cy="103"
          rx="489"
          ry="103"
          fill="url(#radial-gradient)"
        />
      </svg>
    </div>
  </div>
);

export default Light;

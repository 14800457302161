import { observer } from "mobx-react-lite";
import React from "react";
import { DetailColorNames, DetailColors } from "../constants";
import store, { setDetailColor } from "../store";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";

const InteriorColorRadioGroup = observer(() => (
  <ColorRadioGroup title="Inredningsdetaljer">
    <ColorRadioGroupItem
      name="detaljer"
      value={DetailColorNames.White}
      label="Vit"
      color={DetailColors[DetailColorNames.White]}
      first
      last={false}
      isActive={store.detailColor === DetailColorNames.White}
      onChange={() => setDetailColor(DetailColorNames.White)}
    />
    <ColorRadioGroupItem
      name="detaljer"
      value={DetailColorNames.DarkGrey}
      label="Mörkgrå"
      color={DetailColors[DetailColorNames.DarkGrey]}
      first={false}
      last
      isActive={store.detailColor === DetailColorNames.DarkGrey}
      onChange={() => setDetailColor(DetailColorNames.DarkGrey)}
    />
  </ColorRadioGroup>
));

export default InteriorColorRadioGroup;

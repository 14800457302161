import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const SummaryBox = observer(({ label, rows }) => (
  <div className="w-full bg-yellow rounded-lg">
    {label && <p className="text-sm font-bold px-4 pt-4">{label}</p>}
    <div className="flex flex-col">
      {rows.map((row, i) => (
        <React.Fragment key={i}>
          {row.value && (
            <span className="flex items-center px-4 py-1.5 border-b border-med last:border-b-0 space-x-3">
              <p
                className={`flex-1 text-sm truncate ${
                  row.title ? "font-bold" : "text-med"
                }`}
              >
                {row.label}
              </p>
              <p className="font-bold truncate">{row.value}</p>
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
));

SummaryBox.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      title: PropTypes.bool,
    })
  ).isRequired,
};

export default SummaryBox;

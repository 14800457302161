import React, { useEffect, useRef, useLayoutEffect } from "react";
import store, {
  setProfiles,
  setAreaColors,
  setBase,
  setInterior,
  getOrder,
  setInteriorAccessories,
  setAccessories,
  setShowConfirmPopup,
  setShowAlertPopup,
  resetWardobe,
  setCampaign,
} from "./store";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { get } from "./api";
import floor from "./assets/floor.jpg";
import TopBar from "./components/TopBar";
import ToolBar from "./components/ToolBar";
import Placements from "./components/Placements";
import Size from "./components/Size";
import Doors from "./components/Doors";
import Fields from "./components/Fields";
import Fills from "./components/Fills";
import Lighting from "./components/Lighting";
import Sections from "./components/Sections";
import Interior from "./components/Interior";
import Summary from "./components/Summary";
import Quotation from "./components/Quotation";
import KlarnaCheckout from "./components/KlarnaCheckout";
import Wardrobe from "./components/Wardrobe";
import DoorSummary from "./components/DoorSummary";
import InteriorSummary from "./components/InteriorSummary";
import SummaryBox from "./components/SummaryBox";
import Checkbox from "./components/Checkbox";
import { NumSteps, OrderType, Steps } from "./constants";
import SelectedSection from "./components/SelectedSection";
import InteriorAccessories from "./components/InteriorAccessories";
import Accessories from "./components/Accessories";
import LandscapeOverlay from "./components/LandscapeOverlay";
import { useEffectSkipFirst } from "./hooks/useEffectSkipFirst";
import OnboardingModal from "./components/OnboardingModal";
import SectionsModal from "./components/SectionsModal";
import InteriorModal from "./components/InteriorModal";
import SizeModal from "./components/SizeModal";
import Confirm from "./components/Confirm";
import Alert from "./components/Alert";
import ProductModal from "./components/ProductModal";
import InteriorSectionInfoModal from "./components/InteriorSectionInfoModal";
import TermsModal from "./components/TermsModal";
import CampaignBar from "./components/CampaignBar";

configure({
  enforceActions: "never",
  useProxies: "never",
});

const App = observer(() => {
  const appRef = useRef(null);
  const wallsWardrobeWrapperRef = useRef(null);
  const wardrobeWrapperRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const [
        { data: base },
        { data: areaColors },
        { data: profiles },
        { data: interior },
        { data: interiorAccessories },
        { data: accessories },
        { data: campaign },
      ] = await Promise.all([
        get("/public/product_groups/base"),
        get("/public/product_groups/area_color"),
        get("/public/product_groups/profile_color"),
        get("/public/product_groups/interior_section"),
        get("/public/product_groups/interior_accessories"),
        get("/public/product_groups/other_accessories"),
        get("/public/campaigns"),
      ]);

      setBase(base);
      setAreaColors(areaColors);
      setProfiles(profiles);
      setInterior(interior);
      setInteriorAccessories(interiorAccessories);
      setAccessories(accessories);

      const globalId = new URLSearchParams(location.search).get("id");

      if (globalId) {
        getOrder(globalId);
      }
      // Do not set campaign when editing order
      setCampaign(globalId ? null : campaign);
    }

    fetchData();

    const handleOrientationChange = () => {
      store.orientation = window.orientation || 0;
    };

    window.addEventListener("resize", updateAppDimensions);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", updateAppDimensions);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    updateAppDimensions();
  }, [appRef, store.step]);

  useEffectSkipFirst(() => {
    window.scrollTo({ top: 0 });
  }, [store.requestSent]);

  const updateAppDimensions = () => {
    if (appRef.current) {
      if (store.step !== Steps.Payment) {
        appRef.current.style.height = `${window.innerHeight}px`;
      } else {
        appRef.current.style.height = null;
      }
    }

    store.windowInnerWidth = window.innerWidth;
  };

  const { step, cappedWidth, cappedHeight, placement } = store;

  useLayoutEffect(() => {
    // We don't want to run this logic if the main Wardrobe component is not rendered.
    if (!wardrobeWrapperRef.current) return;
    // If the placement is not wall_to_wall we let the Wardrobe component preserve the
    // aspect ratio by itself.
    if (placement !== "wall_to_wall") {
      wardrobeWrapperRef.current.style.removeProperty("width");
      wardrobeWrapperRef.current.style.removeProperty("height");

      return;
    }

    const updateWardrobeDimensions = () => {
      const aspectRatio = cappedWidth / cappedHeight;
      const parentWidth = wallsWardrobeWrapperRef.current.clientWidth;
      const parentHeight = wallsWardrobeWrapperRef.current.clientHeight;
      const widthHeightRatio = parentWidth / parentHeight;

      if (widthHeightRatio < aspectRatio) {
        wardrobeWrapperRef.current.style.width = "100%";
        wardrobeWrapperRef.current.style.height = `${
          wardrobeWrapperRef.current.clientWidth / aspectRatio
        }px`;
      } else {
        wardrobeWrapperRef.current.style.height = "100%";
        wardrobeWrapperRef.current.style.width = `${
          wardrobeWrapperRef.current.clientHeight * aspectRatio
        }px`;
      }
    };

    updateWardrobeDimensions();

    const resizeObserver = new ResizeObserver(updateWardrobeDimensions);

    resizeObserver.observe(wallsWardrobeWrapperRef.current);

    return () => resizeObserver.disconnect();
  }, [step, cappedWidth, cappedHeight, placement]);

  if (store.requestSent) {
    return (
      <div className="max-w-screen-xl flex flex-1 flex-wrap xl:flex-nowrap px-4 lg:px-16 py-4 lg:py-12 mx-auto xl:space-x-16">
        <div className="w-full xl:flex-1 mb-16 xl:mb-0">
          <div className="flex flex-wrap bg-white rounded-lg px-6 md:px-10 py-8 pb-12 space-y-5 shadow-checkout">
            <h1 className="w-full text-4xl font-bold">Skickat!</h1>
            <p>
              En offertförfrågan med din garderobsdesign är nu skickad och
              kommer behandlas av en av våra representanter. Om du har markerat
              att du vill bli kontaktad eller om undrar något om din design hör
              vi av oss. En kopia av sammanställningen har även skickats till
              den e-post som fyllts i.
            </p>
            <SummaryBox
              label="Information"
              rows={[
                {
                  label: "Namn",
                  value: `${store.customerData.name} ${store.customerData.lastname} `,
                },
                { label: "E-post", value: store.customerData.email },
                {
                  label: "Telefon",
                  value: store.customerData.phone,
                },
                {
                  label: "Leveransadress",
                  value: `${store.customerData.delivery_adress}, ${store.customerData.postal_code} ${store.customerData.city}`,
                },
                {
                  label: "Faktureringsadress",
                  value: store.customerData.billing_adress
                    ? `${store.customerData.billing_adress}, ${store.customerData.billing_postal_code} ${store.customerData.billing_city}`
                    : `${store.customerData.delivery_adress}, ${store.customerData.postal_code} ${store.customerData.city}`,
                },
              ]}
            />
            {store.customerData.comment && (
              <>
                <h2 className="w-full text-2xl font-bold">
                  Kommentar/Önskemål
                </h2>
                <p>{store.customerData.comment}</p>
              </>
            )}
            {store.customerData.sms && (
              <div className="w-full pt-6">
                <Checkbox
                  label="Jag vill ha ett sms när offerten är skickad"
                  checked
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full xl:flex-1 flex flex-wrap space-y-16 lg:space-y-0 lg:space-x-8">
          {store.orderType !== OrderType.OnlyInterior && <DoorSummary />}
          {store.orderType !== OrderType.OnlyDoors &&
            store.sections.length > 0 && <InteriorSummary />}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col bg-background ${
        store.orientation !== 0 ? "overflow-hidden" : ""
      }`}
      ref={appRef}
    >
      {store.campaign && <CampaignBar />}
      <div className="h-1 w-full flex-shrink-0">
        <div
          className="bg-darkest h-full transition-width"
          style={{ width: `${(store.step / (NumSteps - 1)) * 100}%` }}
        ></div>
      </div>
      {store.orientation !== 0 && <LandscapeOverlay />}

      {store.showOnboardingModal && <OnboardingModal />}
      {store.step === Steps.Size && store.showSizeModal && <SizeModal />}
      {store.step === Steps.Sections && store.showSectionsModal && (
        <SectionsModal />
      )}
      {store.step === Steps.Interior && store.showInteriorModal && (
        <InteriorModal />
      )}
      {store.step === Steps.Interior && store.showInteriorSectionInfoModal && (
        <InteriorSectionInfoModal />
      )}
      {store.showConfirmPopup && (
        <Confirm
          text={store.confirmText}
          onConfirm={resetWardobe}
          onCancel={() => setShowConfirmPopup(false)}
        />
      )}
      {store.showAlertPopup && (
        <Alert
          text={store.alertText}
          buttonText={store.alertButtonText}
          onClick={() => setShowAlertPopup(false)}
        />
      )}
      {store.modalProduct && <ProductModal product={store.modalProduct} />}
      {store.termsModal && <TermsModal />}

      {store.activeSection && store.isMobile && <SelectedSection />}

      <TopBar />
      {store.step === Steps.InteriorAccessories ? (
        <InteriorAccessories />
      ) : store.step === Steps.Accessories ? (
        <Accessories />
      ) : store.step === Steps.Summary ? (
        <div className="flex flex-1 flex-wrap overflow-x-auto px-4 lg:px-12 pb-20 mt-20 space-y-8 lg:space-y-0 lg:space-x-8 lg:justify-center">
          {store.orderType !== OrderType.OnlyInterior && (
            <DoorSummary maxWidth />
          )}
          {store.orderType !== OrderType.OnlyDoors &&
            store.sections.length > 0 && <InteriorSummary maxWidth />}
        </div>
      ) : !store.paymentType ? (
        <div
          className="flex flex-1 items-center justify-center pt-24 pb-8"
          style={{
            backgroundColor: "#C9C5BC",
            backgroundImage: `url(${floor})`,
            backgroundSize: "100vw 15%",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            className="w-full h-full flex justify-center relative"
            ref={wallsWardrobeWrapperRef}
          >
            <div className="flex-1 walls"></div>
            <div
              className="flex self-end w-full lg:w-[85%] h-full"
              ref={wardrobeWrapperRef}
            >
              <Wardrobe
                preserveAspectRatio={placement !== "wall_to_wall"}
                placement={placement}
                showTrackbar
                wardrobeScale={0.9}
              />
            </div>
            <div className="flex-1 walls"></div>
          </div>
        </div>
      ) : store.paymentType === "buy" ? (
        <KlarnaCheckout />
      ) : (
        <Quotation />
      )}
      {store.step !== Steps.Payment &&
        store.step !== Steps.InteriorAccessories &&
        store.step !== Steps.Accessories && (
          <ToolBar>
            {store.step === Steps.Placements ? (
              <Placements />
            ) : store.step === Steps.Size ? (
              <Size />
            ) : store.step === Steps.Doors ? (
              <Doors />
            ) : store.step === Steps.Fields ? (
              <Fields />
            ) : store.step === Steps.Fills ? (
              <Fills />
            ) : store.step === Steps.Lighting ? (
              <Lighting />
            ) : store.step === Steps.Sections ? (
              <Sections />
            ) : store.step === Steps.Interior ? (
              <Interior />
            ) : store.step === Steps.Summary ? (
              <Summary />
            ) : null}
          </ToolBar>
        )}
    </div>
  );
});

export default App;

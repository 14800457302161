import React from "react";
import Modal from "./Modal";
import { hideInteriorModal } from "../store";

const InteriorModal = () => (
  <Modal
    title="Inredningsdetaljer"
    buttonText="Jag förstår"
    onClick={hideInteriorModal}
  >
    <p className="mb-4">
      Nu är det dags att fylla era garderobsstommar. Klicka på den sektion ni
      önskar att fylla och ni kommer få upp en lista med valbara alternativ och
      färger.
    </p>
    <p>
      Ritverktyget känner av vilka ställen man inte kan ha utdragbara artiklar
      på och visar inte detta som ett valbart alternativ.
    </p>
  </Modal>
);

export default InteriorModal;

import React from "react";
import { observer } from "mobx-react";
import {
  ProductDragType,
  HoleSpacing,
  InteriorWardrobeMaxHeight,
  API_URL,
  DrawerWithHandleName,
  JewelryBoxName,
  SectionTypes,
} from "../constants";
import store, {
  canRemoveProduct,
  removeProduct,
  moveProduct,
  canDragProduct,
} from "../store";
import { FillCrossCircle } from "./Icons";
import { useDrag } from "react-dnd";
import { useDraggedProduct } from "../hooks/useDraggedProduct";

const WardrobeProduct = observer(
  ({ src, section, product, productIndex, wrapperRef, offset }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: ProductDragType,
        item: {
          index: productIndex,
        },
        collect: (monitor) => ({
          isDragging: Boolean(monitor.isDragging()),
        }),
        end(_item, monitor) {
          const wardrobeHeight = store.size.height;
          const offset = monitor.getDifferenceFromInitialOffset();
          const wrapperRect = wrapperRef.current.getBoundingClientRect();
          // Move the dragged product to a hole.
          moveProduct(
            productIndex,
            Math.round(
              ((-offset.y / wrapperRect.height) * wardrobeHeight) / HoleSpacing
            )
          );
        },
      }),
      [productIndex]
    );

    const draggedProduct = useDraggedProduct(wrapperRef);
    const isDraggable =
      store.activeSection === section && canDragProduct(productIndex);

    return (
      <div
        className={`absolute w-full group z-10 bg-no-repeat bg-center ${
          isDragging ? "cursor-grabbing" : isDraggable ? "cursor-grab" : ""
        }`}
        style={{
          bottom: `${
            (((product.pos + offset) * HoleSpacing) /
              (InteriorWardrobeMaxHeight - 2 * HoleSpacing)) *
            100
          }%`,
          height: `${
            ((product.height * HoleSpacing) /
              (InteriorWardrobeMaxHeight - 2 * HoleSpacing)) *
            100
          }%`,
          backgroundImage: `url(${API_URL + src})`,
          backgroundSize: "100% 100%",
        }}
        ref={isDraggable ? drag : undefined}
      >
        {(product.name === DrawerWithHandleName ||
          product.name === JewelryBoxName) && (
          <div
            className="w-1/4 h-1 border-b border-b-gray-300 mx-auto"
            style={{
              backgroundColor: store.handleColor,
              marginTop:
                product.name === JewelryBoxName
                  ? section.type === SectionTypes.XL
                    ? "2%"
                    : "4%"
                  : null,
            }}
          ></div>
        )}
        {store.activeSection === section &&
          !draggedProduct &&
          canRemoveProduct(productIndex, store.activeSection) && (
            <button
              onClick={() => removeProduct(productIndex)}
              className={`md:hidden absolute focus:outline-none top-1/2 -right-8 md:right-0 transform -translate-y-1/2 z-20 ${
                !isDragging ? "md:group-hover:block" : ""
              }`}
            >
              <FillCrossCircle />
            </button>
          )}
      </div>
    );
  }
);

export default WardrobeProduct;

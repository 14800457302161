import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import store, {
  resetActiveField,
  setActiveDoor,
  setActiveField,
} from "../store";
import { Deselect, Select } from "./Icons";
import Profile from "./Profile";
import Bars from "./Bars";
import { API_URL, Steps } from "../constants";

const addedSize = 5;
const doorTrackStyles = {
  1: {
    zIndex: 10,
  },
  2: {
    zIndex: 20,
  },
  3: {
    zIndex: 30,
  },
};

const Door = observer(({ door, index, profileColor }) => {
  const getProductGroup = (groupId, productId) => {
    if (!store.areaColors) return;

    const group = store.areaColors
      .find(({ id }) => id === groupId)
      .Products.find(({ id }) => id === productId);

    return group;
  };

  const doorStyling = Object.assign(
    {
      width: `calc(${100 / store.doors.length}% + ${addedSize}px)`,
      left:
        index !== store.doors.length - 1
          ? `calc(${(100 / store.doors.length) * index}% - ${
              index !== 0 ? addedSize / 2 : 0
            }px)`
          : null,
      right: index === store.doors.length - 1 ? 0 : null,
      background: !door.layout.type
        ? "transparent linear-gradient(155deg, rgba(157, 166, 170, 1) 0%, rgba(248, 248, 248, 1) 52%, rgba(194, 201, 206, 1) 100%) 0% 0% no-repeat padding-box"
        : null,
      opacity:
        store.step === Steps.Fields && store.activeDoor && !door.active
          ? 0.5
          : undefined,
    },
    doorTrackStyles[store.trackConfig[store.doors.length][store.tracks][index]]
  );

  return (
    <div
      onClick={() => {
        if (store.step === Steps.Fields) {
          if (store.activeDoor && store.activeDoor.id === door.id) {
            setActiveDoor(null);
          } else {
            setActiveDoor(door.id);
          }
        }
      }}
      className={`door-track-${
        store.trackConfig[store.doors.length][store.tracks][index]
      } absolute flex flex-col h-full z-10 shadow-door ${
        store.step === Steps.Fields
          ? "cursor-pointer transition-opacity duration-200"
          : ""
      }`}
      style={doorStyling}
    >
      {index === 0 && store.step === Steps.Fields && !store.activeDoor && (
        <div className="w-full absolute inset-0 flex flex-col justify-center items-center z-30 text-med select-none">
          <Select />
          <p className="hidden md:block text-xs sm:text-sm mt-2 px-2 text-center">
            Markera dörr
          </p>
        </div>
      )}
      {store.step === Steps.Fields && store.activeDoor === door && (
        <div className="w-full absolute inset-0 flex flex-col justify-center items-center z-30 text-med select-none">
          <Deselect />
          <p className="hidden md:block text-xs sm:text-sm mt-2 px-2 text-center">
            Avmarkera dörr
          </p>
        </div>
      )}
      {profileColor && <Profile profileColor={profileColor} />}
      {door.layout.type &&
        door.layout.fields.map((field, i) => {
          const productGroup =
            field.fill.group && field.fill.product
              ? getProductGroup(field.fill.group, field.fill.product)
              : null;

          return (
            <div
              key={i}
              className={`flex justify-center items-center relative ${
                field.size
              } ${store.step === Steps.Fills ? "cursor-pointer" : ""}`}
              onClick={() => {
                if (store.step === Steps.Fills) {
                  if (field.active) {
                    resetActiveField();
                  } else {
                    setActiveDoor(door.id);
                    setActiveField(door.id, i);
                  }
                }
              }}
            >
              <div
                className={`absolute top-0 right-0 bottom-0 left-0 ${
                  store.step === Steps.Fills
                    ? "transition-opacity duration-200"
                    : ""
                }`}
                style={
                  productGroup
                    ? {
                        backgroundColor: productGroup.color,
                        backgroundImage: productGroup.image
                          ? `url(${API_URL + productGroup.image})`
                          : null,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        opacity:
                          !store.hasActiveField || field.active ? 1 : 0.5,
                      }
                    : {
                        background:
                          "transparent linear-gradient(155deg, rgba(157, 166, 170, 1) 0%, rgba(248, 248, 248, 1) 52%, rgba(194, 201, 206, 1) 100%) 0% 0% no-repeat padding-box",
                      }
                }
              ></div>
              {index === 0 &&
                i === 0 &&
                store.step === Steps.Fills &&
                !store.hasActiveField && (
                  <div className="w-full absolute inset-0 flex flex-col justify-center items-center z-30 text-purple select-none">
                    <Select />
                    <p className="hidden md:block text-xs sm:text-sm mt-2 px-2 text-center">
                      Markera fält
                    </p>
                  </div>
                )}
              {store.step === Steps.Fills && field.active && (
                <div className="w-full absolute inset-0 flex flex-col justify-center items-center z-30 text-purple select-none">
                  <Deselect />
                  <p className="hidden md:block text-xs sm:text-sm mt-2 px-2 text-center">
                    Avmarkera fält
                  </p>
                </div>
              )}
              {door.layout.fields.length - 1 !== i && (
                <Bars profileColor={profileColor} />
              )}
            </div>
          );
        })}
    </div>
  );
});

Door.propTypes = {
  door: PropTypes.shape({
    id: PropTypes.number.isRequired,
    layout: PropTypes.object.isRequired,
  }),
  index: PropTypes.number.isRequired,
};

export default Door;

import React from "react";
import Modal from "./Modal";
import store, { hideSizeModal } from "../store";
import { OrderType } from "../constants";

const SizeModal = () => (
  <Modal
    title="Fyll i era mått"
    buttonText="Jag förstår"
    onClick={hideSizeModal}
  >
    <p className="mb-4">
      Nu börjar resan mot er nästa garderob med måttanpassade skjutdörrar och en
      inredning helt planerat efter era önskemål. Måtten är viktiga så var
      noggranna med måtten då detta sätter eventuella begränsningar och påverkar
      hur er garderob kommer se ut. Vi har en mer utförlig måttguide men här
      kommer en kort sammanfattning.
    </p>
    <p className="mb-4">
      Grunden är att lägsta höjden ska väljas för att vi ska veta att dörrarna
      går in samt att största bredden ska väljas för att veta att garderoben
      täcker upp hela ytan.
    </p>
    <p className="mb-4">
      Nedan ser ni vilket breddmått ni ska ange baserat på hur er garderob ska
      placeras. Lägsta höjdmåttet från golv till tak ska alltid anges.
    </p>

    {store.orderType === OrderType.FullWardrobe && (
      <>
        <div className="font-bold">Hel garderob</div>
        <p className="mb-4">
          Här ska alltid totalbredden anges. Antingen måttet från vägg till vägg
          eller totalbredden på garderoben om sidoväggar och ev. anslagslist ska
          väljas. Vi kommer sedan räkna ut rätt mått på dörrarna baserat på
          detta.
        </p>
      </>
    )}

    {store.orderType === OrderType.OnlyDoors && (
      <>
        <div className="font-bold">Bara dörrar</div>
        <p>Vägg till vägg: Här ska totala måttet anges från vägg till vägg.</p>
        <p>
          Centrerad: Här ska öppningsmåttet ni önskar anges. Sidoväggar (19 mm
          per sidovägg) kommer läggas på utöver detta val.
        </p>
        <p className="mb-4">
          Till vänster och till höger: Här ska öppningsmåttet för dörrarna
          anges. Vi kommer räkna av ev. anslagslist från öppningsmåttet.
        </p>
      </>
    )}

    {store.orderType === OrderType.OnlyInterior && (
      <>
        <div className="font-bold">Bara inredning</div>
        <p>Ange alltid totalbredden ni önskar på inredningen.</p>
      </>
    )}
  </Modal>
);

export default SizeModal;

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { observer, useLocalObservable } from "mobx-react";
import store from "../store";
import InfoBox from "./InfoBox";

const ToggleSwitch = observer(({ label, name, info }) => {
  const toggleSwitchRef = useRef();
  const infoBoxButtonRef = useRef();
  const infoBox = useLocalObservable(() => ({
    isVisible: false,
    toggle() {
      infoBox.isVisible = !infoBox.isVisible;
    },
    hide(e) {
      if (
        e.target !== infoBoxButtonRef.current &&
        !document.getElementById("infobox-root").contains(e.target)
      ) {
        infoBox.isVisible = false;
      }
    },
  }));

  const handleOnChange = (e) => {
    store[e.target.name] = e.target.value === "true" ? true : false;
  };

  useEffect(() => {
    if (info && info.title && info.description) {
      document.addEventListener("mousedown", infoBox.hide);
      return () => {
        document.removeEventListener("mousedown", infoBox.hide);
      };
    }
  }, []);

  return (
    <div className="flex flex-col items-center" ref={toggleSwitchRef}>
      <label className="toggle-switch relative cursor-pointer my-4">
        <input
          type="checkbox"
          onChange={handleOnChange}
          name={name}
          className="hidden"
          value={!store[name]}
          checked={store[name]}
        />
        <div
          className={`relative flex items-center w-10 h-5.5 rounded-3xl ${
            store[name] ? "bg-dark" : "bg-inactive"
          }`}
        ></div>
      </label>
      <p className="text-sm whitespace-nowrap font-medium leading-6">
        {label}
        {info && info.title && info.description && (
          <span
            className="w-4 h-4 inline-flex justify-center items-center text-xs text-dark leading-none rounded-full border border-dark cursor-pointer ml-1"
            onClick={() => infoBox.toggle()}
            ref={infoBoxButtonRef}
          >
            ?
          </span>
        )}
      </p>
      {info && info.title && info.description && infoBox.isVisible && (
        <InfoBox ref={toggleSwitchRef}>
          {info.image && (
            <img src={info.image} className="w-full rounded-t-sm" />
          )}
          <div className="max-h-64 lg:max-h-full overflow-auto px-6 py-3">
            <h3 className="text-dark font-medium">{info.title}</h3>
            <p className="text-med text-sm">{info.description}</p>
          </div>
          <span className="w-2.5 h-2.5 absolute bottom-0 left-1/2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-yellow rounded-sm"></span>
        </InfoBox>
      )}
    </div>
  );
});

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  info: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    image: PropTypes.string,
  }),
};

export default ToggleSwitch;

import React from "react";
import Modal from "./Modal";
import { hideOnboardingModal } from "../store";

const OnboardingModal = () => (
  <Modal
    title="Planera er drömgarderob"
    buttonText="Jag är redo att börja designa min drömgarderob!"
    onClick={hideOnboardingModal}
  >
    Med vårt ritverktyg kan ni planera och beställa er drömgarderob. Designa era
    måttanpassade skjutdörrar och planera inredningen precis som ni önskar.
    Endast fantasin sätter gränserna.
    <br />
    På varje steg finns ett frågetecken ni kan klicka på för att få upp massa
    bra information. Har ni fler frågor så finns vi här för att hjälpa er.
  </Modal>
);

export default OnboardingModal;

import React, { useEffect, useRef } from "react";

const ConfirmPopup = ({ onConfirm, onCancel, text }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.focus();
  }, []);

  return (
    <div className="w-screen min-h-screen bg-grey-lighter bg-opacity-90 absolute top-0 inset-x-0 z-50 flex justify-center items-center">
      <div className="py-16 w-full max-w-sm">
        <div className="p-7 mx-8 shadow-popup bg-white rounded-2xl">
          <p>{text}</p>
          <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
            <button
              className="w-full lg:w-auto mt-5 px-8 py-4 bg-yellow font-bold rounded-2xl"
              onClick={onConfirm}
            >
              Ja, Återställ
            </button>
            <button
              ref={buttonRef}
              className="w-full sm:w-auto border border-grey-light mt-5 px-8 py-4 rounded-2xl"
              onClick={onCancel}
            >
              Nej, Avbryt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;

import React from "react";
import Modal from "./Modal";
import { hideInteriorSectionInfoModal } from "../store";

const InteriorSectionInfoModal = () => (
  <Modal buttonText="Jag förstår" onClick={hideInteriorSectionInfoModal}>
    <p>
      Du kommer behöva öppna två dörrar för att kunna dra ut denna
      inredningsdetalj. Du kan flytta på sektionen om detta är ett problem.
    </p>
  </Modal>
);

export default InteriorSectionInfoModal;

import React from "react";
import { observer } from "mobx-react";
import store from "../store";
import SummaryBox from "./SummaryBox";
import Wardrobe from "./Wardrobe";
import {
  BodyColorNames,
  DetailColorNames,
  SectionNames,
  SectionTypes,
} from "../constants";

const InteriorSummary = observer(({ maxWidth }) => {
  const sections = [];
  const interiorProducts = [];
  const accessories = [];

  store.sections.forEach((section) => {
    const sectionIndex = sections.findIndex(
      (obj) => obj.label === SectionNames[section.type]
    );

    // Add to already existing section type
    // Otherwise add it to the array
    if (sectionIndex > -1) {
      sections[sectionIndex].value += 1;
    } else {
      sections.push({
        label: SectionNames[section.type],
        value: 1,
      });
    }

    if (
      [SectionTypes.S, SectionTypes.M, SectionTypes.L, SectionTypes.XL].indexOf(
        section.type
      ) > -1 &&
      section.products.length > 0
    ) {
      section.products.forEach((product) => {
        const productIndex = interiorProducts.findIndex(
          (obj) => obj.label === `${product.name} ${section.type}`
        );

        // Add to already existing product type
        // Otherwise add it to the array
        if (productIndex > -1) {
          interiorProducts[productIndex].value += 1;
        } else {
          interiorProducts.push({
            label: `${product.name} ${section.type}`,
            value: 1,
          });
        }
      });
    }
  });
  sections.push(
    {
      label: "Stomme",
      value: store.bodyColor === BodyColorNames.White ? "Vit" : "Antracit",
    },
    {
      label: "Inredningsdetaljer",
      value: store.detailColor === DetailColorNames.White ? "Vit" : "Grå",
    }
  );

  // Sort interior products by label
  interiorProducts.sort((a, b) =>
    a.label < b.label ? -1 : a.label > b.label ? 1 : 0
  );

  store.possibleInteriorAccessories.forEach((accessory) => {
    if (accessory.amount > 0) {
      accessories.push({
        label: `${accessory.name}`,
        value: accessory.amount,
      });
    }
  });

  store.accessories.forEach((accessory) => {
    if (accessory.amount > 0) {
      accessories.push({
        label: `${accessory.name}`,
        value: accessory.amount,
      });
    }
  });

  const ratio = store.cappedHeight / store.cappedWidth;

  return (
    <div className={`w-full lg:flex-1 ${maxWidth ? "lg:max-w-1/2" : ""}`}>
      <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-4 md:space-x-4">
        <h2 className="w-full md:w-1/2 text-4xl font-bold mb-6 md:mb-0">
          Inredning
        </h2>
        <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
          <div
            style={{
              paddingTop: ratio < 1 ? `${ratio * 100}%` : "100%",
            }}
          >
            <div className="absolute inset-0">
              <Wardrobe
                preserveAspectRatio
                forceShowSections
                placement="center"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2 space-y-4">
          <SummaryBox label="Inredningsektioner" rows={sections} />
          <SummaryBox
            rows={[
              {
                label: "Inredningsbelysning",
                value: store.interiorLighting ? "Ja" : "Nej",
                title: true,
              },
            ]}
          />
        </div>
        <div className="w-full md:w-1/2 space-y-4">
          {interiorProducts.length > 0 && (
            <SummaryBox label="Inredningsdelar" rows={interiorProducts} />
          )}
          {accessories.length > 0 && (
            <SummaryBox label="Tillbehör" rows={accessories} />
          )}
        </div>
      </div>
    </div>
  );
});

export default InteriorSummary;

import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import { useForm } from "react-hook-form";
import { patch, post } from "../api";

import Input from "./Input";
import Checkbox from "./Checkbox";
import store, {
  completeStep,
  setRequestQuotation,
  setCustomerData,
  setShippingOption,
  setCurrentPrice,
} from "../store";
import RadioGroup from "./RadioGroup";
import RadioGroupItem from "./RadioGroupItem";

const Quotation = observer(() => {
  const formRef = useRef(null);
  const state = useLocalObservable(() => ({
    isBillingSame: false,
    toggleIsBillingSame() {
      state.isBillingSame = !state.isBillingSame;
    },
    isLoading: false,
    setIsLoading(isLoading) {
      state.isLoading = isLoading;
    },
  }));

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
    mode: "all",
    defaultValues: {
      name:
        store.orderInfo.customer.first_name ||
        store.orderInfo.customer.contact_first_name,
      lastname:
        store.orderInfo.customer.last_name ||
        store.orderInfo.customer.contact_last_name,
      email:
        store.orderInfo.customer.email ||
        store.orderInfo.customer.contact_email,
      phone: store.orderInfo.customer.contact_phone,
      delivery_adress: store.orderInfo.customer.delivery_address,
      postal_code: store.orderInfo.customer.delivery_zipcode,
      city: store.orderInfo.customer.delivery_city,
      billing_adress: store.orderInfo.customer.billing_address,
      billing_postal_code: store.orderInfo.customer.billing_zipcode,
      billing_city: store.orderInfo.customer.billing_city,
      sms: store.orderInfo.customer_wants_sms,
      comment: store.orderInfo.customer_note,
    },
  });

  const onSubmit = async (data) => {
    setCustomerData(data);

    postOrder();
  };

  const postOrder = async () => {
    const order = store.order;
    if (store.customerData) {
      order.customer = {
        first_name: store.customerData.name,
        last_name: store.customerData.lastname,
        email: store.customerData.email,
        contact_phone: store.customerData.phone,
        billing_address: store.customerData.billing_adress
          ? store.customerData.billing_adress
          : store.customerData.delivery_adress,
        billing_zipcode: store.customerData.billing_postal_code
          ? store.customerData.billing_postal_code
          : store.customerData.postal_code,
        billing_city: store.customerData.billing_city
          ? store.customerData.billing_city
          : store.customerData.city,
        delivery_address: store.customerData.delivery_adress,
        delivery_zipcode: store.customerData.postal_code,
        delivery_city: store.customerData.city,
        type: "person",
      };
      order.customer_wants_sms = store.customerData.sms;
      order.customer_note = store.customerData.comment;
    }

    state.setIsLoading(true);
    const shouldEdit = store.orderInfo.isRetrievedOrder;

    if (shouldEdit) {
      await patch(`/public/order/${store.orderInfo.globalId}`, order);
      location.href = `${document.referrer}offerter/${store.orderInfo.orderId}`;
    } else {
      await post("/public/order", order);
      store.requestSent = true;
    }
    state.setIsLoading(false);
  };

  useEffect(() => {
    completeStep();
  }, []);

  useEffect(() => {
    if (store.requestQuotation) {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event("submit"));
      } else {
        postOrder();
      }
      setRequestQuotation();
    }
  }, [store.requestQuotation]);

  useEffect(() => {
    if (!state.isBillingSame) return;

    setValue("billing_adress", "");
    setValue("billing_postal_code", "");
    setValue("billing_city", "");
  }, [state.isBillingSame]);

  return (
    <div className="pb-20 mt-20 mx-5 md:mx-16">
      <form
        className="flex flex-wrap bg-toolbar rounded-lg max-w-2xl px-6 md:px-10 py-8 pb-12 mx-auto space-y-5 shadow-checkout"
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="w-full text-4xl font-bold">Be om offert</h1>
        <div className="w-full sm:flex sm:space-x-4 space-y-5 sm:space-y-0">
          <Input
            label="Förnamn"
            info={errors.name && "Måste fylla i ett förnamn."}
            type="text"
            full
            {...register("name", { required: true })}
          />
          <Input
            label="Efternamn"
            info={errors.lastname && "Måste fylla i ett efternamn."}
            type="text"
            full
            {...register("lastname", { required: true })}
          />
        </div>
        <div className="w-full sm:flex sm:space-x-4 space-y-5 sm:space-y-0">
          <Input
            label="E-post"
            info={errors.email && "Inte en giltig e-post address."}
            type="email"
            full
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+\.\S+$/i,
            })}
          />
          <Input
            label="Telefon"
            info={errors.phone && "Måste fylla i ett telefonnummer."}
            type="tel"
            full
            {...register("phone", {
              required: true,
              minLength: 5,
              pattern: /^\+?[0-9]+$/i,
            })}
          />
        </div>
        <div className="w-full">
          <p className="text-2xl md:text-3xl font-bold mt-8 mb-4">
            Leverans och montering
          </p>
          <RadioGroup>
            {store.shipping.map((shipping, index) => (
              <RadioGroupItem
                key={shipping.value}
                value={shipping.value}
                label={shipping.label}
                activeValue={store.shippingOption}
                index={index}
                options={store.shipping}
                {...register("shipping", {
                  required: true,
                  onChange: (e) => {
                    setShippingOption(e.target.value);
                    setCurrentPrice();
                  },
                })}
              />
            ))}
          </RadioGroup>
          {errors.shipping && (
            <div className="flex mt-2">
              <p className="text-med text-xs">Måste välja leverans.</p>
            </div>
          )}
        </div>
        <div className="w-full flex flex-wrap md:flex-nowrap md:space-x-4 space-y-4 md:space-y-0">
          <div className="w-full md:w-1/2 space-y-5">
            <div className="w-full flex space-x-4">
              <Input
                label="Leveransadress"
                info={
                  errors.delivery_adress && "Måste fylla i en leveransadress."
                }
                type="text"
                full
                {...register("delivery_adress", { required: true })}
              />
            </div>
            <div className="w-full flex space-x-4">
              <Input
                label="Postnummer"
                info={errors.postal_code && "Obligatoriskt"}
                type="text"
                full
                {...register("postal_code", {
                  required: true,
                  pattern: /^[0-9]+$/i,
                })}
              />
              <Input
                label="Ort"
                info={errors.city && "Måste fylla i en stad."}
                type="text"
                full
                {...register("city", { required: true, pattern: /^\D+$/i })}
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 space-y-5">
            <div className="w-full flex space-x-4">
              <Input
                label="Faktureringsadress"
                info={
                  !state.isBillingSame && errors.billing_adress
                    ? "Måste fylla i en faktureringsadress."
                    : null
                }
                type="text"
                full
                disabled={state.isBillingSame}
                {...(!state.isBillingSame &&
                  register("billing_adress", { required: true }))}
              />
            </div>
            <div className="w-full flex space-x-4">
              <Input
                label="Postnummer"
                info={
                  !state.isBillingSame && errors.billing_postal_code
                    ? "Obligatoriskt"
                    : null
                }
                type="text"
                full
                disabled={state.isBillingSame}
                {...(!state.isBillingSame &&
                  register("billing_postal_code", {
                    required: true,
                    pattern: /^[0-9]+$/i,
                  }))}
              />
              <Input
                label="Ort"
                info={
                  !state.isBillingSame && errors.billing_city
                    ? "Måste fylla i en stad."
                    : null
                }
                type="text"
                full
                disabled={state.isBillingSame}
                {...(!state.isBillingSame &&
                  register("billing_city", {
                    required: true,
                    pattern: /^\D+$/i,
                  }))}
              />
            </div>
            <Checkbox
              label="Samma som leveransadress"
              name="same"
              onChange={state.toggleIsBillingSame}
            />
          </div>
        </div>
        <p className="text-sm">Kommentar, fråga eller önskemål</p>
        <textarea
          placeholder="Starta konversationen med oss och komplettera din beställning med kommentarer, frågor eller förfrågningar."
          className="w-full bg-yellow text-sm rounded-lg placeholder-current outline-none resize-none p-4"
          rows="4"
          {...register("comment")}
        ></textarea>
        <Checkbox
          checked={store.orderInfo.customer_wants_sms}
          label="Jag vill ha ett sms när offerten är skickad"
          {...register("sms")}
        />
        <button
          disabled={state.isLoading}
          className="bg-dark text-white font-medium text-lg rounded-lg uppercase px-6 py-3 ml-auto"
        >
          {store.orderInfo.isRetrievedOrder
            ? "Uppdatera order"
            : "Skicka offert"}
        </button>
      </form>
    </div>
  );
});

export default Quotation;

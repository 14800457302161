import React, { useEffect } from "react";
import { observer, useLocalObservable } from "mobx-react";
import store, { completeStep, nextStep } from "../store";
import ProductRow from "./ProductRow";

const InteriorAccessories = observer(() => {
  const interiorAccessories = useLocalObservable(() => ({
    totalPrice: 0,
    discountPrice: 0,
  }));

  useEffect(() => {
    completeStep();
  }, []);

  useEffect(() => {
    interiorAccessories.totalPrice = 0;
    store.possibleInteriorAccessories.forEach(
      (product) =>
        (interiorAccessories.totalPrice +=
          (product.price * product.amount) / 100)
    );

    if (store.campaign) {
      interiorAccessories.discountPrice =
        interiorAccessories.totalPrice *
        (1 - store.campaign.discount_percentage / 100);
    }
  }, [store.currentPrice]);

  return (
    <div className="pb-20 mt-20 overflow-x-auto">
      <div className="flex flex-wrap bg-toolbar rounded-lg max-w-2xl p-5 mx-auto shadow-checkout">
        <h1 className="w-full text-4xl font-bold mb-8">Tillbehör korg</h1>
        <div className="w-full space-y-5">
          {store.possibleInteriorAccessories.map((product) => (
            <ProductRow key={product.config_id} product={product} />
          ))}
        </div>

        {store.campaign && (
          <>
            <p className="w-full flex justify-between mt-8">
              Pris{" "}
              <span className="text-med line-through">
                {new Intl.NumberFormat("sv-SE", {
                  style: "currency",
                  currency: "SEK",
                }).format(interiorAccessories.totalPrice)}
              </span>
            </p>
            <p className="w-full flex justify-between">
              Rabatt{" "}
              <span>
                {new Intl.NumberFormat("sv-SE", {
                  style: "currency",
                  currency: "SEK",
                }).format(
                  interiorAccessories.totalPrice -
                    interiorAccessories.discountPrice
                )}
              </span>
            </p>
          </>
        )}
        <p
          className={`w-full flex justify-between font-bold ${
            store.campaign ? "text-red" : ""
          }`}
        >
          Totalt{" "}
          <span>
            {new Intl.NumberFormat("sv-SE", {
              style: "currency",
              currency: "SEK",
            }).format(
              store.campaign
                ? interiorAccessories.discountPrice
                : interiorAccessories.totalPrice
            )}
          </span>
        </p>
        <button
          className="w-full bg-dark text-white font-medium text-lg self-end rounded-lg uppercase px-6 py-3 mt-16"
          onClick={() => {
            nextStep();
          }}
        >
          Välj och fortsätt
        </button>
      </div>
    </div>
  );
});

export default InteriorAccessories;

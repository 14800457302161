import React from "react";
import { observer } from "mobx-react";
import {
  Steps,
  SectionDragType,
  SectionWidths,
  InteriorWardrobeMaxHeight,
  HoleSpacing,
  WardrobeHoles,
  SectionTypes,
} from "../constants";
import {
  CrossCircle,
  PointerMoveHorizontally,
  ZoomIn,
  Select,
  Deselect,
} from "./Icons";
import store, {
  calculateProductMovement,
  canDragSection,
  hideInteriorSectionInfoModal,
  moveSection,
  removeSection,
} from "../store";
import { useDrag } from "react-dnd";
import WardrobeProduct from "./WardrobeProduct";
import { useDropProducts } from "../hooks/useDropProducts";
import { useDraggedProduct } from "../hooks/useDraggedProduct";

const interiorSelectableSections = [
  SectionTypes.S,
  SectionTypes.M,
  SectionTypes.L,
  SectionTypes.XL,
];

const WardrobeSection = observer(
  ({
    section,
    sectionIndex,
    wardrobeWidth,
    offset = 0,
    frontFaceRef,
    interiorRef,
    canRemove,
    canMove,
    showSectionType,
  }) => {
    const drop = useDropProducts(interiorRef);

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: SectionDragType,
        item: {
          type: section.type,
          index: sectionIndex,
        },
        collect: (monitor) => ({
          isDragging: Boolean(monitor.isDragging()),
        }),
        end(_item, monitor) {
          const offset = monitor.getDifferenceFromInitialOffset();
          const frontFaceRect = frontFaceRef.current.getBoundingClientRect();
          // Move the dragged section to an even mm.
          moveSection(
            sectionIndex,
            Math.round((offset.x / frontFaceRect.width) * wardrobeWidth)
          );
        },
      }),
      [section.type, sectionIndex]
    );

    const draggedProduct = useDraggedProduct(frontFaceRef);
    const isDraggable = canMove && canDragSection(sectionIndex);

    return (
      <div
        className={`absolute bottom-0 ${
          isDragging ? "cursor-grabbing" : isDraggable ? "cursor-grab" : ""
        } ${
          store.step === Steps.Interior && store.activeSection !== section
            ? "cursor-pointer"
            : ""
        } `}
        style={{
          left: `${((section.pos + offset) / wardrobeWidth) * 100}%`,
          width: `${(SectionWidths[section.type] / wardrobeWidth) * 100}%`,
          height: `${
            ((9.5 + WardrobeHoles * HoleSpacing) / InteriorWardrobeMaxHeight) *
            100
          }%`,
        }}
        onClick={() => {
          if (store.step === Steps.Interior) {
            store.activeSection = section;
            hideInteriorSectionInfoModal();
          }
        }}
        ref={store.activeSection === section ? drop : null}
      >
        <div
          className="absolute top-0 right-0 bottom-0 left-0 transition-opacity duration-200"
          style={{
            opacity:
              store.step === Steps.Interior &&
              store.activeSection &&
              store.activeSection !== section
                ? 0.5
                : undefined,
          }}
        >
          {(store.step === Steps.Interior ||
            store.step === Steps.Summary ||
            store.requestSent) &&
            section.products.map((product, i) => {
              let offset = 0;

              if (
                draggedProduct &&
                store.activeSection === section &&
                i === draggedProduct.index
              ) {
                const { newPosition } = calculateProductMovement(
                  draggedProduct.index,
                  draggedProduct.relativePos
                );
                offset = newPosition - product.pos;
              }

              return (
                <WardrobeProduct
                  key={product.id}
                  src={product.src}
                  section={section}
                  product={product}
                  productIndex={i}
                  wrapperRef={frontFaceRef}
                  offset={offset}
                />
              );
            })}
        </div>
        {showSectionType && (
          <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center select-none">
            <div
              className="w-5 h-5 text-transparent bg-dark text-xs rounded-full flex justify-center items-center"
              style={{ color: "#C9C5BC" }}
            >
              {section.type}
            </div>
          </div>
        )}
        <div
          className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-end items-center"
          ref={isDraggable ? drag : undefined}
        >
          {isDraggable && (
            <div
              className="absolute cursor w-1/2"
              style={{
                bottom: "15%",
              }}
            >
              <PointerMoveHorizontally />
            </div>
          )}
          {store.step === Steps.Interior &&
            !store.activeSection &&
            // Only show the mark section indicator for the first selectable section
            !store.sections
              .slice(0, sectionIndex)
              .some((section) =>
                interiorSelectableSections.includes(section.type)
              ) && (
              <div className="w-full absolute inset-0 flex flex-col justify-center items-center z-30 text-med select-none">
                {store.isMobile ? <ZoomIn /> : <Select />}
                <p className="text-sm hidden md:block mt-2 px-2 text-center">
                  Markera sektion
                </p>
              </div>
            )}
        </div>
        {store.step === Steps.Interior && store.activeSection === section && (
          <button
            onClick={(event) => {
              event.stopPropagation();
              store.activeSection = null;
            }}
            className="absolute focus:outline-none text-med"
            style={{
              left: "calc(50% - 16px)",
              bottom: "-50px",
            }}
          >
            <Deselect />
          </button>
        )}
        {canRemove && (
          <button
            onClick={() => removeSection(sectionIndex)}
            className="absolute focus:outline-none"
            style={{
              left: "calc(50% - 8px)",
              bottom: "-32px",
            }}
          >
            <CrossCircle />
          </button>
        )}
      </div>
    );
  }
);

export default WardrobeSection;

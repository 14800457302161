import React from "react";
import { observer } from "mobx-react";
import store, {
  getPlacementLabel,
  getActiveProfileInfo,
  getFieldProductName,
} from "../store";
import SummaryBox from "./SummaryBox";
import Wardrobe from "./Wardrobe";

const DoorSummary = observer(({ maxWidth }) => {
  const profileInfo = getActiveProfileInfo();
  const fieldsArray = [];
  const accessories = [];

  store.doors.forEach((door, i) => {
    fieldsArray.push({
      label: `Dörr ${i + 1}`,
      value: door.layout.fields.length,
      title: true,
    });
    door.layout.fields.forEach((field, i) => {
      const fieldProductName = getFieldProductName(
        field.fill.group,
        field.fill.product
      );
      fieldsArray.push({ label: `Fält ${i + 1}`, value: fieldProductName });
    });
  });

  if (store.orderType === "only_doors") {
    store.accessories.forEach((accessory) => {
      if (accessory.amount > 0) {
        accessories.push({
          label: `${accessory.name}`,
          value: accessory.amount,
        });
      }
    });
  }

  const ratio = store.cappedHeight / store.cappedWidth;

  return (
    <div className={`w-full lg:flex-1 ${maxWidth ? "lg:max-w-1/2" : ""}`}>
      <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-4 md:space-x-4">
        <h2 className="w-full md:w-1/2 text-4xl font-bold mb-6 md:mb-0">
          Dörrar
        </h2>
        <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
          <div
            style={{
              paddingTop: ratio < 1 ? `${ratio * 100}%` : "100%",
            }}
          >
            <div className="absolute inset-0">
              <Wardrobe preserveAspectRatio placement="center" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap space-y-4 md:space-y-0 md:space-x-4">
        <div className="w-full md:w-1/2 space-y-4">
          <SummaryBox
            rows={[
              { label: "Placering", value: getPlacementLabel(), title: true },
              { label: "Anslagslister", value: store.bead ? "Ja" : "Nej" },
              {
                label: "Färg",
                value: store.bead ? store.beadConfig.meta.color : null,
              },
            ]}
          />
          <SummaryBox
            label="Mått"
            rows={[
              { label: "Bredd", value: `${store.size.width} mm` },
              { label: "Höjd", value: `${store.size.height} mm` },
              { label: "Djup", value: `${store.size.depth} mm` },
            ]}
          />
          <SummaryBox
            label="Dörrar och spår"
            rows={[
              { label: "Antal dörrar", value: store.doors.length },
              { label: "Antal spår", value: store.tracks },
              {
                label: "Mjukstängning",
                value: store.softClosing ? "Ja" : "Nej",
              },
              { label: "Dämplister", value: store.muffler ? "Ja" : "Nej" },
              {
                label: "Profil",
                value: `${profileInfo.type} ${profileInfo.color}`,
              },
            ]}
          />
        </div>
        <div className="w-full md:w-1/2 space-y-4">
          <SummaryBox label="Fält" rows={fieldsArray} />
          {accessories.length > 0 && (
            <SummaryBox label="Tillbehör" rows={accessories} />
          )}
          <SummaryBox
            rows={[
              {
                label: "Dörrbelysning",
                value: store.lighting ? "Ja" : "Nej",
                title: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
});

export default DoorSummary;

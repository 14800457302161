import React from "react";
import { setModalProduct } from "../store";
import { API_URL } from "../constants";
import { Cross } from "./Icons";

const ProductModal = ({ product }) => (
  <div className="w-full h-full bg-grey-lighter bg-opacity-90 fixed top-0 inset-x-0 z-50 flex justify-center items-center">
    <div className="max-w-full">
      <div className="mx-8 bg-yellow rounded-lg shadow-popup relative">
        <img
          src={API_URL + product.image}
          className="w-full object-cover rounded-t-lg"
        />
        <p className="font-medium px-6 py-2">{product.name}</p>
        <button
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
          onClick={() => setModalProduct(null)}
        >
          <Cross />
        </button>
      </div>
    </div>
  </div>
);

export default ProductModal;

export const API_URL = process.env.API_URL;

export const Steps = {
  Placements: 0,
  Size: 1,
  Doors: 2,
  Fields: 3,
  Fills: 4,
  Lighting: 5,
  Sections: 6,
  Interior: 7,
  InteriorAccessories: 8,
  Accessories: 9,
  Summary: 10,
  Payment: 11,
};
export const NumSteps = Object.keys(Steps).length;

export const StepsTitles = {
  [Steps.Placements]: "Placera garderob",
  [Steps.Size]: "Mått",
  [Steps.Doors]: "Dörrar och spår",
  [Steps.Fields]: "Fältindelning",
  [Steps.Fills]: "Fältfyllningar",
  [Steps.Lighting]: "Belysning",
  [Steps.Sections]: "Sektioner",
  [Steps.Interior]: "Inredningsdetaljer",
  [Steps.InteriorAccessories]: "Inredningsdetaljer: Tillbehör",
  [Steps.Accessories]: "Tillbehör",
  [Steps.Summary]: "Sammanfattning",
  [Steps.Payment]: "Be om offert",
};

// A wardrobe with OrderType of OnlyInterior can be up to 6000 mm wide, so we
// make the max room size a bit larger than that.
export const MaxRoomWidth = 6200;
export const InteriorWardrobeMaxHeight = 2080;

export const SectionTypes = {
  S: "S",
  M: "M",
  L: "L",
  XL: "XL",
  Partition: "wall",
  ClothesRail: "clothes_rail",
  DoubleClothesRail: "clothes_rail_x2",
};

export const SectionWidths = {
  [SectionTypes.S]: 332,
  [SectionTypes.M]: 432,
  [SectionTypes.L]: 532,
  [SectionTypes.XL]: 732,
  [SectionTypes.Partition]: 19,
  [SectionTypes.ClothesRail]: [200, 1100],
  [SectionTypes.DoubleClothesRail]: [200, 1100],
};

export const SectionNames = {
  [SectionTypes.S]: "S",
  [SectionTypes.M]: "M",
  [SectionTypes.L]: "L",
  [SectionTypes.XL]: "XL",
  [SectionTypes.Partition]: "Mellanvägg",
  [SectionTypes.ClothesRail]: "Klädstång",
  [SectionTypes.DoubleClothesRail]: "2X Klädstång",
};
export const SideWallWidth = 19;
// There are 64 holes, but we index from 0
export const WardrobeHoles = 63;
export const AllowedMiddleShelfOffset = 20;
export const HoleSpacing = 32;
export const ShelfName = "Hylla";
export const TrouserHangerName = "Byxhängare";
export const DrawerWithHandleName = "Låda med handtag";
export const DrawerWithoutHandleName = "Låda utan handtag";
export const JewelryBoxName = "Smyckeslåda";
export const ClothesRailName = "Klädstång (inkl. 2 fäste)";

export const SectionDragType = "section_drag";
export const ProductDragType = "product_drag";
export const ProductDropType = "product_drop";

export const OrderType = {
  FullWardrobe: "full_wardrobe",
  OnlyDoors: "only_doors",
  OnlyInterior: "only_interior",
};

export const BodyColorNames = {
  White: "white",
  Antracit: "antracit",
};

export const BodyColors = {
  [BodyColorNames.White]: "#FFFFFF",
  [BodyColorNames.Antracit]: "#666665",
};

export const DetailColorNames = {
  White: "white",
  DarkGrey: "dark_grey",
};

export const DetailColors = {
  [DetailColorNames.White]: "#FFFFFF",
  [DetailColorNames.DarkGrey]: "#585C56",
};

export const HandleColors = {
  White: "#FFFFFF",
  Silver: "#D2D2D2",
  Black: "#171717",
};
export const HandleColorNames = {
  [HandleColors.White]: "white",
  [HandleColors.Silver]: "silver",
  [HandleColors.Black]: "black",
};

export const ToolbarScrollSpeed = 5;

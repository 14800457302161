import React from "react";
import Modal from "./Modal";
import { hideSectionsModal } from "../store";

const SectionsModal = () => (
  <Modal title="Sektioner" buttonText="Jag förstår" onClick={hideSectionsModal}>
    <p className="mb-4">
      Vi vet att alla familjer har olika behov och önskemål när det gäller
      förvaring. Så för oss är det självklart att våra kunder ska kunna välja
      fritt hur deras inredning ska se ut.
    </p>
    <p className="mb-4">
      Detta steg är grunden för att planera er praktiska och snygga inredning.
      Lägg upp grunden här för att fylla den med inredningsartiklar i nästa
      steg.
    </p>
    <p>
      Dra in de sektioner ni önskar och använd sektioner med klädstång för att
      täcka upp hela garderobens bredd för att det inte ska bli tomma utrymmen
      och passbitar.
    </p>
  </Modal>
);

export default SectionsModal;

import { forwardRef, useEffect } from "react";
import ReactDOM from "react-dom";

const InfoBox = forwardRef(({ error, children }, ref) => {
  const infoBoxRoot = document.getElementById("infobox-root");
  const toolBarRoot = document.getElementById("toolbar");

  infoBoxRoot.classList.remove(error ? "bg-yellow" : "bg-warning");
  infoBoxRoot.classList.add(
    "w-64",
    "absolute",
    "transform",
    "-translate-y-full",
    error ? "bg-warning" : "bg-yellow",
    "rounded",
    "z-40"
  );

  useEffect(() => {
    if (ref.current && toolBarRoot) {
      toolBarRoot.addEventListener("scroll", handleScroll);
      return () => {
        toolBarRoot.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleScroll = () => {
    infoBoxRoot.style.left = `${
      ref.current.offsetLeft +
      (ref.current.offsetWidth / 2 - infoBoxRoot.offsetWidth / 2) -
      toolBarRoot.scrollLeft
    }px`;
  };

  infoBoxRoot.style.top = `${
    ref.current.offsetTop - infoBoxRoot.offsetHeight - 40
  }px`;
  infoBoxRoot.style.left = `${
    ref.current.offsetLeft +
    (ref.current.offsetWidth / 2 - infoBoxRoot.offsetWidth / 2) -
    (toolBarRoot ? toolBarRoot.scrollLeft : 0)
  }px`;

  return ReactDOM.createPortal(children, infoBoxRoot);
});

InfoBox.displayName = "InfoBox";

export default InfoBox;

import React from "react";
import PropTypes from "prop-types";
import store from "../store";
import { hexToHSL } from "../utils";
import { observer } from "mobx-react";

const Profile = observer(({ profileColor }) => {
  const positions = {
    top: {
      classes: "h-1.5 sm:h-2 xl:h-4 absolute top-0 right-0 left-0 z-20",
      style: {
        borderTop: `solid 1px ${hexToHSL(profileColor, 2)}`,
        borderBottom: `solid 1px ${hexToHSL(profileColor, 6)}`,
      },
    },
    right: {
      classes: `absolute top-0 right-0 bottom-0 z-30 ${
        store.profile.type === 2 ? "w-0.5 sm:w-1" : "w-1.5 sm:w-2 xl:w-2.5"
      }`,
      style: {
        borderRight:
          store.profile.type === 2
            ? `solid 1px ${hexToHSL(profileColor, 6)}`
            : null,
      },
    },
    bottom: {
      classes: "h-1.5 sm:h-2 xl:h-4 absolute bottom-0 right-0 left-0 z-20",
      style: {
        borderTop: `solid 1px ${hexToHSL(profileColor, 2)}`,
        borderBottom: `solid 1px ${hexToHSL(profileColor, 6)}`,
      },
    },
    left: {
      classes: `absolute top-0 left-0 bottom-0 z-30 ${
        store.profile.type === 2 ? "w-0.5 sm:w-1" : "w-1.5 sm:w-2 xl:w-2.5"
      }`,
      style: {
        borderLeft:
          store.profile.type === 2
            ? `solid 1px ${hexToHSL(profileColor, 6)}`
            : null,
      },
    },
  };

  return (
    <>
      {Object.keys(positions).map((position) => (
        <div
          key={position}
          className={positions[position].classes}
          style={Object.assign(
            {
              backgroundColor: profileColor,
            },
            positions[position].style
          )}
        >
          {position === "top" || position === "bottom" ? (
            <div
              className="absolute top-0 right-0 bottom-0 left-0"
              style={{
                background:
                  "transparent linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(62, 62, 62) 14%, rgb(10, 10, 10) 83%, rgb(0, 0, 0) 100%) 0% 0% no-repeat padding-box",
                opacity: 0.02,
              }}
            ></div>
          ) : (
            <div
              className={`absolute top-0 bottom-0 ${
                position === "left" ? "right-0" : "left-0"
              }`}
              style={{
                width: store.profile.type === 2 ? "2px" : "100%",
                background: `transparent linear-gradient(${
                  position === "left" ? "90deg" : "270deg"
                }, ${hexToHSL(profileColor, 6)} 0%, ${
                  store.profile.type === 1 ? ` ${profileColor} 25%,` : ""
                } ${hexToHSL(
                  profileColor,
                  15
                )} 100%) 0% 0% no-repeat padding-box`,
              }}
            ></div>
          )}
        </div>
      ))}
    </>
  );
});

Profile.propTypes = {
  profileColor: PropTypes.string.isRequired,
};

export default Profile;

import React, { useEffect, useRef } from "react";
import { observer, useLocalObservable } from "mobx-react";
import PropTypes from "prop-types";
import InfoBox from "./InfoBox";

const FieldButton = observer(({ figure, onClick, disabled, infoText }) => {
  const inputRef = useRef();
  const infoBoxButtonRef = useRef();
  const infoBox = useLocalObservable(() => ({
    isVisible: false,
    toggle() {
      infoBox.isVisible = !infoBox.isVisible;
    },
    hide(e) {
      if (
        e.target !== infoBoxButtonRef.current &&
        !document.getElementById("infobox-root").contains(e.target)
      ) {
        infoBox.isVisible = false;
      }
    },
  }));

  useEffect(() => {
    document.addEventListener("mousedown", infoBox.hide);
    return () => {
      document.removeEventListener("mousedown", infoBox.hide);
    };
  }, []);

  return (
    <div className="flex flex-col items-center" ref={inputRef}>
      <button
        className="text-med focus:outline-none"
        disabled={disabled}
        onClick={onClick}
      >
        {figure}
      </button>
      <span
        className="w-4 h-4 inline-flex justify-center items-center text-xs text-dark leading-none rounded-full border border-dark cursor-pointer mt-1"
        onClick={() => infoBox.toggle()}
        ref={infoBoxButtonRef}
      >
        ?
      </span>
      {infoBox.isVisible && (
        <InfoBox ref={inputRef}>
          <div className="max-h-64 lg:max-h-full overflow-auto px-6 py-3">
            <h3 className="text-dark font-medium">Fältindelning</h3>
            <p className="text-med text-sm">{infoText}</p>
          </div>
          <span className="w-2.5 h-2.5 absolute bottom-0 left-1/2 transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-yellow rounded-sm"></span>
        </InfoBox>
      )}
    </div>
  );
});

FieldButton.propTypes = {
  figure: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  infoText: PropTypes.string.isRequired,
};

export default FieldButton;

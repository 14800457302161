import React from "react";

const Modal = ({ title, children, buttonText, onClick }) => (
  <div className="flex justify-center items-center bg-grey-lighter bg-opacity-90 absolute top-0 inset-x-0 z-50 min-h-screen">
    <div className="py-16">
      <div className="border border-grey rounded-2xl bg-white px-5 lg:px-40 py-8 lg:py-32 max-w-3xl lg:text-center shadow-popup mx-8">
        <div className="text-3xl">{title}</div>
        <div className="mt-8 text-base">{children}</div>
        <button
          className="w-full lg:w-auto mt-5 px-10 py-4 bg-yellow font-bold rounded-2xl"
          onClick={onClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  </div>
);

export default Modal;

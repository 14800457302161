import React from "react";
import { toggleModalTerms } from "../store";
import { Cross } from "./Icons";

const TermsModal = () => (
  <div className="w-full h-full bg-grey-lighter bg-opacity-90 fixed top-0 inset-x-0 z-50 flex justify-center items-center">
    <div className="lg:w-1/2 h-[80vh]">
      <div className="h-full mx-8 bg-toolbar rounded-lg shadow-popup relative p-7">
        <div className="h-full overflow-auto">
          <h2 className="text-4xl font-bold mb-5">Köpvillkor</h2>
          <p>
            Vid beställning av en eller flera varor på garderobsfabriken.se blir
            ett köp först bindande när en orderbekräftelse skickas på angiven
            email adress. Du måste ha fyllt 18 år för att kunna handla på
            garderoberonline.se.
          </p>
          <br />
          <b>Orderbekräftelse</b>
          <p>
            Invänta orderbekräftelse på angiven email adress (kolla ev. I
            skräpkorgen om den inte hamnar i vanliga inkorgen). Läs noga igenom
            orderbekräftelse och meddela eventuella felaktigheter inom 24
            timmar. Det är ert som kund ansvar att kolla igenom så att mått och
            önskemål som fyllt in i vårt ritverktyg är korrekta.
          </p>
          <br />
          <b>Leveranstid</b>
          <p>
            Normal leveranstid på måttanpassade skjutdörrar är 3-5 veckor inkl.
            leverans till angiven adress. Förseningsförseningar, sjukdomar eller
            vilken säsong på året det är kan styra leveranstid. Boka inte in ev.
            snickare för montering innan leverans är skickad och bekräftad. Om
            ni har en specifik fråga om leveranstid på ett visst material eller
            offert kan ni höra av er till oss. Vid en order av både
            måttanpassade skjutdörrar och kundplanerad inredning skickas detta
            samtidigt. Del leverans där inredningen kommer innan skjutdörrarna
            kan göras om så önskas, dock mot en extra fraktkostnad. När endast
            möbelinredning köps skickas denna normalt inom 1-2 veckor. Vid
            eventuella förseningar och delleverans kommer vår kundtjänst kontakt
            med dig för närmare leveransbesked. Vi kommer höra av oss på angiven
            mejl när transport är bokad.
          </p>
          <br />
          <b>Leveransvillkor samt fraktkostnader</b>
          <p>
            Frakt på varor som beställt genom ritverktygen räknas automatiskt ut
            i kassan. Vi använder os utav fast priser enligt nedan.
          </p>
          <br />
          <p>Order av skjutdörrar: 1500 kr</p>
          <p>Order av skjutdörrar + inredning: 2000 kr</p>
          <p>Order av inredning: 2000 kr</p>
          <br />
          <p>
            Alla transporter sker i samarbete DHL eller diverse små
            transportbolag beroende på typ av gods och leveransort.
          </p>
          <br />
          <b>
            När kund mottagit och kvitterat ut varor övergår varans ansvar på
            dig som kund.
          </b>
          <p>
            När varor levereras till hemadress ingår ej inbärning av gods. Detta
            kan köpas till och efterfaktureras. Önskar ni hjälp med inbärning
            måste kundtjänst kontaktas inom 48 timmar efter lagt order, gärna på
            order@garderobsfabriken.se.
          </p>
          <br />
          <p>
            Vid leverans till hemadress måste gods genomsökas ordentligt efter
            transportskador. Om fraktdokument skrivs på och fraktskador upptäcks
            i efterhand kommer transportbolaget troligtvis ej godkänna
            skadeärende och ej stå för eventuella kostnader. Ev. dolda skador
            ska anmälas senast inom 7 dagar. Det är därför viktigt att kolla
            igenom allt ordentligt och uppmärksamma chaufför om transportskador
            som då ska noteras på fraktdokument. Vänta inte med att kolla på
            produkterna utan packa upp och gå igenom allt direkt.
          </p>
          <br />
          <b>Skickat gods</b>
          <p>
            Önskar ni att söka efter gods kan ett kolli id fås av vår kundtjänst
            om detta efterfrågas på order@garderobsfabriken.se . Detta kan
            endast göras med DHL och ej när vi skickas med mindre bolag.
          </p>
          <br />
          <b>Betalningsvillkor</b>
          <p>
            Alla betalningar i vårt ritverktyg sker i samarbete med Klarna och
            kan ske med alla deras olika betalalternativ. Klarnas kompletta
            villkor och ev. kostnader för delbetalning hittas vid utcheckning i
            kassan.
          </p>
          <br />
          <b>Moms</b>
          <p>I alla priser på garderobsfabriken.se ingår 25% moms.</p>
          <br />
          <b>Reklamationer</b>
          <p>
            Alla reklamationer sker först och främst till
            order@garderobsfabriken.se med utförlig förklaring samt bilder på
            skador.
          </p>
          <br />
          <b>Avbeställning av måttanpassade varor</b>
          <p>
            Varor som ska måttanpassas alt. Specialbeställas kan ej avbeställas
            efter påbörjad produktion/order. Transportskada vid en synlig
            transportskada vid leverans måste detta påpekas och noteras av
            chaufför på plats. Kvitteras och godkänns en leverans ligger varans
            ansvar och skicka på personen som kvitterat leveransen. Har leverans
            godkänts trotts transportskada accepterats kommer ansvarigt
            transportbolag ha svårare att ersätta skadade varor. Ångerrätt
            enligt lag har kund 14 dagars ångerrätt från det att kund mottagit
            varan. (gäller ej måttanpassade eller specialprodukter) kund står
            för ev. Returfrakt och varor ska levereras till oss och nyskick. Är
            varan trasig när vi har mottagit denna kommer du som kund stå för
            ev. Värdeminskning. Önskar ni att skicka tillbaka varor måste
            kundtjänst meddelas på order@garderobsfabriken.se. Återbetalning vid
            ev. Utnyttjad ångerrätt sker inom 30 dagar från att vi mottagit
            varor. Returnerade varor måste finnas hos garderobsfabriken.se
            senast 30 dagar efter mottagen vara.
          </p>
          <br />
          <b>Personuppgifter</b>
          <p>
            Inga personer som registreras på garderobsfabriken.se kommer någon
            gång överlåtas, säljas eller göras tillgängligt för tredje part.
            Alla registrerade uppgifter vid köp hos oss önskas bara för att vi
            ska göra affär som är trygg för både oss och kund samt att kunna
            marknadsföra våra produkter i form av. T.ex. Nyhetsbrev.
            Garderobsfabriken ansvarar för all behandling utav de uppgifter våra
            kunder lämnar, i enlighet med till personuppgiftslagen (pul). Du har
            alltid rätt att ta del av samt rätta eller få raderat de uppgifter
            som finns registrerade om dig. Ta kontakt med oss för att veta mer
            om hur det fungerar.
          </p>
          <br />
          <b>Cookies</b>
          <p>
            På Garderobsfabriken.se hanteras kundkorg med hjälp av cookies.
            Detta är för att du ska få en enkel och bra shoppingupplevelse.
            Cookies kan rensas i webbläsarens inställningar.
          </p>
          <br />
          <b>Företagsfakta</b>
          <p>Garderobsfabriken Sverige AB</p>
          <p>Norra aspelundsvägen 3, 54134 Skövde</p>
          <p>Org nr. 556987-7755</p>
          <p>
            <a href="tel:0500420021">0500 - 42 00 21</a>
          </p>
          <p>
            <a href="mailto:info@garderobsfabriken.se">
              info@garderobsfabriken.se
            </a>
          </p>
        </div>
        <button
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
          onClick={() => toggleModalTerms()}
        >
          <Cross />
        </button>
      </div>
    </div>
  </div>
);

export default TermsModal;

import { useEffect, useRef } from "react";

export const useEffectSkipFirst = (fn, deps) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    return fn();
  }, deps);
};

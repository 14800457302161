import React from "react";
import { observer } from "mobx-react";
import {
  ClothesRailJacket,
  CrossCircle,
  PointerMoveHorizontally,
} from "./Icons";
import { canDragSection, moveSection, removeSection } from "../store";
import { useDrag } from "react-dnd";
import { InteriorWardrobeMaxHeight, SectionDragType } from "../constants";

const WardrobeClothesRail = observer(
  ({
    section,
    sectionIndex,
    wardrobeWidth,
    offset = 0,
    move = false,
    fromLeft = false,
    fromRight = false,
    depth,
    double = false,
    frontFaceRef,
    color,
    canRemove,
    canMove,
  }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: SectionDragType,
        item: {
          type: section.type,
          index: sectionIndex,
        },
        collect: (monitor) => ({
          isDragging: Boolean(monitor.isDragging()),
        }),
        end(_item, monitor) {
          const offset = monitor.getDifferenceFromInitialOffset();
          const frontFaceRect = frontFaceRef.current.getBoundingClientRect();
          // Move the dragged section to an even mm.
          moveSection(
            sectionIndex,
            Math.round((offset.x / frontFaceRect.width) * wardrobeWidth)
          );
        },
      }),
      [section.type, sectionIndex]
    );

    const isDraggable = canMove && canDragSection(sectionIndex);
    let left = section.pos;
    let width = section.width;

    if (move) {
      left += offset;
    } else if (fromLeft) {
      left += offset;
      width -= offset;
    } else if (fromRight) {
      width += offset;
    }

    return (
      <div
        className={`absolute h-full preserve-3d ${
          isDragging ? "cursor-grabbing" : isDraggable ? "cursor-grab" : ""
        }`}
        style={{
          left: `${(left / wardrobeWidth) * 100}%`,
          width: `${(width / wardrobeWidth) * 100}%`,
        }}
      >
        <div
          className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-end items-center"
          ref={isDraggable ? drag : undefined}
        >
          {isDraggable && (
            <div
              className="absolute cursor w-1/2"
              style={{
                bottom: "15%",
              }}
            >
              <PointerMoveHorizontally />
            </div>
          )}
        </div>
        <div
          className="absolute w-full left-0 right-0 border-t-4"
          style={{
            top: `${(80 / InteriorWardrobeMaxHeight) * 100}%`,
            height: "40%",
            transform: `translateZ(-${25 + depth}px)`,
            borderTopColor: color,
            color,
          }}
        >
          <ClothesRailJacket
            className="h-full max-w-full relative"
            style={{ top: "-4px" }}
          />
        </div>

        {double && (
          <div
            className="absolute w-full left-0 right-0 h-1 border-t-4"
            style={{
              top: `${(1080 / InteriorWardrobeMaxHeight) * 100}%`,
              height: "40%",
              transform: `translateZ(-${25 + depth}px)`,
              borderTopColor: color,
              color,
            }}
          >
            <ClothesRailJacket
              className="h-full max-w-full relative"
              style={{ top: "-4px" }}
            />
          </div>
        )}
        {canRemove && (
          <button
            onClick={() => removeSection(sectionIndex)}
            className="absolute focus:outline-none"
            style={{
              left: "calc(50% - 8px)",
              bottom: "-32px",
            }}
          >
            <CrossCircle />
          </button>
        )}
      </div>
    );
  }
);

export default WardrobeClothesRail;

import React from "react";

export const Figure1 = () => (
  <svg
    id="Figur"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Figur-1"
      data-name="Figur"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
  </svg>
);

export const Figure2 = () => (
  <svg
    id="Figur_2"
    data-name="Figur 2"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 25.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure3 = () => (
  <svg
    id="Figur_3"
    data-name="Figur 3"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 16.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 33.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure4 = () => (
  <svg
    id="Figur_4"
    data-name="Figur 4"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 11.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 21.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure5 = () => (
  <svg
    id="Figur_5"
    data-name="Figur 5"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 13.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 37.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_40"
      data-name="Line 40"
      x2="32"
      transform="translate(1.5 25.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure6 = () => (
  <svg
    id="Figur_6"
    data-name="Figur 6"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 9.814)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 40.186)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure7 = () => (
  <svg
    id="Figur_7"
    data-name="Figur 7"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 20.172)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 29.828)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const Figure8 = () => (
  <svg
    id="Figur_8"
    data-name="Figur 8"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="50"
    viewBox="0 0 35 50"
  >
    <g
      id="Rectangle_127"
      data-name="Rectangle 127"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <rect width="35" height="50" stroke="none" />
      <rect x="1" y="1" width="33" height="48" fill="none" />
    </g>
    <line
      id="Line_38"
      data-name="Line 38"
      x2="32"
      transform="translate(1.5 38.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      x2="32"
      transform="translate(1.5 28.5)"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

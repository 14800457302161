import { useDragLayer } from "react-dnd";
import { HoleSpacing, ProductDragType } from "../constants";
import store from "../store";

export const useDraggedProduct = (wrapperRef) => {
  const draggedProduct = useDragLayer((monitor) => {
    if (!monitor.isDragging() || monitor.getItemType() !== ProductDragType) {
      return null;
    }

    const item = monitor.getItem();
    const wardrobeHeight = store.size.height;
    const offset = monitor.getDifferenceFromInitialOffset();
    const wrapperRect = wrapperRef.current.getBoundingClientRect();

    return {
      index: item.index,
      type: item.type,
      relativePos: Math.round(
        ((-offset.y / wrapperRect.height) * wardrobeHeight) / HoleSpacing
      ),
    };
  });

  return draggedProduct;
};

import React, { forwardRef } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

const RadioGroupItem = forwardRef(
  (
    { name, value, label, activeValue, onChange, index, options, ...props },
    ref
  ) => {
    const activeValueIndex = options.findIndex(
      (option) => option.value === activeValue || option === activeValue
    );

    return (
      <div className="flex flex-1" key={value}>
        <input
          type="radio"
          id={`${name}-${value}`}
          name={name}
          value={value}
          className="hidden"
          onChange={onChange}
          checked={activeValue === value}
          ref={ref}
          {...props}
        />
        <label
          htmlFor={`${name}-${value}`}
          className={`flex-1 min-w-12 cursor-pointer text-center whitespace-nowrap rounded-lg px-3 py-4 ${
            activeValue === value ? "bg-white" : ""
          }`}
        >
          <p className="text-sm leading-none font-semibold">{label}</p>
        </label>

        {options.length > 2 && options.length - 1 !== index && (
          <span
            className={`w-px ${
              activeValueIndex !== index && activeValueIndex - 1 !== index
                ? "bg-dark"
                : "bg-transparent"
            } mx-1 my-3`}
          ></span>
        )}
      </div>
    );
  }
);

RadioGroupItem.displayName = "RadioGroupItem";

RadioGroupItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default observer(RadioGroupItem);

import React from "react";
import { observer } from "mobx-react";
import store, {
  setCurrentOrder,
  setCurrentPrice,
  setModalProduct,
} from "../store";
import { API_URL } from "../constants";
import { useEffectSkipFirst } from "../hooks/useEffectSkipFirst";
import { ZoomIn } from "./Icons";

const ProductRow = observer(({ product }) => {
  useEffectSkipFirst(() => {
    setCurrentOrder();
    setCurrentPrice();
  }, [product.amount]);

  return (
    <div className="w-full flex items-center border-b border-inactive pb-5">
      {product.image && (
        <div className="w-20 h-20 relative mr-3">
          <img
            src={API_URL + product.image}
            className="h-full object-cover rounded-lg"
          />
          <span
            className="flex justify-center items-center w-8 h-8 bg-yellow absolute right-0 bottom-0 rounded-lg mr-1 mb-1 z-1 cursor-pointer"
            onClick={() => setModalProduct(product)}
          >
            <ZoomIn />
          </span>
        </div>
      )}
      <div className="flex-1 flex flex-col md:flex-row md:items-center">
        <p className="md:text-2xl font-bold mb-1 md:mb-0 md:mr-4">
          {product.name}
        </p>
        <div className="flex items-center md:ml-auto">
          <div>
            <div className="flex mb-1">
              <button
                className="w-10 h-10 flex justify-center items-center text-2xl bg-yellow focus:outline-none rounded-l-lg"
                onClick={() => {
                  if (product.amount > 0) {
                    product.amount--;
                  }
                }}
              >
                -
              </button>
              <input
                className="w-10 h-10 text-center outline-none font-semibold"
                type="number"
                value={product.amount}
                readOnly
              />
              <button
                className="w-10 h-10 flex justify-center items-center text-2xl bg-yellow focus:outline-none rounded-r-lg"
                onClick={() => {
                  if (!product.max || product.amount < product.max) {
                    product.amount++;
                  }
                }}
              >
                +
              </button>
            </div>
            <p className="text-center leading-none text-xs text-med">
              Välj antal. {product.max && `Max ${product.max} st.`}
            </p>
          </div>
          <p
            className={`flex flex-col w-full md:w-24 text-right font-medium ml-5 ${
              product.amount === 0 ? "text-med opacity-50" : ""
            }`}
          >
            {store.campaign && (
              <span className="text-red">
                {new Intl.NumberFormat("sv-SE", {
                  style: "currency",
                  currency: "SEK",
                }).format(
                  product.amount > 0
                    ? ((product.price * product.amount) / 100) *
                        (1 - store.campaign.discount_percentage / 100)
                    : (product.price / 100) *
                        (1 - store.campaign.discount_percentage / 100)
                )}
              </span>
            )}
            <span className={`${store.campaign ? "line-through" : ""}`}>
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(
                product.amount > 0
                  ? (product.price * product.amount) / 100
                  : product.price / 100
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
});

export default ProductRow;

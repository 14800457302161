import React from "react";
import { observer } from "mobx-react";
import store, { completeStep, nextStep, setPaymentType } from "../store";
import Button from "./Button";

const Summary = observer(() => (
  <>
    <Button
      label="Köp nu"
      onClick={() => {
        setPaymentType("buy");
        completeStep();
        nextStep();
      }}
      active={false}
    >
      <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" />
    </Button>
    <Button
      label="Få offert"
      onClick={() => {
        setPaymentType("offer");
        completeStep();
        nextStep();
      }}
      active={false}
    />
    {store.currentPrice && (
      <div>
        <p className="font-medium whitespace-nowrap">
          Pris:{" "}
          {store.campaign && (
            <span className="text-red font-bold">
              {new Intl.NumberFormat("sv-SE", {
                style: "currency",
                currency: "SEK",
              }).format(store.currentPrice.total_amount / 100)}
            </span>
          )}
          <span
            className={`text-med ${
              store.campaign ? "block line-through text-right" : "font-bold"
            }`}
          >
            {new Intl.NumberFormat("sv-SE", {
              style: "currency",
              currency: "SEK",
            }).format(
              (store.campaign
                ? store.currentPrice.original_amount
                : store.currentPrice.total_amount) / 100
            )}
          </span>
        </p>
        <p className="text-xs text-med text-right">Inkl moms</p>
      </div>
    )}
  </>
));

export default Summary;

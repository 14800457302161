import React, { useEffect } from "react";
import { observer } from "mobx-react";
import store, {
  completeStep,
  setActiveDoor,
  setCurrentOrder,
  setCurrentPrice,
  setFieldProduct,
} from "../store";
import ColorRadioGroup from "./ColorRadioGroup";
import ColorRadioGroupItem from "./ColorRadioGroupItem";

const Fills = observer(() => {
  useEffect(() => {
    setActiveDoor(null);
    store.doors.forEach((door) =>
      door.layout.fields.forEach((field) => {
        field.fill.group = field.fill.group || 3;
        field.fill.product = field.fill.product || 8;
      })
    );
    completeStep();
  }, []);

  let activeFieldProductId = null;
  if (store.activeDoor) {
    const activeField = store.activeDoor.layout.fields.find(
      (field) => field.active
    );
    if (activeField) {
      activeFieldProductId = activeField.fill.product;
    }
  }

  const handleChange = (areaColorId, productId) => {
    setFieldProduct(areaColorId, productId);
    setCurrentOrder();
    setCurrentPrice();
  };

  return (
    <>
      {store.areaColors.map((areaColor) => (
        <ColorRadioGroup key={areaColor.id} title={areaColor.name}>
          {areaColor.Products.map((product, index) => (
            <ColorRadioGroupItem
              key={product.id}
              name="field_product"
              value={product.id}
              label={product.name}
              url={product.image}
              activeValue={activeFieldProductId}
              onChange={() => handleChange(areaColor.id, product.id)}
              first={index === 0}
              last={index === areaColor.Products.length - 1}
              isActive={activeFieldProductId === product.id}
            />
          ))}
        </ColorRadioGroup>
      ))}
    </>
  );
});

export default Fills;
